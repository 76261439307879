import { Fragment } from "react";

export const DashboardPanelItem = ({ heading, data }) => {
  return (
    <Fragment>
      <div
        style={{
          borderRadius: "10px",
          backgroundColor: "#FFFF",
          padding: "2px 8px",
          margin: "2px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {heading}:<h2 style={{ fontWeight: "bold" }}>{data}</h2>
      </div>
    </Fragment>
  );
};
