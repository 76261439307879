import {
  Row,
  Col,
  Table,
  useTable,
  Card,
  Form,
  List,
  Input,
  Modal,
  useModalForm,
  Select,
  Space,
  EditButton,
  Tag,
  Button,
  useModal,
} from "@pankod/refine-antd";

import { HttpError, IResourceComponentsProps } from "@pankod/refine-core";
import { DatePicker } from "antd";
import { StatusColours, SUBSCRIPTION_RUN_STATUSES } from "../../constants";

import { ISubscriptionRun } from "../../Interfaces";
import { Fragment, useEffect } from "react";
import toast from "react-hot-toast";
import { SubscriptionRunStatusTagRender } from "./SubscriptionRunStatusTagRender";

const dayjs = require("dayjs");
var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);

export const SubscriptionRunList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, tableQueryResult } = useTable<
    ISubscriptionRun,
    HttpError
  >({
    resource: `subscription-runs`,
    initialPageSize: 100,
    //i'm temporarily disabling the filter because it is not working properly
    //ive added a method below to filter the results based on the rundate
    // permanentSorter: [
    //   {
    //     field: "runDate",
    //     order: "asc",
    //   },
    // ],
  });

  // Create Modal
  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    // form: createForm,
    // show: createModalShow,
  } = useModalForm<ISubscriptionRun>({
    action: "create",
  });

  const currentRunList = tableQueryResult?.data?.data;

  useEffect(() => {
    if (currentRunList) {
      const openRunData = [];

      currentRunList.forEach((run) => {
        if (run.status === "open") {
          openRunData.push(run);
        }
      });

      if (openRunData.length > 1) {
        toast.error("Warning: more than one run is open");
      }
    }
  }, [currentRunList]);

  const sortedRuns = tableProps?.dataSource
    ?.map((run) => {
      //sort the runs by the run date
      return {
        ...run,
        runDate: dayjs(run.runDate),
      };
    })
    .sort((a, b) => {
      return a.runDate - b.runDate;
    })
    .reverse();

  return (
    <Fragment>
      <Card>
        <Row style={{ marginBottom: "30px" }}>
          <Col span={12}></Col>
        </Row>
        <Row>
          <Col span={24}>
            <List canCreate={true}>
              <Table dataSource={sortedRuns} rowKey="id">
                <Table.Column<ISubscriptionRun>
                  dataIndex="name"
                  title="Run Name"
                  render={(_, record) => {
                    return <Fragment> {record.name}</Fragment>;
                  }}
                ></Table.Column>
                <Table.Column<ISubscriptionRun>
                  title="Open Date"
                  dataIndex="openDateTime"
                  render={(_, record) => {
                    return dayjs(record.openDateTime).format(
                      "DD/MM/YYYY: hh:mm:ss"
                    );
                  }}
                />
                <Table.Column<ISubscriptionRun>
                  title="Close Date"
                  dataIndex="closeDateTime"
                  render={(_, record) => {
                    return dayjs(record.closeDateTime).format(
                      "DD/MM/YYYY: hh:mm:ss"
                    );
                  }}
                />
                <Table.Column<ISubscriptionRun>
                  title="Run Status"
                  dataIndex="Status"
                  align="center"
                  render={(_, record) => {
                    return (
                      <Fragment>
                        <SubscriptionRunStatusTagRender
                          status={record.status}
                        />
                      </Fragment>
                    );
                  }}
                />
                <Table.Column<ISubscriptionRun>
                  title="Asap Available?"
                  dataIndex="isAsapPurchaseAvailable"
                  align="center"
                  render={(_, record) => {
                    //if the asap purchase is available then return a checkmark
                    if (record.isAsapPurchaseAvailable) {
                      //return a tag with a checkmark
                      return (
                        <Fragment>
                          <Tag color={StatusColours.YES}>Yes</Tag>
                        </Fragment>
                      );
                    }
                    //if the asap purchase is not available then return a cross
                    return <Tag color={StatusColours.NO}>No</Tag>;
                  }}
                />

                <Table.Column<ISubscriptionRun>
                  title="Actions"
                  dataIndex="actions"
                  render={(_, record) => (
                    <Space>
                      <EditButton size="small" recordItemId={record.id} />
                    </Space>
                  )}
                />
              </Table>
            </List>
            <Modal {...createModalProps} title="Create a Run">
              <Form {...createFormProps} layout="vertical">
                <Form.Item
                  label="Run Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Status"
                  name="status"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select options={SUBSCRIPTION_RUN_STATUSES}></Select>
                </Form.Item>

                <Form.Item
                  label="Run Date"
                  name="runDate"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker />
                </Form.Item>
                <Form.Item
                  label="openDateTime"
                  name="openDateTime"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker />
                </Form.Item>
                <Form.Item
                  label="closeDateTime"
                  name="closeDateTime"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker />
                </Form.Item>
              </Form>
            </Modal>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};
