import { Tag } from "@pankod/refine-antd";
import { FirstLetterToUpperCase } from "Utilities/FirstLetterToUpperCase";

import { SUBSCRIPTION_STATUSES } from "../../constants";

export const SubscriptionStatusTagRender = ({ status }: { status: string }) => {
  switch (status) {
    case SUBSCRIPTION_STATUSES.find((s) => s.value === "active").value:
      return <Tag color="green">{FirstLetterToUpperCase(status)}</Tag>;
    case SUBSCRIPTION_STATUSES.find((s) => s.value === "paused").value:
      return <Tag color="orange">{FirstLetterToUpperCase(status)}</Tag>;
    case SUBSCRIPTION_STATUSES.find((s) => s.value === "cancelled").value:
      return <Tag color="red">{FirstLetterToUpperCase(status)}</Tag>;
    case SUBSCRIPTION_STATUSES.find((s) => s.value === "completed").value:
      return <Tag color="blue">{FirstLetterToUpperCase(status)}</Tag>;

    default:
      console.warn(
        `Subscription Status - Unknown status value selected '${status}' `
      );
      return <Tag color="#7a7d7b"> null </Tag>;
  }
};
