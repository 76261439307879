import { BaseKey, useApiUrl, useCustom } from "@pankod/refine-core";
import { ILifeTimeValue } from "Interfaces";
import { formatCurrency } from "Pages/Dashboard/FormatCurrency";
import { Fragment } from "react";
export const SubscriptionLifeTimeValueDisplay = ({
  id,
}: {
  id: BaseKey | undefined;
}) => {
  const apiUrl = useApiUrl();

  //get the required data for the lifetimeValue from the custom endpoint.
  const { data: lifetimeValueData } = useCustom<ILifeTimeValue>({
    url: `${apiUrl}/subscriptions/${id}/lifetimevalue`,
    method: "get",
  });

  return (
    <Fragment>
      {lifetimeValueData?.data?.total ? (
        <b>{formatCurrency(lifetimeValueData?.data?.total)}</b>
      ) : (
        <b>N/A</b>
      )}
    </Fragment>
  );
};
