import { Tag } from "@pankod/refine-antd";

export const IsAsapAvailStatusTagRender = ({ status }: { status: boolean }) => {
  switch (status) {
    case true:
      return <Tag color="green">ASAP Purchase Currently Available</Tag>;
    case false:
      return <Tag color="red">ASAP Purchase Currently Not Available</Tag>;

    default:
      console.warn(
        `S Asap Status  - Unknown status value provided '${status}' `
      );
      return <Tag color="#7a7d7b"> null </Tag>;
  }
};
