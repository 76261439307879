import {
  Edit,
  useForm,
  useTable,
  List,
  Table,
  Space,
  EditButton,
  Form,
  useModalForm,
  Input,
  Select,
  DatePicker,
  Row,
  Col,
  Tabs,
  Button,
  Modal,
  Checkbox,
  FilterDropdown,
  getDefaultFilter,
  DeleteButton,
  Tag,
  Dropdown,
  Menu,
  Popover,
} from "@pankod/refine-antd";
import {
  MailTwoTone,
  ThunderboltTwoTone,
  CloseSquareTwoTone,
} from "@ant-design/icons";
import { IResourceComponentsProps, useList } from "@pankod/refine-core";
import {
  ISubscriptionOrder,
  ISubscriptionRun,
  ISubscriptionRunSkuMap,
  ISubscriptionSku,
} from "Interfaces";
import { Fragment, useEffect, useState } from "react";
import {
  AdminEmails,
  OrderStatusProcessAvailable,
  StatusColours,
  SUBSCRIPTION_RUN_STATUSES,
} from "../../constants";
import toast from "react-hot-toast";
import { ShowRunIsCurrent } from "./ShowRunIsCurrent";
import { IncompleteSkuMapWarning } from "./IncompleteSkuMapWarning";
import {
  cancelOrder,
  createOrders,
  processOrders,
  refreshStockAllocation,
  sendEmail,
} from "Services/productServices";

import { TotalSubscriptionTable } from "./TotalSubscriptionTable";
import { StockAllocationTable } from "./StockAllocationTable";
import { EditSkuMapModalForm } from "./EditSkuMapModalForm";
import { CreateSkuMapModalForm } from "./createSkuMapModalForm";
import { SubscriptionOrderStatusTagRender } from "./SubscriptionOrderStatusTagRender";
import { SubscriptionOrderOrderStatusTagRender } from "./SubscriptionOrderOrderStatusTagRender";
import { IsAsapAvailStatusTagRender } from "./IsAsapAvailStatusTagRender";
import { SubscriptionRunDeleteButton } from "./SubscriptionRunDeleteButton";
import { PackAllocationTable } from "./PackAlloactionTable";

const dayjs = require("dayjs");
const { TabPane } = Tabs;

export const SubscriptionRunEdit: React.FC<IResourceComponentsProps> = () => {
  //setting the date format of the datepicker than than in place (DRY)
  const dateFormat = "DD/MM/YYYY";

  const { formProps, id, saveButtonProps, queryResult, form } =
    useForm<ISubscriptionRun>();

  const [processingOrders, setProcessingOrders] = useState(false);

  const { tableProps, tableQueryResult } = useTable<ISubscriptionRunSkuMap>({
    resource: "subscription-run-sku-maps",
    hasPagination: false,
    permanentFilter: [
      {
        field: "subscription_run",
        operator: "eq",
        value: id,
      },
    ],
    initialPageSize: 50,
  });

  const { data: subscriptionSkuDataResult } = useList<ISubscriptionSku>({
    resource: "subscription-skus",
    config: {
      pagination: { current: 1, pageSize: 500 },
    },
  });

  const {
    tableProps: orderTableProps,
    tableQueryResult: { refetch },
    filters,
  } = useTable<ISubscriptionOrder>({
    resource: "subscription-orders",
    permanentFilter: [
      {
        field: "subscription_run",
        operator: "eq",
        value: id,
      },
    ],
    initialPageSize: 500,
  });

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    form: createForm,
    show: createModalShow,
  } = useModalForm<ISubscriptionRunSkuMap>({
    resource: "subscription-run-sku-maps",
    action: "create",

    redirect: false,
  });

  const {
    modalProps: editModalProps,
    formProps: editFormProps,
    show: editModalShow,
    queryResult: editQueryResult,
  } = useModalForm<ISubscriptionRunSkuMap>({
    resource: "subscription-run-sku-maps",
    action: "edit",
    redirect: false,
  });

  const handleCreateOrders = () => {
    setIsLoading(true);
    const orderResult = createOrders(id);
    setIsLoading(false);

    toast
      .promise(orderResult, {
        loading: "Loading... please wait",
        success: "Orders Created",
        error: (error) => `${error}`,
      })
      .then(() => refetch());
  };

  // this get called when the user adds the sku , we then auto generate master title from the sku
  const handleCreateSelect = (value, option) => {
    // console.log("handleCreateSelect", value);
    // console.log("handleCreateSelectoption", option);

    //get the skumap data
    const existingSkuMapData = tableQueryResult.data?.data.find(
      (x) => x.executedSku === value
    );

    // console.log("handleCreateSelect => existingSkuMapData", existingSkuMapData);

    //i think this should be calling the subscription Skus instead.
    const masterSku = subscriptionSkuDataResult?.data?.find(
      // eslint-disable-next-line eqeqeq
      (x) => x.sku === value
    ); // diasble the === as value is type any and we dont want to create an interface

    if (!masterSku) {
      console.log("error creating sku map, master sku not found for : ", value);
      return;
    }

    createForm.setFieldsValue({
      masterSku: masterSku.sku,
    });

    createForm.setFieldsValue({
      masterTitle: masterSku.packName,
    });
  };

  //clear the masterTitle on deselecting the option from the select
  const handleCreateDeselect = () => {
    createForm.setFieldsValue({
      masterTitle: "",
    });
  };

  //state for the checkbox on the orders form
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  //checkbox magic
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,

    getCheckboxProps: (record) => {
      if (!OrderStatusProcessAvailable.includes(record.status))
        return {
          disabled: true,
        };
    },
  };

  // we get the ids from the selected rows from state and then call the processOrders service to process the orders
  const handleProcessSelectedOrders = () => {
    setProcessingOrders(true);
    const selectedOrderIds = selectedRowKeys.map((key) => Number(key));
    internalHandleProcessOrders(selectedOrderIds);
  };

  const internalHandleProcessOrders = async (ids: number[]) => {
    // await processOrders(ids, {
    //   processName: "Admin Edit Subscription Order - Process Order",
    // })
    //   .then(() => toast.success(`process request sent..`))
    //   .catch((error) => toast.error(`${error}`));

    toast.promise(
      processOrders(ids, {
        processName: "Admin Edit Subscription Order - Process Order",
      }),
      {
        loading: "Processing... please wait",
        success: "Orders Processed",
        error: (error) => `${error}`,
      }
    );

    //refetch the run order data
    refetch();
    setProcessingOrders(false);
  };

  const handleProcessOrder = (id: number) => {
    setProcessingOrders(true);
    internalHandleProcessOrders([id]);
  };

  const handleCancelOrder = async (id: number) => {
    toast.promise(
      cancelOrder(id, {
        processName: "Admin Edit Subscription Order - Cancel Order",
      }),
      {
        loading: "Processing... Please wait",
        success: "Order Cancelled",
        error: (error) => `${error}`,
      }
    );

    //refetch the run order data
    refetch();
  };

  const [isLoading, setIsLoading] = useState(false);

  const [isCompleteSkuMap, setIsCompleteSkuMap] = useState<boolean>(false);
  // once the sku maps is complete we need to populate the pack skuitems table, then the stock alloaction table call works
  //using force render so data is available for the stock allocation table immeadiately.
  const [isPackDataFetched, setIsPackDataFetched] = useState<boolean>(false);

  //If there are missing values we set isCompleteSkumMaps to false which will display a waring and disable the create subsription run button
  const checkForMissingSkuMapValues = (data: ISubscriptionRunSkuMap[]) => {
    //make sure that the data is not null
    if (data) {
      const missingValues = data.filter((x) => x.executedSku === "");
      if (missingValues.length > 0) {
        setIsCompleteSkuMap(false);
      } else {
        setIsCompleteSkuMap(true);
        if (isPackDataFetched === false) {
          refreshStockAllocation(Number(id)).then(() =>
            tableQueryResult.refetch()
          );
          setIsPackDataFetched(true);
        }
      }
    }
  };

  useEffect(() => {
    checkForMissingSkuMapValues(tableQueryResult?.data?.data);
    // console.log("use effect called => subrunedit");
  }, [tableQueryResult?.data?.data]);

  const runData = queryResult?.data?.data;

  //use this to disable the various buttons on the forms
  const runIsClosed: boolean =
    queryResult?.data?.data?.status === "closed" ? true : false;

  //run is pending check
  const runIsPending =
    queryResult?.data?.data?.status === "pending" ? true : false;

  //run is open check
  const runIsOpen = queryResult?.data?.data?.status === "open" ? true : false;

  const openRuncheck = (value) => {
    // get the orgianl ru status
    const originalRunStatus = queryResult?.data?.data?.status;

    // crete a modal to confirm the update
    const confirm = Modal.confirm;

    confirm({
      title: `Are you sure you want to set the run to ${value}?`,
      content: "Only one run should be open at a time.",
      onOk() {
        //set the form run status to the value
        form.setFieldsValue({
          status: value,
        });

        refetch();
      },
      onCancel() {
        form.setFieldsValue({
          status: originalRunStatus,
        });
      },
    });
  };

  const handleSetAsapAvailable = () => {
    //check if the skumaps are complete
    if (isCompleteSkuMap) {
      // get the value of the isAsapAvailable
      const originalIsAsapAvailable =
        queryResult?.data?.data?.isAsapPurchaseAvailable;

      const updatedIsAsapAvailable = !originalIsAsapAvailable;

      // create a alert to confirm the update
      const confirm = Modal.confirm;

      confirm({
        title: `Are you sure you want to change is Asap Purchase Available from ${originalIsAsapAvailable} to ${updatedIsAsapAvailable}?`,
        content: "",
        onOk() {
          //set the form isAsapAvailable to the value
          form.setFieldsValue({
            isAsapPurchaseAvailable: updatedIsAsapAvailable,
          });
          form.submit();
        },
        onCancel() {
          form.setFieldsValue({
            isAsapPurchaseAvailable: originalIsAsapAvailable,
          });
        },
      });
    } else {
      alert("Can't set available ASAP without complete Sku Maps!");
    }
  };

  const handleClickEmailMenuItem = (id, value) => {
    //crete aa modal to confirm the update
    const emailSubject = AdminEmails.find(
      (x) => x.key.toString() === value.key
    );
    const confirm = Modal.confirm;

    confirm({
      title: `Are you sure you want to send the ${emailSubject.label} email ?`,
      content: "",
      onOk() {
        sendEmail(
          emailSubject.label,
          id,
          "Admin Edit Subscription Order - Send Email"
        );
      },
      onCancel() {
        return;
      },
    });
  };

  return (
    <Edit title="Edit Run / Add Sku Maps" saveButtonProps={saveButtonProps}>
      <Form {...formProps}>
        <Row>
          <Col lg={24}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Form.Item
                label="Run Name"
                name="name"
                style={{ marginRight: "20px" }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Status"
                name="status"
                style={{ marginRight: "20px" }}
              >
                <Select
                  onSelect={openRuncheck}
                  options={SUBSCRIPTION_RUN_STATUSES}
                ></Select>
              </Form.Item>
              <div style={{ marginTop: "2px" }}>
                {runData && <ShowRunIsCurrent runStringProp={runData} />}
              </div>
            </div>
          </Col>
          <Col lg={24}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "1000px",
              }}
            >
              <Form.Item
                label="Run Date"
                name="runDate"
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
              >
                <DatePicker format={dateFormat} />
              </Form.Item>
              <Form.Item
                label="Open Date"
                name="openDateTime"
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
                style={{ marginLeft: "20px" }}
              >
                <DatePicker format={dateFormat} />
              </Form.Item>
              <Form.Item
                label="Close Date"
                name="closeDateTime"
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
                style={{ marginLeft: "20px" }}
              >
                <DatePicker format={dateFormat} />
              </Form.Item>
            </div>
          </Col>
          <Col lg={24}>
            {/* dummy form item to allow the tracking by refine actual complex render is below */}
            <div style={{ display: "none" }}>
              <Form.Item name="isAsapPurchaseAvailable">
                <Input />
              </Form.Item>
            </div>
            <div style={{ display: "flex" }}>
              <Form.Item style={{ marginRight: "20px" }}>
                <IsAsapAvailStatusTagRender
                  status={queryResult?.data?.data.isAsapPurchaseAvailable}
                />
              </Form.Item>

              {queryResult?.data?.data?.isAsapPurchaseAvailable ? (
                <Button
                  onClick={handleSetAsapAvailable}
                  disabled={runIsClosed || runIsPending}
                >
                  Disable ASAP Purchases
                </Button>
              ) : (
                <Button
                  onClick={handleSetAsapAvailable}
                  disabled={runIsClosed || runIsPending}
                >
                  Enable ASAP Purchases
                </Button>
              )}
            </div>
          </Col>
          <Col lg={24}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <SubscriptionRunDeleteButton
                id={Number(id)}
                runName={runData?.name}
              />
            </div>
          </Col>
        </Row>
      </Form>
      <Tabs type="card" style={{ marginTop: "30px" }}>
        <TabPane forceRender={isCompleteSkuMap} tab="Sku Maps" key="1">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IncompleteSkuMapWarning isComplete={isCompleteSkuMap} />
          </div>
          <List
            createButtonProps={{
              onClick: () => {
                //NOTE once the modal gets submitted the form gets cleared and the default form values never
                // get loaded again, so we have to pass them this way.
                createForm.setFieldsValue({
                  subscription_run: id,
                });
                createModalShow();
              },
              disabled: runIsClosed,
            }}
            title="Add / Edit Sku Maps "
          >
            <Table {...tableProps} rowKey="id">
              <Table.Column<ISubscriptionRunSkuMap>
                title="Id"
                dataIndex="id"
                sorter
              />
              <Table.Column<ISubscriptionRunSkuMap>
                title="Master Title"
                dataIndex="masterTitle"
              />
              <Table.Column<ISubscriptionRunSkuMap>
                title="Executed Title"
                dataIndex="executedTitle"
              />
              <Table.Column<ISubscriptionRunSkuMap>
                title="Executed Sku"
                dataIndex="executedSku"
                sorter
              />
              <Table.Column<ISubscriptionRunSkuMap>
                sorter
                title="Master Sku"
                dataIndex="masterSku"
              />
              <Table.Column<ISubscriptionRunSkuMap>
                title="Asap Avail?"
                dataIndex="isAsapPurchaseAvailable"
                align="center"
                render={(_, record) => {
                  //if the asap purchase is available then return a checkmark
                  if (record.isAsapPurchaseAvailable) {
                    //return a tag with a checkmark
                    return (
                      <Fragment>
                        <Tag color={StatusColours.YES}>Yes</Tag>
                      </Fragment>
                    );
                  }
                  //if the asap purchase is not available then return a cross
                  return <Tag color={StatusColours.NO}>No</Tag>;
                }}
              />
              <Table.Column<ISubscriptionRunSkuMap>
                title="Edit"
                dataIndex="id"
                render={(_, record) => (
                  <Space>
                    <EditButton
                      // disabled={runIsClosed}
                      onClick={() => editModalShow(record.id.toString())}
                    />

                    <DeleteButton
                      resourceNameOrRouteName="subscription-run-sku-maps"
                      recordItemId={record.id}
                      disabled={runIsClosed}
                    />
                  </Space>
                )}
              />
            </Table>
          </List>

          <CreateSkuMapModalForm
            formProps={createFormProps}
            modalProps={createModalProps}
            onSelect={handleCreateSelect}
            onDeSelect={handleCreateDeselect}
          />
          <EditSkuMapModalForm
            formProps={editFormProps}
            modalProps={editModalProps}
            queryData={editQueryResult?.data?.data}
          />
        </TabPane>
        {isCompleteSkuMap && (
          <TabPane tab="Orders" key="2">
            <List
              pageHeaderProps={{
                extra: (
                  <Fragment>
                    <Button
                      disabled={!isCompleteSkuMap || runIsPending}
                      onClick={() => refetch()}
                    >
                      Refresh Orders
                    </Button>
                    <Button
                      onClick={handleProcessSelectedOrders}
                      loading={processingOrders}
                      disabled={
                        selectedRowKeys.length === 0 ||
                        runIsClosed ||
                        runIsPending
                      }
                    >
                      Process Selected
                    </Button>

                    <Button
                      loading={isLoading}
                      disabled={
                        !isCompleteSkuMap || runIsClosed || runIsPending
                      }
                      onClick={() => handleCreateOrders()}
                    >
                      Create Subscription Orders
                    </Button>
                  </Fragment>
                ),
              }}
              title="Orders"
            >
              <Table
                {...orderTableProps}
                rowSelection={rowSelection}
                rowKey="id"
              >
                <Table.Column<ISubscriptionOrder> title="Id" dataIndex="id" />

                <Table.Column<ISubscriptionOrder>
                  title="Freq"
                  dataIndex={["subscription", "frequency"]}
                />
                <Table.Column<ISubscriptionOrder>
                  title="Customer"
                  dataIndex={["subscription", "customerName"]}
                />
                <Table.Column<ISubscriptionOrder>
                  title="Subscription Order Title"
                  dataIndex="title"
                />

                <Table.Column<ISubscriptionOrder>
                  title="Executed Sku"
                  dataIndex="executedSku"
                />

                <Table.Column<ISubscriptionOrder>
                  title="Status"
                  dataIndex="status"
                  defaultFilteredValue={getDefaultFilter(
                    "subscription_run",
                    filters
                  )}
                  render={(_, record) => (
                    <SubscriptionOrderStatusTagRender status={record.status} />
                  )}
                  filterDropdown={(props) => (
                    <FilterDropdown {...props}>
                      <Checkbox.Group>
                        <Checkbox value="pending">Pending</Checkbox>
                        <Checkbox value="queued">Queued</Checkbox>
                        <Checkbox value="paid">Paid</Checkbox>
                        <Checkbox value="payment_error">Payment Error</Checkbox>
                        <Checkbox value="ordered">Ordered</Checkbox>
                        <Checkbox value="orderinterventionrequired">
                          Intervention Required
                        </Checkbox>
                        <Checkbox value="cancelled">Cancelled</Checkbox>
                      </Checkbox.Group>
                    </FilterDropdown>
                  )}
                />

                <Table.Column<ISubscriptionOrder>
                  title="Order Status"
                  dataIndex="orderStatus"
                  render={(_, record) => (
                    <SubscriptionOrderOrderStatusTagRender
                      status={record.orderStatus}
                    />
                  )}
                  filterDropdown={(props) => (
                    <FilterDropdown {...props}>
                      <Checkbox.Group>
                        <Checkbox value="pending">Pending</Checkbox>
                        <Checkbox value="ordered">Ordered</Checkbox>
                        <Checkbox value="shipped">Shipped</Checkbox>
                        <Checkbox value="failed">Failed</Checkbox>
                        <Checkbox value="orderinterventionrequired">
                          Intervention Required
                        </Checkbox>
                      </Checkbox.Group>
                    </FilterDropdown>
                  )}
                />

                <Table.Column<ISubscriptionOrder>
                  title="Order #"
                  dataIndex="prontoOrderId"
                />
                <Table.Column<ISubscriptionOrder>
                  title="Actions"
                  align="center"
                  render={(_, record) => {
                    return (
                      <Fragment>
                        <Space direction="horizontal">
                          <Popover
                            content="Process this order"
                            title="Process"
                            trigger="hover"
                          >
                            <Button
                              style={{
                                background: "#DCEDC8",
                                borderColor: "#33691E",
                              }}
                              onClick={() => handleProcessOrder(record.id)}
                              disabled={
                                selectedRowKeys.includes(record.id) ||
                                runIsClosed ||
                                runIsPending ||
                                !OrderStatusProcessAvailable.includes(
                                  record.status
                                )
                              }
                            >
                              <ThunderboltTwoTone twoToneColor="green" />
                            </Button>
                          </Popover>
                          <Popover content="Edit the order" title="Edit">
                            <EditButton
                              style={{
                                background: "#F5F5F5",
                                borderColor: "#000000",
                              }}
                              hideText
                              resourceName="subscription-orders"
                              recordItemId={record.id}
                            />
                          </Popover>
                          <Dropdown
                            trigger={["click"]}
                            overlay={
                              <Menu
                                selectable
                                items={AdminEmails}
                                onClick={(item) => {
                                  handleClickEmailMenuItem(
                                    record.subscription.id,
                                    item
                                  );
                                }}
                              >
                                {(item) => (
                                  <Menu.Item key={item.value}>
                                    {item.label}
                                  </Menu.Item>
                                )}
                              </Menu>
                            }
                          >
                            <Popover
                              content="click to view emails you can send"
                              title="Email"
                              trigger="hover"
                            >
                              <Button
                                style={{
                                  background: "#BBDEFB",
                                  borderColor: "#0D47A1",
                                }}
                              >
                                <MailTwoTone />
                              </Button>
                            </Popover>
                          </Dropdown>

                          <Popover
                            content="Cancel this order"
                            title="Cancel"
                            trigger="hover"
                          >
                            <Button
                              style={{
                                background: "#F6C7CC",
                                borderColor: "#AC1E1E",
                              }}
                              onClick={() => handleCancelOrder(record.id)}
                              disabled={runIsClosed || runIsPending}
                            >
                              <CloseSquareTwoTone twoToneColor="red" />
                            </Button>
                          </Popover>
                        </Space>
                      </Fragment>
                    );
                  }}
                />
              </Table>
            </List>
          </TabPane>
        )}

        {isCompleteSkuMap && !runIsPending && (
          <TabPane tab="Subscription Info" key="3">
            <div>
              <TotalSubscriptionTable id={id} />
            </div>
          </TabPane>
        )}

        {(runIsPending || runIsOpen) && (
          <TabPane
            disabled={!isCompleteSkuMap}
            forceRender={isCompleteSkuMap}
            tab="Pack Allocation"
            key="4"
          >
            <div>
              <PackAllocationTable id={Number(id)} />
            </div>
          </TabPane>
        )}

        {(runIsPending || runIsOpen) && (
          <TabPane
            disabled={!isCompleteSkuMap}
            forceRender={isCompleteSkuMap}
            tab="Stock Allocation"
            key="5"
          >
            <div>
              <StockAllocationTable id={Number(id)} />
            </div>
          </TabPane>
        )}
      </Tabs>
    </Edit>
  );
};
