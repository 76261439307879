import { Space, Tag, Typography } from "@pankod/refine-antd";
const { Text, Link } = Typography;
export const IsExportedTagRender = (isExported) => {
  if (isExported) {
    return (
      <Space>
        <Text strong>Exported</Text>
        <Tag color="green">Yes</Tag>
      </Space>
    );
  } else {
    <Space>
      <Typography>Exported</Typography>
      <Tag color="red">No</Tag>
    </Space>;
  }
};
