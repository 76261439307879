import {
  Row,
  Col,
  List,
  Table,
  TextField,
  useTable,
  Button,
  Space,
  EditButton,
  Form,
  FormProps,
  Icons,
  Input,
  Select,
  Card,
  Menu,
  Dropdown,
  Modal,
  Tag,
  FilterDropdown,
  Checkbox,
  useSelect,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useList,
} from "@pankod/refine-core";
import { AdminEmails, SUBSCRIPTION_STATUSES } from "../../constants";

import { Fragment, useEffect, useMemo, useState } from "react";
import {
  cancelSubscription,
  exportSubscriptionDataCSV,
  getNextPackDate,
  pauseSubscription,
  restartSubscription,
  sendEmail,
} from "Services/productServices";
import { ISubscription, ISubscriptionRun, ISubscriptionSku } from "Interfaces";
import { SubscriptionStatusTagRender } from "./SubscriptionStatusTagRender";
import dayjs from "dayjs";

import { FirstLetterToUpperCase } from "Utilities/FirstLetterToUpperCase";
import { useUpcomingMonthsToFilterPacks } from "./useUpcomingMonthsToFilterPacks";
import { exportToCSV } from "Utilities/exportToCsv";

export enum StatusActions {
  Active = "activate",
  Pause = "pause",
  Cancel = "cancel",
  Restart = "restart",
  Completed = "completed",
}

export const SubscriptionList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps, tableQueryResult } = useTable<
    ISubscription,
    HttpError,
    SubscriptionFilterVariables
  >({
    defaultSetFilterBehavior: "replace",
    initialPageSize: -1,
    hasPagination: false,
    permanentFilter: [
      {
        field: "_limit",
        operator: "eq",
        value: -1,
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { status, generalSearch } = params;
      // this search currently wont work because it relies on the strapi objects
      // not worth the time to implement some kind of workaround for templating
      if (generalSearch) {
        filters.push({
          operator: "or",
          value: [
            {
              field: "customerName",
              operator: "contains",
              value: generalSearch || undefined,
            },
            {
              field: "masterSku",
              operator: "contains",
              value: generalSearch || undefined,
            },
            {
              field: "shippingAddressLine1",
              operator: "contains",
              value: generalSearch || undefined,
            },
            {
              field: "shippingPostcode",
              operator: "contains",
              value: generalSearch || undefined,
            },
            {
              field: "shippingLocality",
              operator: "contains",
              value: generalSearch || undefined,
            },
          ],
        });
      }
      filters.push({
        field: "status",
        operator: "eq",
        value: status,
      });

      return filters;
    },
  });

  const { data: runData } = useList<ISubscriptionRun>({
    resource: `subscription-runs`,
  });

  const { selectProps: subSkuSelectProps } = useSelect<ISubscriptionSku>({
    resource: "subscription-skus",
    optionLabel: "packName",
    optionValue: "sku",
  });

  // create a memoised list of the subscription runs
  const subscriptionRunDates = useMemo(() => {
    return runData?.data;
  }, [runData]);

  const [subscriptionState, setSubscriptionState] = useState(
    // because of the way useState compares objects, we need to make a new object otherwise it won't rerender
    // just updating a number wont do the job because it will be the same object
    new Map<number, StatusActions>()
  );

  const updateSubscriptionState = (id: number, state?: StatusActions) => {
    setSubscriptionState((current) => {
      const result = new Map(current);
      if (state) {
        result.set(id, state);
      } else {
        result.delete(id);
      }
      return result;
    });
  };

  const handleSubscriptionChange = async (
    id: number,
    action: StatusActions
  ) => {
    //use switch statement to handle the different actions
    switch (action) {
      case StatusActions.Cancel:
        updateSubscriptionState(id, StatusActions.Cancel);
        await cancelSubscription(id, {
          //TODO need to implement this everywhere to get the process name
          processName: "Admin Edit Subscription - Cancel",
        });
        // await sleep(3000);
        updateSubscriptionState(id);
        //refresh the table
        tableQueryResult.refetch();
        break;
      case StatusActions.Restart:
        updateSubscriptionState(id, StatusActions.Restart);

        await restartSubscription(id, {
          //TODO need to implement this everywhere to get the process name
          processName: "Admin Edit Subscription - Restart",
        });
        // await sleep(3000);
        updateSubscriptionState(id);
        //refresh the table
        tableQueryResult.refetch();
        break;
      case StatusActions.Pause:
        updateSubscriptionState(id, StatusActions.Pause);

        await pauseSubscription(id, {
          //TODO need to implement this everywhere to get the process name
          processName: "Admin Edit Subscription - Pause",
        });
        // await sleep(3000);
        updateSubscriptionState(id);
        //refresh the table
        tableQueryResult.refetch();
        break;
    }
  };

  const handleClickEmailMenuItem = (id, value) => {
    //crete aa modal to confirm the update
    const emailSubject = AdminEmails.find(
      (x) => x.key.toString() === value.key
    );
    const confirm = Modal.confirm;

    confirm({
      title: `Are you sure you want to send the ${emailSubject.label} email ?`,
      content: "",
      onOk() {
        sendEmail(
          emailSubject.label,
          id,
          "Admin Edit Subscription  - Send Email"
        );
      },
      onCancel() {
        return;
      },
    });
  };

  // create a useState to store the nextPackDateArray
  const [nextPackDateArray, setNextPackDateArray] = useState([]);
  // get the unique months from the nextPackDateArray
  const monthFilterOptions = useUpcomingMonthsToFilterPacks(nextPackDateArray);

  useEffect(() => {
    let active = true;
    getNextPackDate().then((res) => {
      // set the data
      const output = [];
      res.data.forEach((x) => {
        output.push({
          id: x.subscriptionId,
          nextPackDate: x.nextPackDateUTC,
        });
      });
      if (active) {
        setNextPackDateArray(output);
      }
    });

    return () => {
      active = false;
    };
  }, []);

  const handleCSVExport = async () => {
    const csvRows = [];

    filteredDataSource.forEach((sub) => {
      console.log(sub);
      csvRows.push(sub.id);
    });

    const response = exportSubscriptionDataCSV({ data: csvRows });
    response.then((res) => {
      exportToCSV(res.data, "subscriptionData");
    });
  };

  const returnStartDate = (subscriptionOrders) => {
    // no need for the shallow copy - filter returns a new array
    const orderedOrders = subscriptionOrders.filter(
      (x) => x.status === "ordered"
    );

    // because we don't have the run dates in our subscription lets cross check the subscription run id
    // with the subscription run dates
    const orderedOrdersWithRunDates = orderedOrders.map((x) => {
      if (subscriptionRunDates) {
        const run = subscriptionRunDates?.find(
          (y) => y.id === x.subscription_run.id
        );

        return run?.runDate;
      }
    });

    // now we have the run dates we need to sort them
    const sortedOrderedOrdersWithRunDates = orderedOrdersWithRunDates.sort(
      (a, b) => {
        return new Date(a).getTime() - new Date(b).getTime();
      }
    );

    // return the first item in the array
    return sortedOrderedOrdersWithRunDates[0];
  };

  const [nextPackMonthFilter, setNextPackMonthFilter] = useState("");
  const [isNextPackMonthFilterSet, setIsNextPackMonthFilterSet] =
    useState(false);

  // we are client side filtering this because the nextPackDate is a calculated field
  // so we already have the nextPackDate in an array so once we set the filterMonth/year we can intercept
  // the data before it gets fed into the table and filter it. The other option in future might be to do the calc on the server side and change / update the model

  const filteredDataSource = isNextPackMonthFilterSet
    ? tableProps.dataSource.filter((record) => {
        const nextPackDateResult = nextPackDateArray.find(
          (x) => x.id === record.id
        );
        return (
          nextPackDateResult &&
          dayjs(nextPackDateResult.nextPackDate).format("YYYY-MM") ===
            nextPackMonthFilter
        );
      })
    : tableProps.dataSource;

  const handleClearMonthFilter = () => {
    setNextPackMonthFilter("");
    setIsNextPackMonthFilterSet(false);
  };

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <List
          headerProps={{
            extra: (
              <Button onClick={handleCSVExport} type="primary">
                Export to CSV
              </Button>
            ),
          }}
        >
          <Card title="Filters">
            <SubsFilter formProps={searchFormProps} />
          </Card>
          <Table dataSource={filteredDataSource} rowKey="id">
            <Table.Column<ISubscription>
              dataIndex="customerName"
              sorter={(a, b) => a.customerName.localeCompare(b.customerName)}
              defaultSortOrder="ascend"
              title="Name"
              render={(value) => <TextField value={value} />}
            ></Table.Column>
            <Table.Column<ISubscription>
              title="Frequency"
              dataIndex="frequency"
              render={(_, record) => {
                const frequency = record.frequency;
                const startDate = `Started: ${dayjs(
                  returnStartDate(record.subscription_orders)
                ).format("MMM YY")}`;
                return (
                  <div>
                    <div style={{ fontSize: "13px" }}>
                      {FirstLetterToUpperCase(frequency)}
                    </div>
                    <div>
                      <Tag>{startDate}</Tag>
                    </div>
                  </div>
                );
              }}
              // filter the table by frequency, and clear the filter
              filterDropdown={(props) => (
                <FilterDropdown {...props}>
                  <Checkbox.Group>
                    <Checkbox value="monthly">Monthly</Checkbox>
                    <Checkbox value="bimonthly">Bi-Monthly</Checkbox>
                    <Checkbox value="quarterly">Quarterly</Checkbox>
                    <Checkbox value="onceoff">Once Off</Checkbox>
                  </Checkbox.Group>
                </FilterDropdown>
              )}
            />

            <Table.Column<ISubscription>
              dataIndex="id"
              title="Next Pack"
              render={(value) => {
                // get the next pack date from the array
                const nextPackDateResult = nextPackDateArray.find(
                  (x) => x.id === value
                );
                // if the next pack date is not null then return the date
                // (day.js by default parses to local time so no need to convert from utc)
                if (nextPackDateResult) {
                  //  set the format to MMMM YY
                  return dayjs(nextPackDateResult.nextPackDate).format(
                    "MMMM YY"
                  );
                } else {
                  return "N/A";
                }
              }}
              // we are getting a little mix and match here with clear filters on the actual filter
              // dropdown and the onSelect because refine gives us the clear filters button already and
              // we need to intercept the onSelect to set the filterMonth/year
              filterDropdown={(props) => (
                <FilterDropdown
                  {...props}
                  clearFilters={handleClearMonthFilter}
                >
                  <Select
                    placeholder="Select Month"
                    value={nextPackMonthFilter}
                    onSelect={(value) => {
                      setNextPackMonthFilter(value);
                      setIsNextPackMonthFilterSet(true);
                    }}
                  >
                    {monthFilterOptions.map((option) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </FilterDropdown>
              )}
            ></Table.Column>
            <Table.Column<ISubscription>
              title="Shipping Address"
              render={(_, record) => {
                return (
                  <Fragment>
                    {" "}
                    {record.shippingAddressLine1} {record.shippingLocality}{" "}
                    {record.shippingPostcode}{" "}
                  </Fragment>
                );
              }}
            />
            <Table.Column<ISubscription>
              title="Status"
              dataIndex="status"
              render={(_, record) => {
                return <SubscriptionStatusTagRender status={record.status} />;
              }}
            />

            <Table.Column<ISubscription>
              title="Pack"
              dataIndex="masterSku"
              render={(_, record) => {
                return <Fragment>{record.title}</Fragment>;
              }}
              filterDropdown={(props) => (
                <FilterDropdown {...props}>
                  <Select placeholder="Select Pack" {...subSkuSelectProps} />
                </FilterDropdown>
              )}
            ></Table.Column>

            <Table.Column<ISubscription>
              title="Actions"
              render={(_, record) => {
                return (
                  <Fragment>
                    <Space>
                      {record?.status === "paused" ? (
                        <Button
                          loading={
                            subscriptionState.get(record.id) ===
                            StatusActions.Active
                          }
                          disabled={subscriptionState.has(record.id)}
                          onClick={() =>
                            handleSubscriptionChange(
                              record.id,
                              StatusActions.Restart
                            )
                          }
                        >
                          Make Active
                        </Button>
                      ) : (
                        <Button
                          loading={
                            subscriptionState.get(record.id) ===
                            StatusActions.Pause
                          }
                          disabled={subscriptionState.has(record.id)}
                          onClick={() =>
                            handleSubscriptionChange(
                              record.id,
                              StatusActions.Pause
                            )
                          }
                        >
                          Pause
                        </Button>
                      )}
                      {record?.status === "cancelled" ? (
                        <Button
                          loading={
                            subscriptionState.get(record.id) ===
                            StatusActions.Restart
                          }
                          disabled={subscriptionState.has(record.id)}
                          onClick={() =>
                            handleSubscriptionChange(
                              record.id,
                              StatusActions.Restart
                            )
                          }
                        >
                          Re-start
                        </Button>
                      ) : (
                        <Button
                          loading={
                            subscriptionState.get(record.id) ===
                            StatusActions.Cancel
                          }
                          disabled={subscriptionState.has(record.id)}
                          onClick={() =>
                            handleSubscriptionChange(
                              record.id,
                              StatusActions.Cancel
                            )
                          }
                        >
                          Cancel
                        </Button>
                      )}
                    </Space>
                  </Fragment>
                );
              }}
            />
            <Table.Column<ISubscription>
              title="Emails"
              render={(_, record) => {
                return (
                  <Dropdown
                    overlay={
                      <Menu
                        selectable
                        items={AdminEmails}
                        onClick={(item) => {
                          handleClickEmailMenuItem(record.id, item);
                        }}
                      >
                        {(item) => (
                          <Menu.Item key={item.value}>{item.label}</Menu.Item>
                        )}
                      </Menu>
                    }
                  >
                    <Button>Send Email</Button>
                  </Dropdown>
                );
              }}
            />

            <Table.Column<ISubscription>
              title="Edit"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};

interface SubscriptionFilterVariables {
  status: string;
  generalSearch: string;
}

const SubsFilter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
  return (
    <Form layout="vertical" {...formProps}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Space align="end" size={"large"}>
            <Form.Item label="Search" name="generalSearch">
              <Input
                style={{ width: "260px" }}
                allowClear
                placeholder="Search Id, Sku's or Title..."
                prefix={<Icons.SearchOutlined />}
                // when we clear the input we want to resubmit the form and fetch the fresh data
                onChange={(e) => {
                  if (e.target.value === "") {
                    formProps.form?.submit();
                  }
                }}
              />
            </Form.Item>

            <Form.Item label="Status" name="status">
              <Select
                style={{ width: "150px" }}
                allowClear
                options={SUBSCRIPTION_STATUSES}
                placeholder="Status"
                onClear={() => {
                  formProps.form?.submit();
                }}
              />
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" type="primary">
                Filter
              </Button>
            </Form.Item>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};
