import { ImageField } from "@pankod/refine-antd";
import { Tag } from "antd";
import { IProductTagModel } from "Interfaces";

export const CoverImageRender = ({ imageUrl }: { imageUrl: string }) => {
  // const placeholderImageUrl = "https://via.placeholder.com/300";
  // the null in the prdocut model should be handled here i thinnk - its currently handled in a seperate function in the edit
  if (imageUrl == null) {
    return (
      <>
        <ImageField value="https://via.placeholder.com/30" width={300} />
      </>
    );
  }

  return (
    <>
      <ImageField
        value={imageUrl}
        width={300}
        // fallback={placeholderImageUrl}
      />
    </>
  );
};
