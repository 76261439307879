import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

// create a react component that takes a datetime and then returns that datetime in local time melbourne using dayjs
//also need to provide subtract 1 days, 3, 7 dyas etc

export const dateTimeOffsetMelbourneHelper = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const today = dayjs();

  const todayZonedtoMelbourne = dayjs.tz(today, "Australia/Melbourne");

  const startOfToday = todayZonedtoMelbourne.startOf("day");

  const startOfYesterday = startOfToday.subtract(1, "day");
  const startOf3DaysAgo = startOfToday.subtract(3, "day");
  const startOf7DaysAgo = startOfToday.subtract(7, "day");
  //start of 2 weeks ago
  const startOf2WeeksAgo = startOfToday.subtract(14, "day");
  const startOf1MonthAgo = startOfToday.subtract(1, "month");
  const startOf3MonthsAgo = startOfToday.subtract(3, "month");

  //create an array of dayjs objects using starrtof Yesertdayy, 3 days ago, 7 days ago
  const dateOffsets = {
    todayZonedtoMelbourne,
    startOfToday,
    startOfYesterday,
    startOf3DaysAgo,
    startOf7DaysAgo,
    startOf2WeeksAgo,
    startOf1MonthAgo,
    startOf3MonthsAgo,
  };

  return dateOffsets;
};
