import { useMemo } from "react";
import dayjs from "dayjs";

type NextPackMonthOption = {
  label: string;
  value: string;
};

type NextPackDataObject = {
  subscriptionId: number;
  nextPackDateUTC: string;
  nextPackDate: string;
};

export const useUpcomingMonthsToFilterPacks = (data: NextPackDataObject[]) => {
  const monthsToFilter = useMemo(() => {
    // loop over the data and return the distinct months
    return data.reduce((acc, x) => {
      const month = dayjs(x.nextPackDate).format("YYYY-MM");
      if (!acc.includes(month)) {
        acc.push(month);
      }
      return acc;
    }, []);
  }, [data]);

  // return the options
  return monthsToFilter.map((month) => ({
    label: dayjs(month).format("MMMM YYYY"),
    value: month,
  })) as NextPackMonthOption[];
};
