import { Table } from "@pankod/refine-antd";
import { ISubscriptionHistory } from "Interfaces";
import dayjs from "dayjs";

export const SubscriptionHistoryTable = ({
  data,
}: {
  data: ISubscriptionHistory[] | undefined;
}) => {
  //sort the data by date
  if (data) {
    data.sort((a, b) => {
      return dayjs(a.actionDateTime).isBefore(dayjs(b.actionDateTime)) ? 1 : -1;
    });
  }

  return (
    <Table dataSource={data} rowKey="id">
      <Table.Column<ISubscriptionHistory>
        dataIndex="date"
        title="Date"
        render={(_, record) => {
          //formatted with day.js
          return dayjs(record.actionDateTime).format("MM/DD/YYYY: hh:mm:ss");
        }}
      />
      <Table.Column<ISubscriptionHistory>
        dataIndex="action"
        title="Action"
        render={(_, record) => {
          return record.actionType;
        }}
      />
      <Table.Column<ISubscriptionHistory>
        dataIndex="actionedBy"
        title="Actioned By"
        render={(_, record) => {
          return record.actionedBy;
        }}
      />
      <Table.Column<ISubscriptionHistory>
        title="Description"
        render={(_, record) => {
          return record.actionDescription;
        }}
      />
    </Table>
  );
};
