import {
    Button,
    Divider,
    Edit,
    Form,
    Input,
    ListButton,
    RefreshButton,
    Select,
    Table,
    useForm,
    useTable,
    useSelect,
    Row,
    Col,
    DateField,
    Space, useModal, Modal, Card, Checkbox,
} from "@pankod/refine-antd";
import {CrudFilters, HttpError, IResourceComponentsProps} from "@pankod/refine-core";
import {IOrder, ITag, ITagType, Product} from "Interfaces";
import {ProductImageThumbnailRender} from "Pages/Products/ProductImageThumbnailRender";
import {ProductStateIconList} from "Pages/Products/ProductStateIconList";
import {PublishedProductStatusIcon} from "Pages/Products/PublishedProductStatusIcon";
import {VariantStockOnHand} from "Pages/Products/VariantStockOnHand";
import React, {Fragment, useEffect, useState} from "react";
import toast from "react-hot-toast";
import {pushTagForUpdate, updateOrderMeta, updateProductTags} from "Services/productServices";
import {ProdList} from "../../Products";
import {PUBLICATION_STATUS_LIVE, PUBLICATION_STATUS_PREVIEW} from "../../../constants";
import {ProdFilter} from "../../Products/ProdFilter";
import {ProdFilterMini} from "./ProdFilterMini";
import * as _ from 'underscore'

interface ProductFilterVariables {
    masterStatus: string;
    generalSearch: string;
    childSkus: string;
    productType: string;
    title: string;
    publicationStatus: string;
    filterOutBlankCover: boolean;
    filterOutBlankDesc: boolean;
}

export const TagEdit: React.FC<IResourceComponentsProps> = () => {
    const {formProps, saveButtonProps, queryResult, id} = useForm<ITag>({
        metaData: {publicationState: "preview"},
    });

    const [shouldFetchData, setShouldFetchData] = useState(false);
    const {show: showProductModal, close: closeProductModal, modalProps} = useModal();


    useEffect(() => {
        if (modalProps.visible) {
            setShouldFetchData(true)
        }
    }, [modalProps.visible]);

    const {selectProps} = useSelect<ITagType>({
        resource: "tag-types",
        optionLabel: "name",
        optionValue: "id",
        sort: [
            {
                field: "name",
                order: "asc",
            },
        ],
        defaultValue: queryResult.data?.data?.tagType?.id,
    });

    const manualPushTagForUpdate = () => {
        const updatePromise = pushTagForUpdate(id);
        toast.promise(updatePromise, {
            loading: "Loading...",
            success: (data) => `${data.data.message}`,
            error: (error) => `${error}`,
        });
    };

    const updateTags = async () => {
        let selectedTag = queryResult.data?.data;

        const updatePromises = selectedOrders.map(order =>
            updateProductTags({
                id: order.id,
                tags: [...order.tags, selectedTag]
            })
        );

        try {
            await Promise.all(updatePromises.map(promise =>
                toast.promise(promise, {
                    loading: "Loading...",
                    success: (data) => `${data?.data?.title} updated`,
                    error: (error) => `${error}`,
                })
            ));

            setIsAllSelected(false);
            closeProductModal();
            currentProductsQueryResult.refetch();
        } catch (error) {
            console.error("Error updating tags:", error);
        }
    };


    const onFinish = (values) => {
        console.log(values)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    //get the products with this tag id - including un-published
    const {tableProps,tableQueryResult:currentProductsQueryResult} = useTable<Product, HttpError>({
        resource: "products",

        initialPageSize: 50,
        permanentFilter: [
            {
                field: "_publicationState",
                operator: "eq",
                value: "preview",
            },
            {
                field: "tags.id",
                operator: "eq",
                value: id,
            },
        ],
    });

    const {
        tableProps: productTableProps,
        searchFormProps,
        tableQueryResult: productTableQueryResult
    } = useTable<Product, HttpError, ProductFilterVariables>({
        resource: "products",

        initialPageSize: 50,
        permanentFilter: [
            {
                field: "_publicationState",
                operator: "eq",
                value: "preview",
            },
        ],

        onSearch: (params) => {
            const filters: CrudFilters = [];
            // setFilters({}, "replace");
            const {
                masterStatus,
                generalSearch,
                productType,
                title,
                publicationStatus,
                filterOutBlankCover,
                filterOutBlankDesc,
            } = params;

            if (generalSearch) {
                filters.push({
                    operator: "or",
                    value: [
                        {
                            field: "id",
                            operator: "contains",
                            value: generalSearch,
                        },
                        {
                            field: "childSkus",
                            operator: "contains",
                            value: generalSearch,
                        },
                        {
                            field: "title",
                            operator: "contains",
                            value: generalSearch,
                        },
                    ],
                });
            }

            filters.push(
                {
                    field: "masterStatus",
                    operator: "eq",
                    value: masterStatus,
                },
                {
                    field: "productType",
                    operator: "contains",
                    value: productType,
                },
                {
                    field: "title",
                    operator: "contains",
                    value: title,
                }
            );


            // filters.push(
            //     {
            //       field:"coverImage.formats.thumbnail.url",
            //       operator: "null",
            //       value: filterOutBlankCover,
            //     }
            // )

            if (filterOutBlankDesc) {
                filters.push(
                    {
                        field: "description",
                        operator: "null",
                        value: true
                    }
                )
            } else {
                filters.push(
                    {
                        field: "description",
                        operator: "null",
                        value: undefined
                    }
                )
            }

            if (!publicationStatus) {
                // haven't picked anything from the dropdown, they don't care. Include the normal filter for preview, which will include draft and published
                filters.push(
                    {
                        field: "_publicationState",
                        operator: "eq",
                        value: "preview",
                    },
                    {
                        field: "published_at",
                        operator: "null",
                        value: undefined,
                    }
                );
            } else if (publicationStatus === PUBLICATION_STATUS_PREVIEW) {
                // picked Draft from the dropdown. We only want to show draft products. Which means we need to include the preview ones
                // and exclude anything that has a published date

                filters.push(
                    {
                        field: "_publicationState",
                        operator: "eq",
                        value: "preview",
                    },
                    {
                        field: "published_at",
                        operator: "null",
                        value: true,
                    }
                );
            } else if (publicationStatus === PUBLICATION_STATUS_LIVE) {
                // picked Published from the dropdown. We only want to show published products. Which means that we apply no filters at all here
                filters.push(
                    {
                        field: "_publicationState",
                        operator: "eq",
                        value: undefined,
                    },
                    {
                        field: "published_at",
                        operator: "null",
                        value: undefined,
                    }
                );
            } else {
                console.warn(
                    `Product List onSearch. Unknown publicationStatus selected: '${publicationStatus}'`
                );
            }

            return filters;
        },

    });


    const [selectMap, setSelectMap] = useState({})

    const [selectedOrders, setSelectedOrders] = useState([])

    const [isAllSelected, setIsAllSelected] = useState(false)

    const selectAllOrders = (selected: boolean) => {
        console.log("SELECTING ALL ORDERS")
        // _.map(tableData?.data, o => {
        //     // console.log(o)
        //     selectOrder(o, selected)
        //     // console.log(o,selected)
        // })


        if (selected) {
            setSelectedOrders([
                ...productTableQueryResult.data.data
            ])
        } else {
            setSelectedOrders([])
        }

    }

    useEffect(() => {
        console.log("IS ALL SELECTED", isAllSelected)
        selectAllOrders(isAllSelected)
    }, [isAllSelected]);

    const selectOrder = (record, selected: boolean) => {
        console.log(record, selected)
        if (selected) {
            setSelectedOrders([
                ...selectedOrders,
                record
            ])

        } else {
            setSelectedOrders(_.reject(selectedOrders, (order) => {
                return order.id === record.id
            }))
        }
    }

    useEffect(() => {
        console.log("mutating selectionsmap", selectedOrders)
        const mapKeys = _.pluck(selectedOrders, 'id')

        console.log(mapKeys)

        setSelectMap(_.object(mapKeys, mapKeys.map(one => true)))

        console.log(selectMap)
    }, [selectedOrders]);


    return (
        <Fragment>

            <>
                <Modal
                    title="Select Products"
                    {...modalProps}
                    footer={null}
                    width={1000}
                >
                    <>

                        <Card title="Filters">
                            <ProdFilterMini formProps={searchFormProps}/>
                        </Card>

                        <Card title="Selected Products">
                            <Fragment>
                                {!_.isEmpty(selectedOrders) && <Button onClick={updateTags} style={{marginTop: "10px"}}>
                                    Update Tag
                                </Button>}

                                {selectedOrders && selectedOrders.map((record) => (
                                    <Fragment key={record.id}>
                                        <div style={{marginTop: "10px"}}>
                                            <PublishedProductStatusIcon productProp={record}/>
                                        </div>
                                    </Fragment>
                                ))}
                            </Fragment>
                        </Card>

                        <Table {...productTableProps} rowKey="id">


                            <Table.Column<IOrder>
                                dataIndex="meta"
                                title={(<>
                                    <Checkbox checked={isAllSelected} onChange={e => {
                                        // selectOrder(record, e.target.checked)
                                        setIsAllSelected(e.target.checked)
                                    }}/>
                                </>)}
                                render={(_, record) => <>
                                    <Checkbox checked={selectMap[record.id]} onChange={e => {
                                        selectOrder(record, e.target.checked)
                                    }}/>
                                </>}
                            />

                            <Table.Column dataIndex="id" title="ID" sorter/>
                            <Table.Column<Product>
                                dataIndex="title"
                                title="Title"
                                render={(_, record) => (
                                    <Fragment>
                                        <PublishedProductStatusIcon
                                            productProp={record}
                                        ></PublishedProductStatusIcon>
                                    </Fragment>
                                )}
                            ></Table.Column>
                            <Table.Column<Product>
                                title="Quick State"
                                render={(_, record) => (
                                    <ProductStateIconList productProp={record}></ProductStateIconList>
                                )}
                            />

                            <Table.Column dataIndex="masterSku" title="MasterSku" sorter/>
                            <Table.Column dataIndex="productType" title="Product Type" sorter/>
                            <Table.Column dataIndex="masterStatus" title="Status" sorter/>
                            {/* thanks to Keegan for this - https://ant.design/components/table/#Column (render) */}
                            <Table.Column<Product>
                                title="Variant SOH"
                                align="center"
                                render={(_, record) => (
                                    <>
                                        <Space size={"small"}>
                                            <VariantStockOnHand variants={record?.variants}/>
                                            {record?.variants?.map((v) => v.quantityAvailable).join("/")}
                                        </Space>
                                    </>
                                )}
                            />

                            <Table.Column<Product>
                                title="Cover Image"
                                align="center"
                                render={(_, record) => (
                                    //add the render
                                    <ProductImageThumbnailRender
                                        imageUrl={record?.coverImage?.formats?.thumbnail?.url}
                                    ></ProductImageThumbnailRender>
                                )}
                            />
                        </Table>

                    </>

                </Modal>
            </>


            <Edit
                saveButtonProps={saveButtonProps}
                pageHeaderProps={{
                    extra: (
                        <Fragment>
                            <ListButton/>
                            <RefreshButton/>

                            <Button onClick={manualPushTagForUpdate}>
                                Manual Tag Update
                            </Button>
                        </Fragment>
                    ),
                }}
            >
                <Form {...formProps} layout="vertical">
                    <Row gutter={[8, 16]}>
                        <Col span={6}>
                            <Form.Item label="Name" name="name">
                                <Input/>
                            </Form.Item>

                            <Form.Item label="Code" name="code">
                                <Input/>
                            </Form.Item>

                            {/* NOTE   This works and produces a post that strapi will accept however it doesnt match the documented API
          The documented API expects tag-type to be a string for id, this prodcues tagtype as an object with a property called id with a value
          */}
                            <Form.Item label="Tag Type" name={["tagType", "id"]}>
                                <Select {...selectProps} maxTagTextLength={100}/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Divider type="vertical" style={{height: "240px"}}/>
                        </Col>

                        <Col span={8}>
                            <Form.Item label="Created At" name="created_at">
                                <DateField format="LLL" value="created_at"/>
                            </Form.Item>
                            <Form.Item label="Updated At" name="created_at">
                                <DateField format="LLL" value="updated_at"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Edit>
            <Divider/>

            <Button type="primary" onClick={() => {
                showProductModal();
            }}>
                Assign Products
            </Button>

            <Divider/>

            <Table {...tableProps} rowKey="id">
                <Table.Column dataIndex="id" title="ID" sorter/>
                <Table.Column<Product>
                    dataIndex="title"
                    title="Title"
                    render={(_, record) => (
                        <Fragment>
                            <PublishedProductStatusIcon
                                productProp={record}
                            ></PublishedProductStatusIcon>
                        </Fragment>
                    )}
                ></Table.Column>
                <Table.Column<Product>
                    title="Quick State"
                    render={(_, record) => (
                        <ProductStateIconList productProp={record}></ProductStateIconList>
                    )}
                />

                <Table.Column dataIndex="masterSku" title="MasterSku" sorter/>
                <Table.Column dataIndex="productType" title="Product Type" sorter/>
                <Table.Column dataIndex="masterStatus" title="Status" sorter/>
                {/* thanks to Keegan for this - https://ant.design/components/table/#Column (render) */}
                <Table.Column<Product>
                    title="Variant SOH"
                    align="center"
                    render={(_, record) => (
                        <>
                            <Space size={"small"}>
                                <VariantStockOnHand variants={record?.variants}/>
                                {record?.variants?.map((v) => v.quantityAvailable).join("/")}
                            </Space>
                        </>
                    )}
                />

                <Table.Column<Product>
                    title="Cover Image"
                    align="center"
                    render={(_, record) => (
                        //add the render
                        <ProductImageThumbnailRender
                            imageUrl={record?.coverImage?.formats?.thumbnail?.url}
                        ></ProductImageThumbnailRender>
                    )}
                />
            </Table>
        </Fragment>
    );
};
