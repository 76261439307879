import {
  List,
  TextField,
  Table,
  useTable,
  Space,
  EditButton,
  Form,
  Input,
  Icons,
  Button,
  FormProps,
  Row,
  Select,
  Card,
  useSelect,
  SelectProps,
  Col,
  Badge,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import { Fragment } from "react";

import {
  IAttribute,
  IProductAttributeType,
  IProductTagModel,
} from "../../Interfaces";
import {
  ProductAttributeIconRender,
  ProductAttributeIconRenderProps,
} from "./ProductAttributeIconRender";

export const ProdAttributeList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<
    IAttribute,
    HttpError,
    ProdAttributeFilterVariables
  >({
    initialPageSize: 50,
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],

    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name, productAttributeType } = params;

      filters.push(
        {
          field: "name",
          operator: "contains",
          value: name || undefined,
        },
        {
          field: "productAttributeType",
          operator: "eq",
          value: productAttributeType || undefined,
        }
      );

      return filters;
    },
  });

  const { selectProps } = useSelect<IProductAttributeType>({
    resource: "product-attribute-types",
    optionLabel: "name",
    optionValue: "id",
    fetchSize: 50,
    sort: [
      {
        field: "name",
        order: "asc",
      },
    ],
  });

  return (
    <Fragment>
      <Card title="Filters">
        <ProdAttributeFilter
          formProps={searchFormProps}
          attributeTypeSelectProps={selectProps}
        />
      </Card>

      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column<IAttribute>
            dataIndex="id"
            title="id"
            render={(value) => <TextField value={value} />}
            sorter
          />
          <Table.Column<IAttribute>
            dataIndex="code"
            title="Code"
            render={(value) => <TextField value={value} />}
            sorter
          />

          <Table.Column<IAttribute>
            dataIndex="name"
            title="Name"
            render={(value) => <TextField value={value} />}
            sorter
          />
          <Table.Column<IAttribute>
            dataIndex="overrideName"
            title="Override Name"
            render={(value) => <TextField value={value} />}
            sorter
          />
          <Table.Column<IAttribute>
            dataIndex="hexColor"
            title="Hex Color"
            render={(value) => (
              <Fragment>
                <Badge color={`${value}`} />
                <TextField value={value} style={{ color: `${value}` }} />
              </Fragment>
              ///render a badge the same colour as value
            )}
            sorter
          />

          <Table.Column<IAttribute>
            dataIndex="icon"
            title="Icon"
            render={(_, record) => {
              const renderProp: ProductAttributeIconRenderProps = {
                iconUrl: record.icon?.url,
                width: undefined,
                height: undefined,
              };
              return <ProductAttributeIconRender prop={renderProp} />;
            }}
          />

          <Table.Column<IAttribute>
            dataIndex="productAttributeType"
            title="Product Attribute Type"
            render={(_, record) => {
              return <Fragment>{record?.productAttributeType?.name}</Fragment>;
              // <TextField value={record.tagType.name} />}
            }}
            sorter
          />

          <Table.Column<IAttribute>
            title="Edit"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </List>
    </Fragment>
  );
};

interface ProdAttributeFilterVariables {
  name: string;
  productAttributeType: string;
}

const ProdAttributeFilter: React.FC<{
  formProps: FormProps;
  attributeTypeSelectProps: SelectProps;
}> = ({ formProps, attributeTypeSelectProps }) => {
  return (
    <Form layout="vertical" {...formProps}>
      <Row gutter={[16, 16]}>
        <Col span={10}>
          <Space align="end" size={"large"}>
            <Form.Item label="Search Name" name="name">
              <Input
                allowClear
                placeholder="Attribute name"
                prefix={<Icons.SearchOutlined />}
              />
            </Form.Item>
            <Form.Item label="Tag Type" name="productAttributeType">
              <Select
                allowClear
                style={{ width: "200px" }}
                {...attributeTypeSelectProps}
                maxTagTextLength={100}
              />
            </Form.Item>

            <Form.Item>
              <Button htmlType="submit" type="primary">
                Filter
              </Button>
            </Form.Item>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};
