import {
  Button,
  Col,
  Edit,
  Form,
  Input,
  ListButton,
  Modal,
  RefreshButton,
  Row,
  SaveButton,
  Select,
  Space,
  TextField,
  useForm,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useCustom,
  useApiUrl,
  useOne,
  useList,
} from "@pankod/refine-core";
import {
  IExecutionHistory,
  ISubscription,
  ISubscriptionHistory,
  ISubscriptionSku,
} from "Interfaces";
import { Fragment, ReactHTML, useEffect, useMemo, useState } from "react";
import { ExecutionHistoryTable } from "../ExecutionHistoryTable";
import { SubscriptionHistoryTable } from "../SubscriptionHistoryTable";
import {
  pauseSubscription,
  cancelSubscription,
  updateFrequency,
  updateSubscriptionPack,
} from "Services/productServices";
import { SubscriptionStatusDisplay } from "./SubscriptionStatusDisplay";
import {
  SUBSCRIPTION_FREQUENCIES,
  SUBSCRIPTION_STATUSES,
} from "../../../constants";
import { SubscriptionStartDateDisplay } from "./SubscriptionStartDateDisplay";
import { SubscriptionLifeTimeValueDisplay } from "./SubscriptionLifetimeValueDataDisplay";
import { create } from "domain";
const { Option } = Select;

export const SubscriptionEdit: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl();
  const {
    formProps,
    saveButtonProps,
    queryResult: editSubscriptionQueryResult,
    queryResult: { refetch },
    form,
    id,
  } = useForm<ISubscription>();

  const statusData = editSubscriptionQueryResult?.data?.data?.status;

  //get the required data for the execution history table from the custom endpoint.
  const { data: executionListData, refetch: refetchExecutionHistory } =
    useCustom<IExecutionHistory[]>({
      url: `${apiUrl}/subscriptions/${id}/orders`,
      method: "get",
    });

  //get the subscription history from the query result - we dont need the custom endpoint for this as it is
  //specific to this record, not the entire list.
  const subscriptionHistory: ISubscriptionHistory[] =
    editSubscriptionQueryResult.data?.data?.subscription_histories || [];

  const { data: subscriptionSkuDataResult } = useList<ISubscriptionSku>({
    resource: "subscription-skus",
    config: {
      hasPagination: false,
      pagination: { current: 1, pageSize: 500 },
    },
  });

  const masterSkuTagOptions = useMemo(() => {
    return subscriptionSkuDataResult?.data
      ?.map(
        // build an array with the id and label for the select option
        (t) => ({ id: t.id, label: `${t.sku} : ${t.packName}`, sku: t.sku })
      )
      .sort(
        // then sort everything
        (a, b) =>
          a.label.localeCompare(b.label, undefined, { sensitivity: "base" })
      )
      .map(
        // then build the final options
        (t) => (
          <Option
            key={t.id}
            value={t.sku.toString()}
            title={t.label}
            sku={t.sku}
          >
            {t.label}
          </Option>
        )
      );
  }, [subscriptionSkuDataResult?.data]);

  const [subscriptionPack, setSubscriptionPack] = useState<string[]>([]);

  //set state for title
  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    if (subscriptionPack) {
      //find the title from the subscriptionSkuDataResult using the subscriptionPack
      const subscriptionPackTitle = subscriptionSkuDataResult?.data?.find(
        (t) => t.sku === subscriptionPack[0]
      )?.packName;
      // console.log("subscriptionPackTitle", subscriptionPackTitle);
      setTitle(subscriptionPackTitle);
    }
  }, [subscriptionPack, subscriptionSkuDataResult?.data]);

  const handleSelect = (value) => {
    const processName = "Change Subscription Pack - Admin UI";
    const originalMasterSku =
      editSubscriptionQueryResult?.data?.data?.masterSku;

    const originalData = subscriptionSkuDataResult?.data?.find(
      (t) => t.sku === originalMasterSku
    );

    const originalPackPrice = originalData?.price;
    const originalPackName = originalData?.packName;

    const packDetails = subscriptionSkuDataResult?.data?.find(
      (t) => t.sku === value
    );
    const newMasterSku = packDetails?.sku;
    const newPackName = packDetails?.packName;
    const newPackPrice = packDetails?.price;

    Modal.confirm({
      title: "Confirm Change of Pack",
      content: (
        <>
          Are you sure you want to change the pack from{" "}
          <b>{originalPackName}</b> to <b>{newPackName}</b> ? <br />
          This will change the master sku from <b>
            {originalMasterSku}
          </b> to <b>{newMasterSku}</b> and the price from{" "}
          <b>${originalPackPrice}</b> to <b> ${newPackPrice}</b>
        </>
      ),
      width: "600px",
      onOk: () => {
        setSubscriptionPack((prevValue) => prevValue.concat([value]));
        //write the new pack into the subscription history via api call
        updateSubscriptionPack(id.toString(), value, processName).then(() => {
          refetch();
        });
      },
      onCancel: () => {
        form.setFieldsValue({ masterSku: originalMasterSku });
      },
    });
  };
  const handleDeselect = (value) => {
    setSubscriptionPack((prevValue) => prevValue.filter((pv) => pv !== value));
  };

  useEffect(() => {
    form.setFieldsValue({
      title: title,
    });
  }, [title]);

  const handlePauseSubscription = async (id) => {
    await pauseSubscription(Number(id), {
      processName: "Admin Edit Subscription - Pause",
    });
    //refresh the page to get the new status
    refetch();
    refetchExecutionHistory();
  };

  const handleCancelSubscription = async () => {
    await cancelSubscription(Number(id), {
      processName: "Admin Edit Subscription - Cancel",
    });
    //refresh the page to get the new status
    refetch();
    refetchExecutionHistory();
  };

  const handleFrequencyChange = async (frequency) => {
    //get the query result.frequency and if cancel then set the value back into the form
    const subscriptionFrequency =
      editSubscriptionQueryResult?.data?.data?.frequency;

    //create the processNAme
    const processName = `Admin Edit Subscription - Update Frequency`;

    // crete a modal to confirm the update
    const confirm = Modal.confirm;

    confirm({
      title: `Are you sure you want to update the frequency to ${frequency}?`,
      content: "This action may affect the users orders.",
      onOk() {
        //update the frequency
        updateFrequency(id.toString(), frequency, processName).then(() => {
          //refresh the page to get the new status
          refetch();
          refetchExecutionHistory();
        });
      },
      onCancel() {
        //set the value back into the form
        form.setFieldsValue({
          frequency: subscriptionFrequency,
        });
      },
    });
  };

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      pageHeaderProps={{
        extra: (
          <Fragment>
            <ListButton />
            <RefreshButton />
          </Fragment>
        ),
      }}
    >
      <Form {...formProps} layout="vertical">
        <Row gutter={[32, 16]}>
          <Col span={12}>
            <Fragment>
              <SubscriptionStatusDisplay status={statusData} />
            </Fragment>
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: "10px",
              }}
            >
              <Space direction="horizontal" align="end">
                <Button
                  onClick={() => {
                    handlePauseSubscription(id);
                  }}
                >
                  Pause Subscription
                </Button>
                <Button
                  onClick={() => {
                    handleCancelSubscription();
                  }}
                >
                  Cancel Subscription
                </Button>
              </Space>
            </div>
          </Col>
        </Row>
        <Row gutter={[32, 16]}>
          <Col span={12}>
            <div style={{ display: "none" }}>
              <Form.Item label="Title" name="title">
                <Input />
              </Form.Item>
            </div>
            <Form.Item label="Name" name="customerName">
              <Input />
            </Form.Item>
            <Form.Item label="Frequency" name="frequency">
              <Select
                //set disabled when ststusData is == SubscriptionStatuses array where label is "Cancelled"
                disabled={
                  statusData ===
                  SUBSCRIPTION_STATUSES.find(
                    (status) => status.label === "cancelled"
                  )?.value
                }
                onSelect={handleFrequencyChange}
                style={{ width: "180px" }}
                allowClear
                options={SUBSCRIPTION_FREQUENCIES}
              />
            </Form.Item>
            <Form.Item label="Pack Type" name="masterSku">
              <Select
                disabled={
                  editSubscriptionQueryResult?.data?.data?.status ===
                  "cancelled"
                }
                allowClear
                value={subscriptionPack}
                maxTagTextLength={100}
                onSelect={handleSelect}
                onDeselect={handleDeselect}
                filterOption={(input, option) => {
                  return (
                    option.key.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {masterSkuTagOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row gutter={[32, 16]}>
              <Col span={12}>
                <Form.Item label="Address Line 1" name="shippingAddressLine1">
                  <Input />
                </Form.Item>
                <Form.Item label="Address Line 2" name="shippingAddressLine2">
                  <Input />
                </Form.Item>
                <Form.Item label="Locality" name="shippingLocality">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Region" name="shippingRegion">
                  <Input />
                </Form.Item>
                <Form.Item label="Country" name="shippingCountry">
                  <Input />
                </Form.Item>
                <Form.Item label="Postcode" name="shippingPostcode">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[32, 16]}>
          <Col span={12}>
            <Row>
              <Col span={18}>Subscription Pack Price</Col>
              <Col span={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingBottom: "10px",
                  }}
                >
                  <Fragment>
                    <div>
                      <b>
                        $
                        {
                          editSubscriptionQueryResult?.data?.data
                            ?.subscriptionPrice
                        }
                      </b>
                    </div>
                  </Fragment>
                </div>
              </Col>
            </Row>
          </Col>
          {/* NOTE the payment info data is not directly below the heading here, its slightly further down */}
          <Col span={12}>
            <b>
              <i>Payment Info</i>
            </b>
          </Col>
          <Row style={{ marginBottom: "30px" }}></Row>
        </Row>
        <Row gutter={[32, 16]}>
          <Col span={12}>
            <Row>
              <Col span={18}>Subscription Quantity</Col>
              <Col span={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingBottom: "10px",
                  }}
                >
                  <Fragment>
                    <div>
                      <b>
                        {
                          editSubscriptionQueryResult?.data?.data
                            ?.quantity
                        }
                      </b>
                    </div>
                  </Fragment>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={18}>Subscription Lifetime Value</Col>
              <Col span={6}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingBottom: "10px",
                  }}
                >
                  <SubscriptionLifeTimeValueDisplay id={id} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={18}>Started</Col>
              <Col span={6}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <SubscriptionStartDateDisplay
                    data={executionListData?.data}
                  />
                </div>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Form.Item label="Type" name="paymentScheme">
                <TextField value="paymentScheme" />
              </Form.Item>
              <Form.Item label="Last 4 Digits" name="paymentLast4">
                <TextField value="paymentLast4" />
              </Form.Item>
              <Form.Item label="Expiry" name="paymentExpiry">
                <TextField value="paymentExpiry" />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <SaveButton {...saveButtonProps} htmlType="submit" />
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: "30px" }}></Row>
        <b>Execution History</b>
        <ExecutionHistoryTable data={executionListData?.data} />
        <b>Subscription History</b>
        <SubscriptionHistoryTable data={subscriptionHistory} />
      </Form>
    </Edit>
  );
};
