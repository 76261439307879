import {
  Modal,
  Form,
  Input,
  Select,
  FormProps,
  ModalProps,
} from "@pankod/refine-antd";
import { useList } from "@pankod/refine-core";
import { ISubscriptionSku } from "Interfaces";
import { useMemo } from "react";

const { Option } = Select;

export const CreateSkuMapModalForm: React.FC<{
  formProps: FormProps;
  modalProps: ModalProps;
  onSelect: (value: any, label: any) => void;
  onDeSelect: (value: any, label: any) => void;
}> = ({ formProps, modalProps, onDeSelect, onSelect }) => {
  const { data: subscriptionSkuDataResult } = useList<ISubscriptionSku>({
    resource: "subscription-skus",
    config: {
      pagination: { current: 1, pageSize: 500 },
    },
  });

  const masterSkuTagOptions = useMemo(() => {
    return subscriptionSkuDataResult?.data
      ?.map(
        // build an array with the id and label for the select option
        (t) => ({ id: t.sku, label: `${t.sku} : ${t.packName}`, sku: t.sku })
      )
      .sort(
        // then sort everything
        (a, b) =>
          a.label.localeCompare(b.label, undefined, { sensitivity: "base" })
      )
      .map(
        // then build the final options
        (t) => (
          <Option key={t.id} value={t.id} title={t.label} sku={t.sku}>
            {t.label}
          </Option>
        )
      );
  }, [subscriptionSkuDataResult?.data]);

  return (
    <Modal {...modalProps} title="Add Sku Map">
      <Form {...formProps} layout="vertical" key="id">
        {/* HACK: This div is here because of the way that refine/ant design handles the setting of form vlaues, 
        without this form item here the payload ownt indclude the relevent id*/}
        <div style={{ display: "none" }}>
          <Form.Item name="subscription_run">
            <Input></Input>
          </Form.Item>
        </div>
        <Form.Item
          label="masterSku"
          name="masterSku"
          style={{ width: "480px" }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            allowClear
            style={{ width: "480px" }}
            maxTagTextLength={100}
            onSelect={onSelect}
            onDeselect={onDeSelect}
            filterOption={(input, option) => {
              return (
                option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }}
          >
            {masterSkuTagOptions}
          </Select>
        </Form.Item>
        <Form.Item
          label="Master Title"
          name="masterTitle"
          style={{ width: "350px" }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Executed Title"
          name="executedTitle"
          style={{ width: "350px" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="executedSku"
          name="executedSku"
          style={{ width: "350px" }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
