import { BaseOptions } from "vm";

export const API_URL = "http://localhost:8450";
export const TOKEN_KEY = "strapi-jwt-token";

export const DEFAULT_OPEN_DATE = 27;

export enum StatusColours {
  PENDING = "yellow",
  QUEUED = "purple",
  PAID = "green",
  PAYMENT_ERROR = "orange",
  ORDERED = "blue",
  SHIPPED = "teal",
  ORDER_INTERVENTION_REQUIRED = "dark red",
  CANCELLED = "black",
  FAILED = "violet",
  OPEN = "green",
  CLOSED = " red",
  YES = "green",
  NO = "red",
}

export const AdminEmails = [{ key: 1, label: "Insufficent Funds" }];

export const SHIPPING_CARRIERS = [
  {
    label: "SHIPPIT",
    value: "shippit",
  },
  {
    label: "OPTIMO",
    value: "optimo",
  },
];
export const SHIPPING_NOTIFICATION_OPTIONS = [
  {
    label: "SMS",
    value: "sms",
  },
  {
    label: "EMAIL",
    value: "email",
  },
  {
    label: "BOTH",
    value: "both",
  },
];
export const ORDER_SHIPPING_STATUS = [
  {
    label: "ORDERED",
    value: "ordered",
  },

  {
    label: "SHIPPED",
    value: "shipped",
  },
];

export enum SubscriptionOrderStatuses {
  PENDING = "pending",
  QUEUED = "queued",
  PAID = "paid",
  PAYMENT_ERROR = "payment_error",
  ORDERED = "ordered",
  ORDER_INTERVENTION_REQUIRED = "orderinterventionrequired",
  CANCELLED = "cancelled",
}
export enum SubscriptionOrderOrderStatuses {
  PENDING = "pending",
  ORDERED = "ordered",
  SHIPPED = "shipped",
  FAILED = "failed",
  ORDER_INTERVENTION_REQUIRED = "orderinterventionrequired",
}
export enum SubscriptionRunStatuses {
  PENDING = "pending",
  OPEN = "open",
  CLOSED = "closed",
}

export enum StatusActions {
  Active = "activate",
  Pause = "pause",
  Cancel = "cancel",
  Restart = "restart",
  Completed = "completed",
}
export const OrderStatusProcessAvailable = ["pending", "payment_error"];

export const SUBSCRIPTION_RUN_STATUSES = [
  {
    label: "open",
    value: "open",
  },
  {
    label: "closed",
    value: "closed",
  },
  {
    label: "pending",
    value: "pending",
  },
];
export const SUBSCRIPTION_FREQUENCIES = [
  {
    label: "monthly",
    value: "monthly",
  },
  {
    label: "quarterly",
    value: "quarterly",
  },
  {
    label: "bimonthly",
    value: "bimonthly",
  },
];

export const SUBSCRIPTION_STATUSES = [
  {
    label: "active",
    value: "active",
  },
  {
    label: "paused",
    value: "paused",
  },
  {
    label: "cancelled",
    value: "cancelled",
  },
  {
    label: "completed",
    value: "completed",
  },
];
export const SUBSCRIPTION_ORDER_ORDERSTATUSES = [
  {
    label: "pending",
    value: "pending",
  },
  {
    label: "ordered",
    value: "ordered",
  },
  {
    label: "shipped",
    value: "shipped",
  },
  {
    label: "failed",
    value: "failed",
  },
  {
    label: "order intervention required",
    value: "orderinterventionrequired",
  },
];

export const SUBSCRIPTION_ORDER_STATUSES = [
  {
    label: "pending",
    value: "pending",
  },
  {
    label: "queued",
    value: "queued",
  },
  {
    label: "paid",
    value: "paid",
  },
  {
    label: "payment_error",
    value: "payment_error",
  },
  {
    label: "ordered",
    value: "ordered",
  },
  {
    label: "order intervention required",
    value: "orderinterventionrequired",
  },
  {
    label: "cancelled",
    value: "cancelled",
  },
];

export const ORDER_VALUE_RANGES_0_50 = "0";
export const ORDER_VALUE_RANGES_50_100 = "50";
export const ORDER_VALUE_RANGES_100_200 = "100";
export const ORDER_VALUE_RANGES_200_OVER = "200";
export const ORDER_VALUE_RANGES = [
  {
    label: "less than $50",
    value: ORDER_VALUE_RANGES_0_50,
  },
  {
    label: "between $50 and $100",
    value: ORDER_VALUE_RANGES_50_100,
  },
  {
    label: "between $100 and $200",
    value: ORDER_VALUE_RANGES_100_200,
  },
  {
    label: "over $200",
    value: ORDER_VALUE_RANGES_200_OVER,
  },
];

//SETUP DATES FOR THE ORDER SELECT
export const DATE_SEARCH_RANGES_TODAY = "today";
export const DATE_SEARCH_RANGES_YESTERDAY = "yesterday";
export const DATE_SEARCH_RANGES_LAST_3_DAYS = "last3";
export const DATE_SEARCH_RANGES_LAST_7_DAYS = "last7";
export const DATE_SEARCH_RANGES_CUSTOM = "custom";

export const DATE_SEARCH_RANGES = [
  {
    label: "Today",
    value: DATE_SEARCH_RANGES_TODAY,
  },
  {
    label: "Yesterday",
    value: DATE_SEARCH_RANGES_YESTERDAY,
  },
  {
    label: "Last 3 Days",
    value: DATE_SEARCH_RANGES_LAST_3_DAYS,
  },
  {
    label: "Last 7 Days",
    value: DATE_SEARCH_RANGES_LAST_7_DAYS,
  },
  {
    label: "Custom",
    value: DATE_SEARCH_RANGES_CUSTOM,
  },
];

export const PRODUCT_STATUSES = [
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Inactive",
    value: "Inactive",
  },
  {
    label: "Purchase Stop",
    value: "PurchaseStop",
  },
];
export const PUBLICATION_STATUS_LIVE = "live";
export const PUBLICATION_STATUS_PREVIEW = "preview";
export const PUBLICATION_STATUSES = [
  {
    label: "Published",
    value: PUBLICATION_STATUS_LIVE,
  },
  {
    label: "Draft",
    value: PUBLICATION_STATUS_PREVIEW,
  },
];

export const PRODUCT_TYPES = [
  {
    label: "Beer",
    value: "Beer",
  },
  {
    label: "Wine",
    value: "Wine",
  },
  {
    label: "Miscellaneous",
    value: "Miscellaneous",
  },
  {
    label: "Packs",
    value: "Packs",
  },
  {
    label: "Giftware",
    value: "Giftware",
  },
  {
    label: "Spirits",
    value: "Spirits",
  },
  {
    label: "AlcoholFree",
    value: "AlcoholFree",
  },
  {
    label: "Food",
    value: "Food",
  },
  {
    label: "Beverages",
    value: "Beverages",
  },
  {
    label: "Unspecified",
    value: "Unspecified",
  },
  {
    label: "Cheese",
    value: "Cheese",
  },
  {
    label: "Tobacco",
    value: "Tobacco",
  },
  {
    label: "WinePremium",
    value: "WinePremium",
  },
  {
    label: "Other",
    value: "Other",
  },
  {
    label: "Administration",
    value: "Administration",
  },
  {
    label: "DeletedItems",
    value: "DeletedItems",
  },
  {
    label: "GiftCards",
    value: "GiftCards",
  },
];

export const MASTER_PRODUCT_TYPES = [
  {
    label: "Standard",
    value: "Standard",
  },
  {
    label: "Gift Card",
    value: "Giftcard",
  },
  {
    label: "Subscription Definition",
    value: "SubscriptionDefinition",
  },
  {
    label: "Subscription Instance",
    value: "SubscriptionIstance",
  },
  {
    label: "Giftwrap",
    value: "Giftwrap",
  },
];

export const FURTHER_DISCOUNTS = [
  { label: true, value: true },
  { label: false, value: false },
];

//create a 24 hour time range for every half hour

export const SHIPPING_HOURS = [
  { label: "0000", value: "0000" },
  { label: "0030", value: "0030" },
  { label: "0100", value: "0100" },
  { label: "0130", value: "0130" },
  { label: "0200", value: "0200" },
  { label: "0230", value: "0230" },
  { label: "0300", value: "0300" },
  { label: "0330", value: "0330" },
  { label: "0400", value: "0400" },
  { label: "0430", value: "0430" },
  { label: "0500", value: "0500" },
  { label: "0530", value: "0530" },
  { label: "0600", value: "0600" },
  { label: "0630", value: "0630" },
  { label: "0700", value: "0700" },
  { label: "0730", value: "0730" },
  { label: "0800", value: "0800" },
  { label: "0830", value: "0830" },
  { label: "0900", value: "0900" },
  { label: "0930", value: "0930" },
  { label: "1000", value: "1000" },
  { label: "1030", value: "1030" },
  { label: "1100", value: "1100" },
  { label: "1130", value: "1130" },
  { label: "1200", value: "1200" },
  { label: "1230", value: "1230" },
  { label: "1300", value: "1300" },
  { label: "1330", value: "1330" },
  { label: "1400", value: "1400" },
  { label: "1430", value: "1430" },
  { label: "1500", value: "1500" },
  { label: "1530", value: "1530" },
  { label: "1600", value: "1600" },
  { label: "1630", value: "1630" },
  { label: "1700", value: "1700" },
  { label: "1730", value: "1730" },
  { label: "1800", value: "1800" },
  { label: "1830", value: "1830" },
  { label: "1900", value: "1900" },
  { label: "1930", value: "1930" },
  { label: "2000", value: "2000" },
  { label: "2030", value: "2030" },
  { label: "2100", value: "2100" },
  { label: "2130", value: "2130" },
  { label: "2200", value: "2200" },
  { label: "2230", value: "2230" },
  { label: "2300", value: "2300" },
  { label: "2330", value: "2330" },
];
