import { IProductAttributeTree, IProductTagTree, Product } from "Interfaces";
import React, { useEffect, useMemo, useState } from "react";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useList,
} from "@pankod/refine-core";
import {
  Card,
  Col,
  Form,
  FormProps,
  Icons,
  Input,
  List,
  Row,
  Select,
  Space,
  useTable,
  Table,
  Button,
  InputNumber,
  Switch,
} from "@pankod/refine-antd";
import { getProductBySku, setProductsRank } from "Services/productServices";
import toast from "react-hot-toast";

const { Option } = Select;

export const Merchandising: React.FC<IResourceComponentsProps> = () => {
  interface IProductRankExportModel {
    ids: number[];
    rank: number;
    groupCode?: string;
  }

  interface IProductMerchandising {
    id: number;
    name: string;
    masterSku: string;
    isNewProductUntil: string;
    productRank: number;
  }

  const { data: productAttributeData } = useList<IProductAttributeTree>({
    resource: "product-attributes",
    config: {
      pagination: { current: 1, pageSize: 5000 },
    },
  });

  const { data: tagDataResult } = useList<IProductTagTree>({
    resource: "tags",
    config: {
      pagination: { current: 1, pageSize: 5000 },
    },
  });

  //get the entire list of products
  const { tableProps, searchFormProps, tableQueryResult } = useTable<
    IProductMerchandising,
    HttpError,
    ProductMerchandisingFilterVariables
  >({
    resource: "products/merchandisingtable",

    initialPageSize: 50,
    // NOTE when using the "if" test below we need to use this replace setting otherwise it won't trigger the form reload
    defaultSetFilterBehavior: "replace",

    onSearch: (params) => {
      const filters: CrudFilters = [];
      const {
        generalSearch,
        productAttribute,
        productTag,
        isNew,
        rankMax,
        rankMin,
      } = params;

      if (generalSearch) {
        filters.push({
          operator: "or",
          // these need to be changed to reflect the actual fields of product
          value: [
            {
              field: "masterSku",
              operator: "contains",
              value: generalSearch || undefined,
            },
            {
              field: "title",
              operator: "contains",
              value: generalSearch || undefined,
            },
            {
              field: "name",
              operator: "contains",
              value: generalSearch || undefined,
            },
          ],
        });
      }

      filters.push({
        field: "productAttributes.id",
        operator: "eq",
        value: productAttribute || undefined,
      });

      filters.push({
        field: "tags.id",
        operator: "eq",
        value: productTag || undefined,
      });

      if (isNew) {
        filters.push({
          field: "isNewProductUntil",
          operator: "gt",
          value: new Date().toISOString(),
        });
      } else {
        filters.push({
          field: "isNewProductUntil",
          operator: "eq",
          value: undefined,
        });
      }
      // if there is a rankMax value then add the filter
      if (rankMax && !rankMin) {
        filters.push({
          field: "productRanks.rank",
          operator: "lte",
          value: rankMax,
        });
      }
      // if there is a rankMin value then add the filter
      if (rankMin && !rankMax) {
        filters.push({
          field: "productRanks.rank",
          operator: "gte",
          value: rankMin,
        });
      }
      // if there is a rankMin and rankMax value then add the filter
      if (rankMin && rankMax) {
        filters.push({
          field: "productRanks.rank",
          operator: "between",
          value: [rankMin, rankMax],
        });
      }

      return filters;
    },
  });

  const refreshTable = async () => {
    tableQueryResult.refetch();
  };

  //  this holds the Sku of the product that we want to rank
  const [masterSkuRankTarget, setMasterSkuRankTarget] = useState<string>("");

  const [productToSet, setProductToSet] = useState<Product>(null);

  const [masterRank, setMasterRank] = useState<number>(0);

  const [productRankList, setProductRankList] = useState<
    IProductRankExportModel[]
  >([]);

  // this holds the productId's of the selected rows in the table
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  //checkbox magic
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    // Extract the rank and put it into a custom object for each selected row and push it into the productRankList
    const newProductRankList: IProductRankExportModel[] = [];

    newSelectedRowKeys.forEach((key) => {
      console.log("key :", key);
      newProductRankList.push({
        ids: [key as number],
        rank: 0,
        groupCode: "", // you can set the groupCode to an empty string or whatever value you need
      });
    });

    setProductRankList([...productRankList, ...newProductRankList]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const productAttributeOptions = useMemo(() => {
    const attributes = productAttributeData?.data || [];
    // console.log("productAttributeOptions => attributes :", attributes);
    return attributes
      .map((at) => ({
        id: at.id,
        label: `${at.productAttributeType?.name}: ${
          at.overrideName ? at.overrideName : at.name
        }`,
        value: at.id,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, undefined, { sensitivity: "base" })
      )
      .map((option) => (
        <Option key={option.id} value={option.value} label={option.label}>
          {option.label}
        </Option>
      ));
  }, [productAttributeData]);

  const productTagOptions = useMemo(() => {
    const tags = tagDataResult?.data || [];
    return tags
      .map((t) => ({
        id: t.id,
        label: `${t.tagType.name}: ${t.name}`,
        value: t.id,
      }))
      .sort((a, b) =>
        a.label.localeCompare(b.label, undefined, { sensitivity: "base" })
      )
      .map((option) => (
        <Option key={option.id} value={option.value} label={option.label}>
          {option.label}
        </Option>
      ));
  }, [tagDataResult]);

  //   when the user types something in this input we want to get the sku and then display the product name if it exists in the product list
  const handleGetSku = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setMasterSkuRankTarget(event.target.value);

    const response = await getProductBySku(event.target.value).then((res) => {
      return res.data[0];
    });
    setProductToSet(response);
    setProductRankList([
      ...productRankList,
      {
        ids: [response.id],
        rank: 0,
        groupCode: "", // you can set the groupCode to an empty string or whatever value you need
      },
    ]);
  };

  const productName = productToSet?.name;

  const handleExportRank = () => {
    const productRankExportModel: IProductRankExportModel[] = [];

    productRankList.forEach((productRank) => {
      productRankExportModel.push({
        ids: productRank.ids,
        rank: productRank.rank,
        groupCode: productRank.groupCode,
      });
    });

    const productRanks = productRankExportModel;

    toast
      .promise(setProductsRank(productRanks), {
        loading: "Setting Rank",
        success: "Rank Set",
        error: (error) => `${error}`,
      })
      .then(() => {
        setSelectedRowKeys([]);
        setProductRankList([]);
        refreshTable();
      });
  };

  const handleIncrementSingleProductRank = (
    productId: number,
    currentRank: number
  ) => {
    const productRankExportModel: IProductRankExportModel[] = [];

    const exportObject = {
      ids: [productId],
      rank: currentRank,
      groupCode: "", // you can set the groupCode to an empty string or whatever value you need
    };

    //  increment the rank
    exportObject.rank++;

    productRankExportModel.push(exportObject);
    toast
      .promise(setProductsRank(productRankExportModel), {
        loading: "Incrementing Rank ",
        success: "Rank Incremented",
        error: (error) => `${error}`,
      })
      .then(() => {
        setSelectedRowKeys([]);
        setProductRankList([]);
        refreshTable();
      });
  };

  const handleDecrementSingleProductRank = (
    value: number,
    currentRank: number
  ) => {
    const productRankExportModel: IProductRankExportModel[] = [];
    const exportObject = {
      ids: [value],
      rank: currentRank,
      groupCode: "", // you can set the groupCode to an empty string or whatever value you need
    };
    //  decrement the rank
    exportObject.rank--;
    productRankExportModel.push(exportObject);

    toast
      .promise(setProductsRank(productRankExportModel), {
        loading: "Decrementing Rank",
        success: "Rank Decremented",
        error: (error) => `${error}`,
      })
      .then(() => {
        setSelectedRowKeys([]);
        setProductRankList([]);
        refreshTable();
      });
  };

  const handleResetSingleProductRank = (productId: number) => {
    const productRankExportModel: IProductRankExportModel[] = [];

    const exportObject = {
      ids: [productId],
      rank: 0,
      groupCode: "", // you can set the groupCode to an empty string or whatever value you need
    };
    productRankExportModel.push(exportObject);

    toast
      .promise(setProductsRank(productRankExportModel), {
        loading: "Resetting Rank",
        success: "Rank Reset",
        error: (error) => `${error}`,
      })
      .then(() => {
        setSelectedRowKeys([]);
        setProductRankList([]);
        refreshTable();
      });
  };

  useEffect(() => {
    const newProductRankList: IProductRankExportModel[] = [];
    productRankList.forEach((productRank) => {
      newProductRankList.push({
        ids: productRank.ids,
        rank: masterRank,
        groupCode: productRank.groupCode,
      });
    });
    setProductRankList(newProductRankList);
  }, [masterRank, masterSkuRankTarget]);

  return (
    <div>
      <List title="Merchandising">
        <Card>
          <Row>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <ProductMerchandisingFilter
                  formProps={searchFormProps}
                  attributeTypeSelectProps={productAttributeOptions}
                  attributeTagSelectProps={productTagOptions}
                />
              </div>
            </Col>
          </Row>
        </Card>

        <Row>
          <Col span={24}>
            <Card>
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto",
                    gridGap: "10px",
                  }}
                >
                  <Input
                    style={{ width: "260px", height: "max-content" }}
                    allowClear
                    placeholder="Sku"
                    disabled={selectedRowKeys && selectedRowKeys.length > 0}
                    onBlur={handleGetSku}
                  />
                  {/* display the product name otherwise show "name not found" */}

                  {productName ? (
                    <div>{productName}</div>
                  ) : (
                    <div>Name not found</div>
                  )}
                </div>

                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "auto auto",
                    gridGap: "10px",
                  }}
                >
                  <Input
                    style={{ width: "260px", marginRight: "10px" }}
                    allowClear
                    placeholder="Set Rank"
                    disabled={selectedRowKeys && selectedRowKeys.length > 0}
                    onChange={(event) =>
                      setMasterRank(Number(event.target.value))
                    }
                  />
                  <Button
                    type="primary"
                    disabled={selectedRowKeys && selectedRowKeys.length > 0}
                    onClick={() => handleExportRank()}
                  >
                    Set Rank
                  </Button>

                  <Input
                    style={{ width: "260px", marginRight: "10px" }}
                    allowClear
                    placeholder="Set Bulk Rank"
                    disabled={selectedRowKeys && selectedRowKeys.length === 0}
                    onChange={(event) =>
                      setMasterRank(Number(event.target.value))
                    }
                  />
                  <Button
                    type="primary"
                    onClick={() => handleExportRank()}
                    disabled={selectedRowKeys && selectedRowKeys.length === 0}
                  >
                    Set Bulk Rank
                  </Button>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Table {...tableProps} rowSelection={rowSelection} rowKey="id">
          <Table.Column<IProductMerchandising>
            dataIndex="masterSku"
            title="Sku"
            sorter
          />
          <Table.Column<IProductMerchandising>
            dataIndex="name"
            title="Name"
            sorter
          />
          <Table.Column<IProductMerchandising>
            sorter
            title="Rank."
            dataIndex="productRank"
          />
          <Table.Column<IProductMerchandising>
            title="Actions"
            render={(_, record) => (
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    handleResetSingleProductRank(record?.id);
                  }}
                >
                  Reset
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    handleIncrementSingleProductRank(
                      record?.id,
                      record?.productRank
                    );
                  }}
                >
                  + 1
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    handleDecrementSingleProductRank(
                      record.id,
                      record.productRank
                    )
                  }
                >
                  - 1
                </Button>
              </Space>
            )}
          />
        </Table>
      </List>
    </div>
  );
};
interface ProductMerchandisingFilterVariables {
  generalSearch: string;
  productAttribute: string;
  productTag: string;
  masterSku: string;
  isNew: boolean;
  rankMin: number;
  rankMax: number;
}

const ProductMerchandisingFilter: React.FC<{
  formProps: FormProps;
  attributeTypeSelectProps: JSX.Element[];
  attributeTagSelectProps;
}> = ({ formProps, attributeTypeSelectProps, attributeTagSelectProps }) => {
  return (
    <Form layout="vertical" {...formProps}>
      <Row>
        <Col span={24}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto auto auto auto",
              gridGap: "10px",
              marginRight: "20px",
            }}
          >
            <Form.Item label="Product Attribute" name="productAttribute">
              <Select
                allowClear
                style={{ width: "200px" }}
                maxTagTextLength={2000}
                onChange={(value) => {
                  //   set the form value to the selected value
                  formProps.form.setFieldsValue({ productAttribute: value });
                }}
              >
                {attributeTypeSelectProps}
              </Select>
            </Form.Item>
            <Form.Item
              label="Tag"
              name="productTag"
              style={{ marginLeft: "20px" }}
            >
              <Select
                allowClear
                style={{ width: "200px" }}
                maxTagTextLength={2000}
                onChange={(value) => {
                  //   set the form value to the selected value
                  formProps.form.setFieldsValue({ productTag: value });
                }}
              >
                {attributeTagSelectProps}
              </Select>
            </Form.Item>

            <Form.Item
              label="Only show new"
              name="isNew"
              valuePropName="checked"
              style={{
                marginLeft: "20px",
                display: "flex",
                width: "200px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Search"
              name="generalSearch"
              style={{ marginLeft: "20px" }}
            >
              <Input
                style={{ width: "260px" }}
                allowClear
                placeholder="General Text Filter..."
                prefix={<Icons.SearchOutlined />}
                // if the user clears the input we want to set the value to undefined
                // so that the filter is removed
                onChange={(e) => {
                  if (!e.target.value) {
                    formProps.form.setFieldsValue({ generalSearch: undefined });
                  }
                }}
              />
            </Form.Item>

            <Form.Item label="Rank Range">
              <Form.Item
                name="rankMin"
                style={{ display: "inline-block", width: "calc(50% - 8px)" }}
              >
                <InputNumber placeholder="Min" min={0} precision={0} />
              </Form.Item>
              <Form.Item
                name="rankMax"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                  marginLeft: "16px",
                }}
              >
                <InputNumber placeholder="Max" min={0} precision={0} />
              </Form.Item>
            </Form.Item>
          </div>
          {/* align this button to the right */}
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "20px",
        }}
      >
        <div>
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              onClick={() =>
                console.log("filterSubmit", formProps.form.getFieldsValue())
              }
            >
              Filter
            </Button>
          </Form.Item>
        </div>
      </Row>
    </Form>
  );
};

//  the filter
