import { Badge } from "@pankod/refine-antd";
import { IMissingProductImageTable } from "./MissingProductImagesPanel";

export const MissingImageProductStatus = ({
  productProp,
}: {
  productProp: IMissingProductImageTable;
}) => {
  let hasPublishedDate = Boolean(productProp.published_at);

  return hasPublishedDate ? (
    <Badge color="green" title="published" />
  ) : (
    <Badge color="blue" title="draft" />
  );
};
