import { Product } from "Interfaces";
import { Fragment } from "react";
import isAfter from "date-fns/isAfter";
import {
  CheckCircleTwoTone,
  StarTwoTone,
  EyeTwoTone,
  QuestionCircleTwoTone,
} from "@ant-design/icons";
import { lightFormat, parseISO } from "date-fns";
import { Space } from "@pankod/refine-antd";

export const ProductStateIconList = ({
  productProp,
}: {
  productProp: Product;
}) => {
  // handle the  dates comparison using date-fns
  var formatToIso = parseISO(productProp?.isNewProductUntil);
  var currentDate = lightFormat(Date.now(), "yyyy-MM-dd");
  var isNewProductCheck = isAfter(formatToIso, parseISO(currentDate));

  return (
    <Fragment>
      <Space direction="horizontal">
        {productProp.isFeaturedProduct && (
          <CheckCircleTwoTone twoToneColor="#ff00bb" title="featured" />
        )}

        {!productProp?.isSearchableProduct && (
          <QuestionCircleTwoTone
            twoToneColor="#ff0000"
            title="not searchable"
          />
        )}

        {!productProp.isPublicProduct && (
          <EyeTwoTone twoToneColor="#0407bf" title="not public" />
        )}

        {isNewProductCheck && (
          <StarTwoTone twoToneColor="#52c41a" title="new product" />
        )}
      </Space>
    </Fragment>
  );
};
