import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export const melbourneTimeNowHelper = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const today = dayjs();

  // get the time now in melbourne
  const todayZonedtoMelbourne = dayjs.tz(today, "Australia/Melbourne");

  return todayZonedtoMelbourne;
};
