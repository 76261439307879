import { Tag } from "@pankod/refine-antd";

export const GiftCardStatusTag = ({ status }: { status: string }) => {
  switch (status) {
    case "ok":
      return <Tag color="green">{status}</Tag>;

    case "queued":
      return <Tag color="geekblue">{status}</Tag>;

    case "missing-number":
      return <Tag color="volcano">{status}</Tag>;

    // case "failed-to-send-email":
    //   return <Tag color="orange">{status}</Tag>;

    // case "shipped":
    //   return <Tag color="gold">{status}</Tag>;

    // case "delivered":
    //   return <Tag color="purple">{status}</Tag>;

    // case "cancelled":
    //   return <Tag color="#eb6200">{status}</Tag>;

    // case "failed":
    //   return <Tag color="#eb0000">{status}</Tag>;

    case undefined:
      return <Tag color="purple">pending</Tag>;

    default:
      console.warn(`Gift Card Status - Unknown status value selected '${status}' `);
      return <Tag color="#7a7d7b">Unknown</Tag>;
  }
};
