import { Space, Tag, Typography } from "@pankod/refine-antd";
const { Text, Link } = Typography;

export const NewCustomerTagRender = (isNewCustomer) => {
  if (isNewCustomer) {
    return (
      <Space>
        <Text strong>New Customer</Text>
        <Tag color="green">Yes</Tag>
      </Space>
    );
  } else {
    return (
      <Space>
        <Typography>New Customer</Typography>
        <Tag color="red">No</Tag>
      </Space>
    );
  }
};
