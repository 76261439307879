import { ColumnsType } from "antd/lib/table";
import { IExpectedQty, IPackProjection, ISubscriptionSku } from "Interfaces";
import React, { useEffect, useMemo, useState } from "react";
import { useApiUrl, useCustom, useList } from "@pankod/refine-core";
import { Button, Card, Divider, Select } from "antd";
import { Table } from "antd";
import moment from "moment";
import { formatCurrency } from "Pages/Dashboard/FormatCurrency";
import { List, TextField } from "@pankod/refine-antd";
import { exportPackProjectionDataCSV } from "Services/productServices";
import { exportToCSV } from "Utilities/exportToCsv";
const { Option } = Select;

interface IProjectionCSVRow {
  Pack: string;
  Month: string;
  Qty: number;
}

export const ForwardPackProjectionTable: React.FC<{}> = () => {
  // useCustomQuery hook
  const apiUrl = useApiUrl();

  //consider using the refine query string to the get the unpublished count
  // also count just returns  anumber so no interface needed.
  const { data } = useCustom<IPackProjection[]>({
    url: `${apiUrl}/subscriptions/project`,
    method: "get",
  });

  const { data: subscriptionSkuDataResult } = useList<ISubscriptionSku>({
    resource: "subscription-skus",
    config: {
      pagination: { current: 1, pageSize: 500 },
    },
  });

  const packData = data?.data;

  const [masterSkuFilter, setMasterSkuFilter] = useState("");
  const [monthFilter, setMonthFilter] = useState("");
  const [monthColumns, setMonthColumns] = useState([]);
  const csvRows: IProjectionCSVRow[] = [];

  function handleMasterSkuFilterChange(value) {
    setMasterSkuFilter(value);
  }

  function handleDateFilterChange(value) {
    setMonthFilter(value);
  }

  // Get the list of dates available in the expectedQty array but remove any duplicates
  const expectedQtyFilterOptions = packData
    ? packData
        .map((item) => item.expectedQty.map((eq) => eq.date))
        .flat()
        .filter((value, index, self) => self.indexOf(value) === index)
    : [];

  // Filter the data by masterSku if a filter is selected
  let filteredData = masterSkuFilter
    ? packData.filter((item) => item.masterSku === masterSkuFilter)
    : packData;

  // Filter the data by date if a date filter is selected
  if (monthFilter) {
    filteredData = filteredData.filter((item) =>
      item.expectedQty.some((eq) => eq.date === monthFilter)
    );
  }

  const alignCenter = "center";

  useEffect(() => {
    if (!packData) {
      return;
    }

    const newMonthColumns = packData.reduce((acc, cur) => {
      cur.expectedQty.forEach((eq) => {
        const date = moment(eq.date).format("MMM YYYY");
        if (!acc.find((col) => col.title === date)) {
          acc.push({
            title: date,
            align: "center",
            dataIndex: date,
            key: date,
            render: (text, record) => {
              const expectedQty = record.expectedQty.find(
                (e) => moment(e.date).format("MMM YYYY") === date
              );
              return expectedQty ? expectedQty.qty : "";
            },
          });
        }
      });
      return acc;
    }, []);

    setMonthColumns(newMonthColumns);
  }, [packData]);

  //  without the column typing you cant access the align property
  // https://github.com/ant-design/ant-design/issues/22535
  const columns: ColumnsType<IPackProjection> = [
    {
      title: "Pack Name",
      dataIndex: "packName",
      key: "packName",
    },
    {
      title: "Price Point",
      dataIndex: "pricePoint",
      align: alignCenter,
      key: "pricePoint",
      render: (text, record) => {
        return formatCurrency(record.pricePoint);
      },
    },
  ];

  columns.push(...monthColumns);

  //   async handle export to csv
  const handleCSVExport = async () => {
    filteredData.forEach((packProjection) => {
      // Iterate over each expected quantity for the pack
      packProjection.expectedQty.forEach((expectedQty) => {
        // Create a new row object for the pack/month/qty combination
        const row: IProjectionCSVRow = {
          Pack: packProjection.packName,
          //   parse the date to month and year
          Month: moment(expectedQty.date).format("MMM YYYY"),
          Qty: expectedQty.qty,
        };

        //  if the the row is not already in the csvRows array then add it
        if (
          !csvRows.find((r) => r.Pack === row.Pack && r.Month === row.Month)
        ) {
          csvRows.push(row);
        }
      });
    });

    const response = exportPackProjectionDataCSV({ data: csvRows });
    response.then((res) => {
      exportToCSV(res.data, "packProjection");
    });
  };

  return (
    <div>
      <List title="Forward Pack Projection Table">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <TextField
            style={{ paddingRight: 10, fontWeight: "bold" }}
            value="
            Filter by Master Pack Name
          "
          />
          <Select
            style={{ width: 200 }}
            value={masterSkuFilter}
            onChange={handleMasterSkuFilterChange}
          >
            <Option value="">All packs</Option>
            {subscriptionSkuDataResult?.data?.map((item) => (
              <Option key={item.sku} value={item.sku}>
                {item.packName}
              </Option>
            ))}
          </Select>
        </div>

        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {/* export to csv button */}
          <Button
            type="primary"
            style={{ marginBottom: 10 }}
            onClick={handleCSVExport}
          >
            Export to CSV
          </Button>
        </div>
        <Table dataSource={filteredData} columns={columns} />
      </List>
    </div>
  );
};

export default ForwardPackProjectionTable;
