import { Button, List, Table, TableProps, useTable } from "@pankod/refine-antd";
import { BaseKey } from "@pankod/refine-core";
import { ISubscriptionRunOrderData } from "Interfaces";
import React, { Fragment } from "react";

export const TotalSubscriptionTable: React.FC<{
  id: BaseKey;
}> = ({ id }) => {
  const { tableProps: runOrderDataProps, tableQueryResult } =
    useTable<ISubscriptionRunOrderData>({
      resource: `subscription-orders/${id}/currentSubscriptionData`,
      initialPageSize: 50,
    });

  const refreshTable = async () => {
    tableQueryResult.refetch();
  };

  return (
    <List
      title="Total Subscriptions"
      createButtonProps={{ hidden: true }}
      pageHeaderProps={{
        extra: (
          <Fragment>
            <Button onClick={refreshTable}>Manual Update</Button>
          </Fragment>
        ),
      }}
    >
      <Table
        {...runOrderDataProps}
        style={{ width: "100%" }}
        rowKey="subscriptionName"
      >
        <Table.Column<ISubscriptionRunOrderData>
          align="center"
          title="Subscription Name"
          dataIndex="subscriptionName"
        />
        <Table.Column<ISubscriptionRunOrderData>
          align="center"
          title="Sku"
          dataIndex="sku"
        />
        <Table.Column<ISubscriptionRunOrderData>
          title="Packs This Month"
          dataIndex="packsThisMonth"
          align="center"
        />
        <Table.Column<ISubscriptionRunOrderData>
          title="Monthly Count"
          dataIndex="monthlyCount"
          align="center"
        />
        <Table.Column<ISubscriptionRunOrderData>
          title="Bi-Monthly Count"
          dataIndex="biMonthlyCount"
          align="center"
        />
        <Table.Column<ISubscriptionRunOrderData>
          title="Quarterly Count"
          dataIndex="quarterlyCount"
          align="center"
        />
        <Table.Column<ISubscriptionRunOrderData>
          title="New Subs Count"
          dataIndex="newSubscriptionCount"
          align="center"
        />
      </Table>
    </List>
  );
};
