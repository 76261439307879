import {
  List,
  TextField,
  Table,
  useTable,
  Space,
  EditButton,
  Form,
  Input,
  Icons,
  Button,
  FormProps,
  Row,
  Col,
  Card,
  Select,
  useSelect,
  SelectProps,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from "@pankod/refine-core";
import { PublishedStatus } from "Pages/Products/Edit/PublishedStatus";
import { Fragment } from "react";

import { IProductTagModel, ITag, ITagType } from "../../Interfaces";

export const TagList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, tableQueryResult, searchFormProps } = useTable<
    ITag,
    HttpError,
    TagFilterVariables
  >({
    initialPageSize: 50,
    initialSorter: [
      {
        field: "id",
        order: "asc",
      },
    ],
    initialFilter: [
      {
        field: "_publicationState",
        operator: "eq",
        value: "preview",
      },
    ],

    onSearch: (params) => {
      const filters: CrudFilters = [];
      const { name, tagType } = params;

      filters.push(
        {
          field: "name",
          operator: "contains",
          value: name || undefined,
        },
        {
          field: "tagType",
          operator: "contains",
          value: tagType || undefined,
        },
        {
          field: "_publicationState",
          operator: "eq",
          value: "preview",
        }
      );
      return filters;
    },
  });

  const { selectProps } = useSelect<ITagType>({
    resource: "tag-types",
    optionLabel: "name",
    optionValue: "id",
    fetchSize: 50,
    sort: [
      {
        field: "name",
        order: "asc",
      },
    ],
  });

  return (
    <Fragment>
      <Card title="Filters">
        <TagFilter
          formProps={searchFormProps}
          tagTypeSelectProps={selectProps}
        />
      </Card>

      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="id"
            title="id"
            render={(value) => <TextField value={value} />}
            sorter
          />
          <Table.Column
            dataIndex="code"
            title="Code"
            render={(value) => <TextField value={value} />}
            sorter
          />

          <Table.Column
            dataIndex="name"
            title="Name"
            render={(value) => <TextField value={value} />}
            sorter
          />

          <Table.Column<ITag>
            dataIndex="tagType"
            title="Tag Type"
            render={(_, record) => {
              return <Fragment>{record.tagType.name}</Fragment>;
              // <TextField value={record.tagType.name} />}
            }}
            sorter
          />
          <Table.Column
            dataIndex="published_at"
            title="Status"
            render={(value) => <PublishedStatus publishedDate={value} />}
            sorter
          />

          <Table.Column<IProductTagModel>
            title="Edit"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <EditButton size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </List>
    </Fragment>
  );
};

interface TagFilterVariables {
  name: string;
  tagType: string;
  // preview: string;
}

const TagFilter: React.FC<{
  formProps: FormProps;
  tagTypeSelectProps: SelectProps;
}> = ({ formProps, tagTypeSelectProps }) => {
  return (
    <Form layout="vertical" {...formProps}>
      <Row>
        <Col span={10}>
          <Space align="end" size={"large"}>
            <Form.Item label="Search Tag Name" name="name">
              <Input
                allowClear
                placeholder="Tag name"
                prefix={<Icons.SearchOutlined />}
              />
            </Form.Item>

            <Form.Item label="Tag Type" name="tagType">
              <Select
                allowClear
                style={{ width: "200px" }}
                {...tagTypeSelectProps}
                maxTagTextLength={100}
              />
            </Form.Item>
            <Form.Item>
              <Button htmlType="submit" type="primary">
                Filter
              </Button>
            </Form.Item>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};
