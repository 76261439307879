import {
    Button,
    Col,
    Create,
    DatePicker,
    Form,
    Input,
    Row,
    Select,
    useForm,
} from "@pankod/refine-antd";
import React, {useEffect, useState} from "react";
import {
    IResourceComponentsProps,
    useList,
    useNavigation,
} from "@pankod/refine-core";
import {IOrder, ISubscriptionRun} from "Interfaces";
import dayjs from "dayjs";
import {Dayjs} from "dayjs";
import {DEFAULT_OPEN_DATE, SUBSCRIPTION_RUN_STATUSES} from "../../constants";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";

export const CreateOrder: React.FC<IResourceComponentsProps> = () => {

    const {formProps, saveButtonProps, form} = useForm<IOrder>({
        action: "create",
        resource: "checkoutsmanualcreate",
        // redirect: "list",
        redirect: false
    });


    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical"
                  initialValues={{status: "ordered", orderDate: new Date()}}
            >
                <Form.Item name="status" hidden>
                    <Input/>
                </Form.Item>

                <Form.Item name="orderDate" hidden>
                    <Input/>

                </Form.Item>
                {/*<Row>*/}
                {/*    <Col span={12}>*/}
                {/*        <Form.Item*/}
                {/*            label="Name"*/}
                {/*            name="name"*/}
                {/*            rules={[*/}
                {/*                {*/}
                {/*                    required: true,*/}
                {/*                },*/}
                {/*            ]}*/}
                {/*            style={{ width: "350px" }}*/}
                {/*        >*/}
                {/*            <Input />*/}
                {/*        </Form.Item>*/}
                {/*        <Form.Item label="Status" name="status" style={{ width: "350px" }}>*/}
                {/*            <Select*/}
                {/*                placeholder="Select a status"*/}
                {/*                allowClear*/}
                {/*                style={{ width: "350px" }}*/}
                {/*                options={SUBSCRIPTION_RUN_STATUSES}*/}
                {/*            ></Select>*/}
                {/*        </Form.Item>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                <Row>
                    <Col span={12}>
                        {/*<div style={{display: "flex", justifyContent: "flex-start"}}>*/}

                        <Form.Item
                            label="Client Name"
                            name={["structuredData", "customerName"]}
                            style={{marginLeft: "20px"}}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="Pronto Order ID"
                            name="prontoOrderId"
                            style={{marginLeft: "20px"}}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="Order Value"
                            name="orderValue"
                            style={{marginLeft: "20px"}}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.List name={["structuredData", "lineItems"]}>
                            {(fields, {add, remove}) => (
                                <>
                                    {fields.map((field) => (
                                        <Row key={field.key}>
                                            <Col span={12}>
                                                <Form.Item
                                                    {...field}
                                                    label="Master Sku"
                                                    name={[field.name, 'masterSku']}
                                                    fieldKey={[field.fieldKey, 'masterSku']}
                                                    rules={[{required: true, message: 'Missing SKU'}]}
                                                >
                                                    <Input placeholder="SKU"/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    {...field}
                                                    label="Quantity"
                                                    name={[field.name, 'quantity']}
                                                    fieldKey={[field.fieldKey, 'quantity']}
                                                    rules={[{required: true, message: 'Missing quantity'}]}
                                                >
                                                    <Input placeholder="Quantity"/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Button
                                                    type="primary"
                                                    onClick={() => remove(field.name)}
                                                    icon={<MinusCircleOutlined/>}
                                                >
                                                    Remove
                                                </Button>
                                            </Col>
                                        </Row>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            style={{

                                                marginTop: '4px'
                                            }}
                                            type="dashed"
                                            onClick={() => add()}
                                            icon={<PlusOutlined/>}
                                        >
                                            Add field
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Col>
                    <Col>


                        <Form.Item
                            label="Address"
                            name={["meta", "overridden_shipping_address"]}
                            style={{marginLeft: "20px"}}
                        >
                            <Input.TextArea/>
                        </Form.Item>

                        <Form.Item
                            label="User Phone No"
                            name="userPhoneNumber"
                            style={{marginLeft: "20px"}}
                        >
                            <Input/>
                        </Form.Item>


                    </Col>
                </Row>
            </Form>
        </Create>
    );
};
