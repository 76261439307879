import { Table } from "@pankod/refine-antd";
import { IExecutionHistory } from "Interfaces";

import { formatCurrency } from "../Dashboard/FormatCurrency";
import dayjs from "dayjs";
export const ExecutionHistoryTable = ({
  data,
}: {
  data: IExecutionHistory[] | undefined;
}) => {
  //sort the data by date
  if (data) {
    data.sort((a, b) => {
      return dayjs(a.subscriptionRunDate).isBefore(dayjs(b.subscriptionRunDate))
        ? 1
        : -1;
    });
  }

  //set the id on each record of data to a random number, becuase the id is not returned from the API -- see below
  if (data) {
    data.map((record) => {
      return (record.id = Math.floor(Math.random() * 1000000));
    });
  }

  return (
    // NOTE the rowkey being prontoOrder id makes logical sense howver some of the data is missing the id
    // which will cause errors re duplcate keys i.e null values so im doing this to avoid errors until that part is working 100%
    <Table dataSource={data} rowKey="id">
      <Table.Column<IExecutionHistory>
        dataIndex="run"
        title="Run"
        render={(_, record) => {
          return record.subscriptionRunName;
        }}
      />
      <Table.Column<IExecutionHistory>
        title="Sku"
        render={(_, record) => {
          return record.title;
        }}
      />
      <Table.Column<IExecutionHistory>
        dataIndex="value"
        title="Value"
        render={(_, record) => {
          return formatCurrency(record.subscriptionOrderPrice);
        }}
      />
      <Table.Column<IExecutionHistory>
        dataIndex="orderNumber"
        title="Order #"
        render={(_, record) => {
          return record.prontoOrderId;
        }}
      />
      <Table.Column<IExecutionHistory>
        dataIndex="status"
        title="Status"
        render={(_, record) => {
          return record.subscriptionOrderStatus;
        }}
      />
    </Table>
  );
};
