import dayjs from "dayjs";
import { IExecutionHistory } from "Interfaces";
import { Fragment } from "react";
export const SubscriptionStartDateDisplay = ({
  data,
}: {
  data: IExecutionHistory[] | undefined;
}) => {
  let firstOrder: IExecutionHistory | undefined;

  const shippedOrders = data?.filter(
    (order) => order.subscriptionOrderStatus === "ordered"
  );

  if (shippedOrders && shippedOrders.length > 0) {
    firstOrder = shippedOrders?.reduce((a, b) => {
      return dayjs(a.subscriptionRunDate).isBefore(dayjs(b.subscriptionRunDate))
        ? a
        : b;
    });
  }

  // console.log("firstOrderData", data);
  // console.log("shippedOrders", shippedOrders);

  return (
    <Fragment>
      {shippedOrders?.length > 0 ? (
        <b>
          {firstOrder?.subscriptionRunDate &&
            dayjs(firstOrder.subscriptionRunDate).format("DD/MM/YYYY")}
        </b>
      ) : (
        <b>Pending </b>
      )}
    </Fragment>
  );
};
