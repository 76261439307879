import { useApiUrl, useCustom } from "@pankod/refine-core";
import { IOrderStatistics } from "Interfaces";
import { Fragment } from "react";
import { DashboardPanelItem } from "./DashboardPanelItem";
import { formatCurrency } from "./FormatCurrency";

export const OrdersDashPanel = () => {
  const apiUrl = useApiUrl();

  //consider using the refine query string to the get the unpublished count
  // also count just returns  anumber so no interface needed.
  const { data } = useCustom<IOrderStatistics>({
    url: `${apiUrl}/dashboard/orderstatistics`,
    method: "get",
  });

  const flexColumn: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
  };

  return (
    <Fragment>
      <div style={{ display: "flex" }}>
        <div style={flexColumn}>
          <div>
            <DashboardPanelItem
              heading="Count completed today"
              data={data?.data?.orderTodayCount}
            />
          </div>
          <div>
            <DashboardPanelItem
              heading="Order Count last 7 days"
              data={data?.data?.ordersWithinSevenDaysCount}
            />
          </div>
        </div>
        <div style={flexColumn}>
          <div>
            <DashboardPanelItem
              heading="Daily order value"
              data={formatCurrency(data?.data?.orderSumTotalToday)}
            />
          </div>
          <div>
            <DashboardPanelItem
              heading="Order value last 7 days"
              data={formatCurrency(data?.data?.orderSumTotalLastSevenDays)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
