import { Tag } from "@pankod/refine-antd";

export const WorkflowStatusTag = ({ status }: { status: number }) => {
  let statusText = "";
  if (status === 1) {
    statusText = "disabled";
  } else if (status === 0) {
    statusText = "enabled";
  }

  if (statusText === "enabled") {
    return <Tag color="green">{statusText}</Tag>;
  } else if (statusText === "disabled") {
    return <Tag color="orange">{statusText}</Tag>;
  } else {
    return null;
  }
};
