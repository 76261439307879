import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export const utcToMelbourneTimeHelper = (timeToConvert?: string) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const output = timeToConvert
    ? dayjs(timeToConvert).tz("Australia/Melbourne")
    : null;

  return output;
};
