import React from 'react';
import {Document, Page, Text, View, StyleSheet, Image} from '@react-pdf/renderer';
import {IOrder} from "../../Interfaces";

import {Font} from '@react-pdf/renderer';
import {Divider} from "@pankod/refine-antd";

// Register the font
Font.register({
    family: 'BHS', // The name you'll refer to the font by
    src: '/fonts/TWKLausanne-550.otf', // The path to your font file in the public directory
});

// Define your styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFF',
        padding: 10,
        fontSize: 12,
        lineHeight: 1.6,
    },
    a7Container: {
        width: '50%', // A7 is half the width of A4 in portrait
        height: '25%', // A7 is a quarter of the height of A4 in portrait
        padding: 10,
        borderWidth: 1,
        borderColor: '#000',
        borderStyle: 'solid',
    },
    row: {
        flexDirection: 'row',
        marginBottom: 10,
    },
    col: {
        flex: 1,
        flexGrow: 1
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
    },
    labelTitle: {
        fontSize: 16,
        marginBottom: 4,
        textAlign: 'right',
        fontWeight: 'bold',
        fontFamily: 'BHS'
    },
    labelInfo: {
        fontSize: 14,
        marginBottom: '2px',
        textAlign: 'right',
        fontFamily: 'BHS',
        marginTop: '2px',
    },
    imageContainer: {
        marginBottom: 8,
        overflowY: 'hidden',
        height: '100%'
        // overflow: "hidden"
    },
    image: {
        width: '120px', // Adjust as needed
        height: 'auto',
    },
    footer: {
        fontSize: 18,
        textAlign: 'center',
        fontWeight: 'bold',
        marginTop: 8,
    },
    divider: {
        marginVertical: 10,
        borderBottomWidth: 1,
        borderBottomColor: 'rgb(161,161,161)',
    },
    border:{
        borderWidth:'2px',
        borderRadius:'8px'
    }
});

// Create your react-pdf component
const LabelPdfRenderer = ({record}: { record: IOrder }) => (
    <>
        <View style={{
            padding: '32px',
            borderWidth:'2px',
            borderRadius:'8px'
        }}>
            <View style={{
                ...styles.row,
                // height: '100%',
                display: 'flex',

            }}>
                <View style={styles.col}>
                    <Text style={styles.labelTitle}>#{record?.prontoOrderId}</Text>
                    <Text style={styles.labelInfo}>{record?.clientName}</Text>
                    <View style={styles.divider}/>
                    {/*<Text style={styles.labelInfo}>Address:</Text>*/}
                    <Text style={styles.labelInfo}>{record.structuredData?.shippingAddress?.addressLine1}</Text>
                    <Text style={styles.labelInfo}>{record.structuredData?.shippingAddress?.addressName}</Text>
                    <Text style={styles.labelInfo}>{record.structuredData?.shippingAddress?.locality}</Text>
                    <Text style={styles.labelInfo}>{record.structuredData?.shippingAddress?.postcode}</Text>
                    <Text style={styles.labelInfo}>{record.structuredData?.phoneNumber}</Text>
                </View>
            </View>
            <View style={{
                ...styles.row,
                height: '100%',
                display: 'flex',

            }}>
                <View style={
                    {
                        ...styles.col,
                        flexDirection: 'column-reverse'
                    }
                }>

                    {/* Assuming 'imageUrl' is the path to the image of the character */}
                    <Image src={'bhs.png'} style={styles.image}/>
                    <Image src={'blackhearts.png'} style={{
                        ...styles.image,
                    }}/>

                    {/*<Text style={styles.footer}>BLACKHEARTS & SPARROWS</Text>*/}
                </View>
            </View>
        </View>
        {/*<Text style={styles.labelTitle}>In-House Label Mockup (4 x 6)</Text>*/}

    </>
);

export default LabelPdfRenderer;
