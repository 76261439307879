import { Select, Tag } from "antd";
import {
  IProductAttribute,
  IProductAttributeModel,
  IProductAttributeType,
} from "Interfaces";
import { Fragment } from "react";

export const ProductAttributesTagRender = ({
  productAttrs,
}: {
  productAttrs: IProductAttributeModel[];
}) => {
  return (
    <>
      {productAttrs.map((a) => (
        <Tag key={a.id}>
          {a.productAttributeTypeObject?.name} : {a.name}{" "}
        </Tag>
      ))}
    </>
  );
  // }
};
