import React, {Fragment, useState} from 'react';
import {Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';
import {formatCurrency} from "../Dashboard/FormatCurrency";
import {GiftCardStatusTag} from "./GiftCardStatusTag";
import {CheckmarkIcon} from "react-hot-toast";
import {Divider, Typography} from "@pankod/refine-antd";
import {OrderStatusTagRender} from "./OrderStatusTagRender";


import * as _ from 'underscore'


// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1
    },
    table: {
        display: 'flex',
        width: "auto",
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        flexDirection: 'row'
    },
    tableCol: {
        width: '50%',
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        borderBottomWidth: 1,
        borderRightWidth: 1,
        padding: 5,
    },
    tableCol33: {
        width: '16.66%',
        borderStyle: 'solid',
        borderColor: '#bfbfbf',
        borderBottomWidth: 1,
        borderRightWidth: 1,
        padding: 5,
    },
    tableCell: {
        fontSize: 10
    },
    bold: {
        fontWeight: 'bold'
    },
    divider: {
        marginVertical: 20,
        borderBottomWidth: 2,
        borderBottomColor: '#000000',
    }
});

const {Title} = Typography;

// Create Document Component
export const OrderPdfRender = ({record}) => {

    if (!record) {
        return <div>Loading...</div>; // Or any placeholder you prefer
    }

    console.log('record', record)
    const hasGiftCards = record?.structuredData.giftCards?.length > 0;
    const hasLineItems = record?.structuredData.lineItems?.length > 0;
    const hasGiftWrappingLineItem = record?.structuredData.gift?.product;
    const hasSubscriptionLineItems = record?.structuredData.subscriptions?.length > 0;
    console.log('hasLineItems', hasLineItems)
    const getPaymentCardInfo = (payment) => {
        if (payment.type === "GiftVoucher") {
            return payment.giftCardId;
        } else {
            return `${payment.scheme}: ending ...${payment?.last4}`;
        }
    }

    const getPaymentExpiry = (payment) => {
        if (payment.type === "GiftVoucher") {
            return "";
        } else {
            return `${payment.expiryMonth}/${payment?.expiryYear}`;
        }
    }

    const lineItemsArray = record?.structuredData.lineItems ?? []

    const chunkedLineItems = _.chunk(lineItemsArray, 3)

    const lineItems = _.map(chunkedLineItems, one => {
        return (
            <View wrap={false}>
                {one.map((lineItem) => {
                    return (
                        <>
                            {/*<View style={styles.table}>*/}
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol33}>
                                    <Text style={styles.tableCell}>{lineItem.title} ({lineItem.packageName})</Text>
                                </View>
                                <View style={styles.tableCol33}>
                                    <Text style={styles.tableCell}>{lineItem.sku}</Text>
                                </View>
                                <View style={styles.tableCol33}>
                                    <Text style={[styles.tableCell, styles.bold]}>{lineItem.quantity}</Text>
                                </View>
                                <View style={styles.tableCol33}>
                                    <Text
                                        style={[styles.tableCell, styles.bold]}>{formatCurrency(lineItem.singlePrice, true)}</Text>
                                </View>
                                <View style={styles.tableCol33}>
                                    <Text
                                        style={[styles.tableCell, styles.bold]}>{formatCurrency(lineItem.discountedPrice, true)}</Text>
                                </View>
                                <View style={styles.tableCol33}>
                                    <Text
                                        style={[styles.tableCell, styles.bold]}>{formatCurrency(lineItem.discountedPrice * lineItem.quantity, true)}</Text>
                                </View>
                            </View>
                            {/*</View>*/}

                        </>
                    )
                })}
                {/*<View style={styles.divider}/>*/}
            </View>

        )

    })


    const getGiftWrappingLineItem = (record) => {
        if (record?.structuredData.gift?.product) {
            const lineItem = record?.structuredData.gift?.product;
            return (
                <>
                    <View style={styles.table} wrap={false}>

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol33}>
                                <Text style={styles.tableCell}>{lineItem.title}</Text>
                            </View>
                            <View style={styles.tableCol33}>
                                <Text style={styles.tableCell}>{lineItem.sku}</Text>
                            </View>
                            <View style={styles.tableCol33}>
                                <Text style={[styles.tableCell, styles.bold]}>{lineItem.quantity}</Text>
                            </View>
                            <View style={styles.tableCol33}>
                                <Text
                                    style={[styles.tableCell, styles.bold]}>{formatCurrency(lineItem.price, true)}</Text>
                            </View>
                            <View style={styles.tableCol33}>
                                <Text
                                    style={[styles.tableCell, styles.bold]}>{formatCurrency(lineItem.price, true)}</Text>
                            </View>
                            <View style={styles.tableCol33}>
                                <Text
                                    style={[styles.tableCell, styles.bold]}>{formatCurrency(lineItem.price, true)}</Text>
                            </View>
                        </View>
                    </View>

                </>
            )
        }
        return undefined;
    }

    const giftWrappingLineItem = getGiftWrappingLineItem(record);

    const giftCards = record?.structuredData.giftCards?.map((giftCard) => {
        return (
            <tr>
                <td style={{textAlign: "right"}}>{formatCurrency(giftCard?.cardValue, true)}</td>
                <td></td>
                <td style={{textAlign: "left"}}>{giftCard?.recipientName}</td>
                <td style={{textAlign: "left"}}>{giftCard?.recipientEmail}</td>
                <td style={{textAlign: "left"}}>{giftCard?.senderName}</td>
                <td style={{textAlign: "left"}}>{giftCard?.message}</td>
                <td style={{textAlign: "right"}}><GiftCardStatusTag status={giftCard?.status}/></td>
            </tr>
        )
    })

    const subscriptionLineItems = record?.structuredData.subscriptions?.map((lineItem) => {
        return (
            <tr>
                <td>{lineItem.masterSku}</td>
                <td>{lineItem.title}</td>
                <td style={{textAlign: "right"}}>{lineItem.quantity}</td>
                <td style={{textAlign: "right"}}>{formatCurrency(lineItem.singlePrice, true)}</td>
                <td style={{textAlign: "right"}}>{lineItem.frequency}</td>
                <td align="right">{lineItem.asap ? <CheckmarkIcon/> : <></>}</td>
            </tr>
        )
    })


    return (
        <>
            <Page size="A4" style={styles.page} wrap={true}>
                <View style={styles.section}>

                    <Text>BHS Pickslip #{record?.prontoOrderId}</Text>
                    <View style={styles.divider}/>

                    {/* Table 1 */}
                    <View style={styles.table}>

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Order ID</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={[styles.tableCell, styles.bold]}>{record?.id}</Text>
                            </View>
                        </View>

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Pronto Order #</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={[styles.tableCell, styles.bold]}>{record?.prontoOrderId}</Text>
                            </View>
                        </View>

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Order Date</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text
                                    style={[styles.tableCell, styles.bold]}>{(new Date(record?.orderDate)).toLocaleString()}</Text>
                            </View>
                        </View>

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Order Status</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={[styles.tableCell, styles.bold]}>{record?.status}</Text>
                            </View>
                        </View>


                        {/* More rows can be added similarly */}
                    </View>

                    <View style={styles.divider}/>

                    <View style={styles.table}>

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Shipping</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text
                                    style={[styles.tableCell, styles.bold]}>{record?.structuredData.shipping?.description}</Text>
                            </View>
                        </View>


                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Shipping Name</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text
                                    style={[styles.tableCell, styles.bold]}>{record?.structuredData.shippingAddress?.addressName}</Text>
                            </View>
                        </View>

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Shipping Address</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text
                                    style={[styles.tableCell, styles.bold]}>{record?.structuredData.shippingAddress?.addressLine1}</Text>
                                <Text style={[styles.tableCell, styles.bold]}>
                                    {record?.structuredData.shippingAddress?.locality} {record?.structuredData.shippingAddress?.region} {record?.structuredData.shippingAddress?.postcode}
                                </Text>
                            </View>
                        </View>

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Delivery Instructions</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text
                                    style={[styles.tableCell, styles.bold]}>{record?.structuredData.deliveryInstructions}</Text>
                            </View>
                        </View>
                    </View>

                    <View style={styles.divider}/>

                    <View style={styles.table}>

                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Gift Message</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text
                                    style={[styles.tableCell, styles.bold]}>{record?.structuredData.gift?.message}</Text>
                            </View>
                        </View>
                    </View>

                    <View style={styles.divider}/>

                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol33}>
                                <Text style={styles.tableCell}>Title</Text>
                            </View>
                            <View style={styles.tableCol33}>
                                <Text style={styles.tableCell}>Sku</Text>
                            </View>
                            <View style={styles.tableCol33}>
                                <Text style={[styles.tableCell, styles.bold]}>Quantity</Text>
                            </View>
                            <View style={styles.tableCol33}>
                                <Text style={[styles.tableCell, styles.bold]}>Single Price</Text>
                            </View>
                            <View style={styles.tableCol33}>
                                <Text style={[styles.tableCell, styles.bold]}>Discounted Price</Text>
                            </View>
                            <View style={styles.tableCol33}>
                                <Text style={[styles.tableCell, styles.bold]}>Total Price</Text>
                            </View>
                        </View>

                        {lineItems}
                        {hasGiftWrappingLineItem && giftWrappingLineItem}

                    </View>

                    <View style={styles.divider}/>
                    <Image src="bhs.png" style={{width: 100}}/>
                    <View style={styles.divider}/>

                </View>
            </Page>
        </>
    )
}