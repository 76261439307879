import { CSVExportTypes } from "Interfaces";
import dayjs from "dayjs";

export function exportToCSV(data: any, exportType: CSVExportTypes, exportTypeRaw="") {
  const FileType = "csv";
  const fileExtension = ".csv";
  let blob = new Blob([data], {
    type: "text/" + FileType + ";charset=utf8;",
  });

  let link = document.createElement("a");
  link.href = URL.createObjectURL(blob);

  link.setAttribute("visibility", "hidden");
  const now = dayjs(new Date()).format("DD/MM/YYYY");

  //replace the / in the date with a - to avoid errors
  const date = now.replace(/\//g, "-");

  let filenamePrefix = exportTypeRaw;
  if (exportType === "subscriptionData") {
    filenamePrefix = "subscription_data_";
  } else if (exportType === "packProjection") {
    filenamePrefix = "pack_projection_data_";
  } else if (exportType === "finance") {
    filenamePrefix = "order_shipping_finance_data_";
  } else if (exportType === "optimo") {
    filenamePrefix = "order_shipping_optimo_";
  } else if (exportType === "shippit") {
    filenamePrefix = "order_shipping_shippit_";
  }

  const fileName = filenamePrefix + date + fileExtension;

  link.download = fileName + fileExtension;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
