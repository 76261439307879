import { Fragment } from "react";

export const ChildSkuRender = ({ childSkuList }: { childSkuList: string }) => {
  let removePipes = childSkuList
    .split("|")
    //remove the empty values from the array
    .filter((n) => n)
    .join(",");

  return (
    <Fragment>
      Child Skus: <b>{removePipes}</b>
    </Fragment>
  );
};
