import { Table } from "@pankod/refine-antd";
import { IOrderShippingHistory } from "Interfaces";
import dayjs from "dayjs";

export const OrderShippingHistoryTable = ({
  data,
}: {
  data: IOrderShippingHistory[] | undefined;
}) => {
  //sort the data by date
  if (data) {
    data.sort((a, b) => {
      return dayjs(a.actionDateTime).isBefore(dayjs(b.actionDateTime)) ? 1 : -1;
    });
  }

  return (
    <Table dataSource={data} rowKey="id">
      <Table.Column dataIndex="id" title="Id" />
      <Table.Column<IOrderShippingHistory>
        dataIndex="actionType"
        title="Action Type"
      />
      <Table.Column<IOrderShippingHistory>
        dataIndex="actionDateTime"
        title="Action Date"
        render={(_, record) => {
          return dayjs(record.actionDateTime).format("MM/DD/YYYY: hh:mm:ss");
        }}
      />

      <Table.Column<IOrderShippingHistory>
        dataIndex="actionedBy"
        title="Actioned By"
        render={(_, record) => {
          return record.actionedBy;
        }}
      />
      <Table.Column<IOrderShippingHistory>
        title="Description"
        render={(_, record) => {
          return record.actionDescription;
        }}
      />
    </Table>
  );
};
