import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { IVariant } from "Interfaces";

export const VariantStockOnHand = ({ variants }: { variants: IVariant[] }) => {
  const total = variants?.reduce(
    (accumulate, variant) => accumulate + variant.quantityAvailable,
    0
  );
  if (total > 0) {
    return (
      <div>
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      </div>
    );
  } else {
    return <CloseCircleTwoTone twoToneColor="#eb2f96" />;
  }
};
