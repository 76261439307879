import {useShow} from "@pankod/refine-core";
import {IOrder, IPayment, Product} from "Interfaces";
import {
    Show,
    Typography,
    Row,
    Col,
    Divider,
    Form,
    Grid,
    Button,
    useForm,
    Edit,
    ListButton,
    RefreshButton, useModal, Modal, Input, Space, useModalForm
} from "@pankod/refine-antd";
import {OrderStatusTagRender} from "./OrderStatusTagRender";
import {formatCurrency} from "Pages/Dashboard/FormatCurrency";
import {format} from 'date-fns'
import {GiftCardStatusTag} from "./GiftCardStatusTag";
import toast, {CheckmarkIcon} from "react-hot-toast";
import React, {Fragment, useEffect, useMemo, useState} from "react";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {processOrders, pushToAlgolia, updateOrderMeta} from "../../Services/productServices";

const {Title} = Typography;

export const ShowOrder: React.FC = () => {
    const {queryResult} = useShow<IOrder>();
    // const { formProps, saveButtonProps, queryResult, form, id } =
    //     useForm<IOrder>();
    //
    const {show: showOverrideShippingModal, close: closeOverrideShippingModal, modalProps} = useModal();


    const [currentPrintCount, setCurrentPrintCount] = useState(0)

    useEffect(() => {
        let currentCount = queryResult?.data?.data?.meta?.print_count ?? 0
        setCurrentPrintCount(currentCount)
    }, [queryResult?.data?.data])

    console.log(queryResult)

    const {data, isLoading} = queryResult;
    const record = data?.data;
    const [isPrinting, setIsPrinting] = useState(false);

    console.log('record', record)
    const hasMultiplePaymentRecords = record?.structuredData.payments?.length > 1;

    const getFirstPayment = (): IPayment | undefined => {
        if (record?.structuredData.payments && record?.structuredData.payments?.length > 0) {
            return record?.structuredData.payments[0];
        }
        return undefined;
    }
    const isIncompleteCart = record?.status === "incomplete";
    const firstPayment = getFirstPayment();
    const hasGiftCards = record?.structuredData.giftCards?.length > 0;
    const hasLineItems = record?.structuredData.lineItems?.length > 0;
    const hasGiftWrappingLineItem = record?.structuredData.gift?.product;
    const hasSubscriptionLineItems = record?.structuredData.subscriptions?.length > 0;
    console.log('hasLineItems', hasLineItems)
    const getPaymentCardInfo = (payment) => {
        if (payment.type === "GiftVoucher") {
            return payment.giftCardId;
        } else {
            return `${payment.scheme}: ending ...${payment?.last4}`;
        }
    }


    const getPaymentExpiry = (payment) => {
        if (payment.type === "GiftVoucher") {
            return "";
        } else {
            return `${payment.expiryMonth}/${payment?.expiryYear}`;
        }
    }

    const paymentItems = record?.structuredData.payments?.map((payment) => {
        return (

            <tr>
                <td>{payment.type}</td>
                <td>{getPaymentCardInfo(payment)}</td>
                <td>{getPaymentExpiry(payment)}</td>
                <td>{formatCurrency(payment.amount, true)}</td>
                <td>{payment.bankTransactionId}</td>
            </tr>

        )
    })

    const lineItems = record?.structuredData.lineItems?.map((lineItem) => {
        return (
            <tr>
                <td>{lineItem.sku}</td>
                <td>{lineItem.title} ({lineItem.packageName})</td>
                <td style={{textAlign: "right"}}>{lineItem.quantity}</td>
                <td style={{textAlign: "right"}}>{formatCurrency(lineItem.singlePrice, true)}</td>
                <td style={{textAlign: "right"}}>{formatCurrency(lineItem.discountedPrice, true)}</td>
                <td style={{textAlign: "right"}}>{formatCurrency(lineItem.discountedPrice * lineItem.quantity, true)}</td>
            </tr>
        )
    })

    console.log("LINE ITEMS", lineItems)

    const getGiftWrappingLineItem = (record) => {
        if (record?.structuredData.gift?.product) {
            const lineItem = record?.structuredData.gift?.product;
            return (
                <tr>
                    <td>{lineItem?.sku}</td>
                    <td>{lineItem?.title}</td>
                    <td style={{textAlign: "right"}}>1</td>
                    <td style={{textAlign: "right"}}>{formatCurrency(lineItem?.price, true)}</td>
                    <td style={{textAlign: "right"}}>{formatCurrency(lineItem?.price, true)}</td>
                    <td style={{textAlign: "right"}}>{formatCurrency(lineItem?.price, true)}</td>
                </tr>
            )
        }
        return undefined;
    }

    const giftWrappingLineItem = getGiftWrappingLineItem(record);


    const giftCards = record?.structuredData?.giftCards?.map((giftCard) => {
        return (
            <tr>
                <td style={{textAlign: "right"}}>{formatCurrency(giftCard?.cardValue, true)}</td>
                <td></td>
                <td style={{textAlign: "left"}}>{giftCard?.recipientName}</td>
                <td style={{textAlign: "left"}}>{giftCard?.recipientEmail}</td>
                <td style={{textAlign: "left"}}>{giftCard?.senderName}</td>
                <td style={{textAlign: "left"}}>{giftCard?.message}</td>
                <td style={{textAlign: "right"}}><GiftCardStatusTag status={giftCard?.status}/></td>
            </tr>
        )
    })

    const subscriptionLineItems = record?.structuredData.subscriptions?.map((lineItem) => {
        return (
            <tr>
                <td>{lineItem.masterSku}</td>
                <td>{lineItem.title}</td>
                <td style={{textAlign: "right"}}>{lineItem.quantity}</td>
                <td style={{textAlign: "right"}}>{formatCurrency(lineItem.singlePrice, true)}</td>
                <td style={{textAlign: "right"}}>{lineItem.frequency}</td>
                <td align="right">{lineItem.asap ? <CheckmarkIcon/> : <></>}</td>
            </tr>
        )
    })

    const submitOverrideShippingAddress = (values) => {
        console.log(values)
    }

    const onFinish = (values) => {

        console.log(values)
        toast.promise(
            updateOrderMeta(record.id, 'overridden_shipping_address',
                values.override_shipping_address)
            ,
            {
                loading: "Processing... please wait",
                success: "Updated shipping address",
                error: (error) => `${error}`,
            })
            .then(() => {
                closeOverrideShippingModal()
            })
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>

            {/*<Edit*/}
            {/*    // saveButtonProps={saveButtonProps}*/}
            {/*    */}
            {/*>*/}
            {/*</Edit>*/}

            <>
                <Modal
                    title="Title"
                    {...modalProps}
                    footer={null}
                >

                    <Form
                        //form={form} // Pass the form instance to the Form component
                        name="basic"
                        initialValues={{remember: true}}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Row>

                            <Form.Item
                                label="Override Shipping address"
                                name="override_shipping_address"
                                rules={[{required: false, message: 'Please input your address!'}]}
                                initialValue={record?.meta?.overridden_shipping_address}
                            >
                                <Input.TextArea/>
                            </Form.Item>

                        </Row>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>

                </Modal>
            </>

            <Show isLoading={isLoading} title={(isIncompleteCart ? "Active Cart" : "Online Order")}

                  pageHeaderProps={{
                      extra: (
                          <Fragment>
                              {/*<Button onClick={manualPushToAlgolgia}>Push to Algolia</Button>*/}
                              {/*<Button htmlType="submit" type={currentPrintCount <= 0 ? 'primary' : 'default'}*/}
                              {/*        onClick={printDocument}>*/}
                              {/*    Download as PDF ({currentPrintCount})*/}
                              {/*</Button>*/}

                              <Button type="primary" onClick={() => {
                                  showOverrideShippingModal()
                              }}>
                                  Override shipping address
                              </Button>
                          </Fragment>
                      ),
                  }}

            >

                <Divider
                    type="horizontal"
                    style={{height: "100%", marginTop: "20px"}}
                />

                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr auto 1fr auto 1fr",
                    gap: 10
                }}>
                    <table className="table table-striped" style={{width: "100%", alignSelf: "start"}}>

                        <tr>
                            <td>{(isIncompleteCart ? "Cart" : "Order")} ID #</td>
                            <td><b>{record?.id}</b></td>
                        </tr>
                        {!isIncompleteCart && (
                            <Fragment>
                                <tr>
                                    <td>Pronto Order #</td>
                                    <td><b>{record?.prontoOrderId}</b></td>
                                </tr>
                                <tr>
                                    <td>Order Date</td>
                                    {/* <td>(record?.orderDate & (<Title level={5}>{format(Date.parse(record?.orderDate), "DD MMM YYYY") }</Title>)</td> */}
                                    <td><b>{(new Date(record?.orderDate)).toLocaleString()}</b></td>
                                </tr>
                                <tr>
                                    <td>Order Status</td>
                                    <td><OrderStatusTagRender status={record?.status}/></td>
                                </tr>
                            </Fragment>
                        )}
                    </table>
                    <Divider
                        type="vertical"
                        style={{height: "100%"}}
                    />
                    <table className="table table-striped" style={{width: "100%", alignSelf: "start"}}>
                        <tr>
                            <td>Customer Name</td>
                            <td><b>{record?.structuredData.customerName}</b></td>
                        </tr>
                        <tr>
                            <td>Phone Number</td>
                            <td><b>{record?.structuredData.phoneNumber}</b></td>
                        </tr>
                        <tr>
                            <td>Email</td>
                            <td><b>{record?.structuredData.email}</b></td>
                        </tr>
                    </table>
                    <Divider
                        type="vertical"
                        style={{height: "100%"}}
                    />
                    <table className="table table-striped" style={{width: "100%", alignSelf: "start"}}>
                        <tr>
                            <td>Order Total</td>
                            <td><b>{formatCurrency(record?.orderValue, true)}</b></td>
                        </tr>
                        {record?.structuredData.gift?.product && (
                            <tr>
                                <td>Gift Wrapping</td>
                                <td><b>{formatCurrency(record?.structuredData.gift?.product?.price, true)}</b></td>
                            </tr>
                        )}
                        <tr>
                            <td>Shipping</td>
                            <td><b>{formatCurrency(record?.shippingValue, true)}</b></td>
                        </tr>
                        <tr>
                            <td>Total</td>
                            <td><b>{formatCurrency(record?.totalValue, true)}</b></td>
                        </tr>
                    </table>
                </div>
                <Divider
                    type="horizontal"
                    style={{height: "100%", marginTop: "20px"}}
                />
                <div style={{
                    display: "grid",
                    gridTemplateColumns: "1fr auto 1fr auto 1fr",
                    gap: 10
                }}>
                    <table className="table table-striped" style={{width: "100%", alignSelf: "start"}}>
                        <tr>
                            <td valign="top">Shipping</td>
                            {/* <td>(record?.orderDate & (<Title level={5}>{format(Date.parse(record?.orderDate), "DD MMM YYYY") }</Title>)</td> */}
                            <td><b>{record?.structuredData.shipping?.description}</b></td>
                        </tr>
                        <tr>
                            <td>Shipping Name</td>
                            <td><b>{record?.structuredData.shippingAddress?.addressName}</b>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">Shipping Address</td>
                            <td>
                                <div><b>{record?.structuredData.shippingAddress?.addressLine1}</b></div>
                                <div>
                                    <b>{record?.structuredData.shippingAddress?.locality} {record?.structuredData.shippingAddress?.region} {record?.structuredData.shippingAddress?.postcode}</b>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Delivery Instructions</td>
                            <td><b>{record?.structuredData.deliveryInstructions}</b>
                            </td>
                        </tr>

                    </table>
                    <Divider
                        type="vertical"
                        style={{height: "100%"}}
                    />
                    <table className="table table-striped" style={{width: "100%", alignSelf: "start"}}>
                        <tr>
                            <td>Billing Name</td>
                            <td><b>{record?.structuredData.billingAddress?.addressName}</b>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">Billing Address</td>
                            <td>
                                <div><b>{record?.structuredData.billingAddress?.addressLine1}</b></div>
                                <div>
                                    <b>{record?.structuredData.billingAddress?.locality} {record?.structuredData.billingAddress?.region} {record?.structuredData.billingAddress?.postcode}</b>
                                </div>
                            </td>
                        </tr>

                        {(hasMultiplePaymentRecords && !isIncompleteCart) && (
                            <tr>
                                <table>
                                    <thead>
                                    <th>Type</th>
                                    <th>Card</th>
                                    <th>Expiry</th>
                                    <th>Amount</th>
                                    <th>Trans&nbsp;#</th>
                                    </thead>
                                    {paymentItems}
                                </table>
                            </tr>
                        )}

                        {(!hasMultiplePaymentRecords && firstPayment?.type === "GiftVoucher" && !isIncompleteCart) && (
                            <>
                                <tr>
                                    <td>Payment Type</td>
                                    <td><b>{firstPayment?.type}</b></td>
                                </tr>
                                <tr>
                                    <td>Gift Card</td>
                                    <td><b>{firstPayment?.giftCardId}</b></td>
                                </tr>
                                <tr>
                                    <td>Amount</td>
                                    <td><b>{formatCurrency(firstPayment?.amount, true)}</b></td>
                                </tr>
                            </>
                        )}

                        {(!hasMultiplePaymentRecords && firstPayment?.type !== "GiftVoucher" && !isIncompleteCart) && (
                            <>
                                <tr>
                                    <td>Payment Type</td>
                                    <td><b>{firstPayment?.type}</b></td>
                                </tr>
                                <tr>
                                    <td>Card</td>
                                    <td><b>{firstPayment?.scheme}: ending ...{firstPayment?.last4}</b></td>
                                </tr>
                                <tr>
                                    <td>Expiry</td>
                                    <td><b>{firstPayment?.expiryMonth} / {firstPayment?.expiryYear}</b></td>
                                </tr>
                                <tr>
                                    <td>Transaction #</td>
                                    <td><b>{firstPayment?.bankTransactionId}</b></td>
                                </tr>
                            </>
                        )}


                    </table>
                    <Divider
                        type="vertical"
                        style={{height: "100%"}}
                    />
                    <table className="table table-striped" style={{width: "100%", alignSelf: "start"}}>
                        <tr>
                            <td valign="top">Gift Message</td>
                            <td><b>{record?.structuredData.gift?.message}</b></td>
                        </tr>
                    </table>
                </div>

                {hasLineItems && (
                    <>
                        <Divider
                            type="horizontal"
                            style={{height: "100%", marginTop: "20px"}}
                        />
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr",
                            gap: 10
                        }}>
                            <Title level={4}>Line Items</Title>
                            <table>
                                <thead>
                                <th style={{textAlign: "left"}}>Sku</th>
                                <th style={{textAlign: "left"}}>Item</th>
                                <th style={{textAlign: "right"}}>Quantity</th>
                                <th style={{textAlign: "right"}}>Single Price</th>
                                <th style={{textAlign: "right"}}>Discounted Price</th>
                                <th style={{textAlign: "right"}}>Total Price</th>

                                </thead>

                                <tbody>
                                {lineItems}
                                {hasGiftWrappingLineItem && giftWrappingLineItem}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}


                {hasSubscriptionLineItems && (
                    <>
                        <Divider
                            type="horizontal"
                            style={{height: "100%", marginTop: "20px"}}
                        />
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr",
                            gap: 10
                        }}>
                            <Title level={4}>Subscriptions</Title>
                            <table>
                                <thead>
                                <th style={{textAlign: "left"}}>Sku</th>
                                <th style={{textAlign: "left"}}>Item</th>
                                <th style={{textAlign: "right"}}>Quantity</th>
                                <th style={{textAlign: "right"}}>Single Price</th>
                                <th style={{textAlign: "right"}}>Frequency</th>
                                <th style={{textAlign: "right"}}>ASAP</th>

                                </thead>
                                <tbody>
                                {subscriptionLineItems}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}

                {hasGiftCards && (
                    <Fragment>
                        <Divider
                            type="horizontal"
                            style={{height: "100%", marginTop: "20px"}}
                        />
                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "1fr",
                            gap: 10
                        }}>
                            <Title level={4}>Gift Cards</Title>
                            <table>
                                <thead>
                                <th style={{textAlign: "right"}}>Card Value</th>
                                <th style={{width: "20px"}}></th>
                                <th style={{textAlign: "left"}}>Recipient</th>
                                <th style={{textAlign: "left"}}>Recipient Email</th>
                                <th style={{textAlign: "left"}}>Sender Name</th>
                                <th style={{textAlign: "left"}}>Message</th>
                                <th style={{textAlign: "right"}}>Status</th>

                                </thead>
                                <tbody>
                                {giftCards}
                                </tbody>
                            </table>
                        </div>
                    </Fragment>
                )}
            </Show>

        </>
    );
};
