import { Typography, Space } from "antd";

const { Text } = Typography;

export const PublishedStatus = ({
  publishedDate,
}: {
  publishedDate: string | null;
}) => {
  let hasPublishedDate: boolean = false;
  if (publishedDate) {
    hasPublishedDate = true;
  } else if (publishedDate === null) {
    hasPublishedDate = false;
  }

  return (
    <>
      {hasPublishedDate ? (
        <Text type="success">
          {" "}
          <b>Published</b>{" "}
        </Text>
      ) : (
        <Text type="warning">
          {" "}
          <b>Draft</b>{" "}
        </Text>
      )}
    </>
  );
};
