import React, { Fragment, useEffect, useMemo, useState } from "react";
import { IResourceComponentsProps, useList } from "@pankod/refine-core";

import dayjs from "dayjs";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import {
  Edit,
  FileField,
  Form,
  Input,
  ListButton,
  RefreshButton,
  Select,
  Space,
  useForm,
} from "@pankod/refine-antd";

// import { IProductAttributeType, IProductTag, Product } from "../../Interfaces";
import { MASTER_PRODUCT_TYPES } from "../../constants";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Row,
  Tabs,
  Typography,
} from "antd";
import { ProductAttributesTagRender } from "./Edit/ProductAttributesTagRender";
import { ProductTagRender } from "./Edit/ProductTagRender";
import {
  IAdditionalDetails,
  IProductAttributeModel,
  IProductAttributeType,
  IProductTagModel,
  ITag,
  ITagType,
  Product,
} from "../../Interfaces";
import { VariantItem } from "./Edit/VariantItem";
import { PublishedStatus } from "./Edit/PublishedStatus";
import {
  pushToAlgolia,
  refreshFromPronto,
  uploadTastingNotesFile,
} from "Services/productServices";
import { ChildSkuRender } from "./Edit/ChilldSkuRender";
import { MetaImageRender } from "./Edit/MetaImageRender";
import { CoverImageRender } from "./Edit/CoverImageRender";
import toast from "react-hot-toast";

const { Option } = Select;
const { TabPane } = Tabs;
const { TextArea } = Input;

// TODO move this maarkdown input somewhere else to make re-useable

interface MarkdownInputProps {
  value?: string | null;
  onChange?: (value: string) => void;
}

const MarkdownInput: React.FC<MarkdownInputProps> = ({ value, onChange }) => {
  // const [local, setLocal] = useState("");
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");

  return (
    <ReactMde
      value={value || undefined}
      onChange={onChange}
      selectedTab={selectedTab}
      onTabChange={setSelectedTab}
      generateMarkdownPreview={(markdown) =>
        Promise.resolve(<ReactMarkdown>{markdown}</ReactMarkdown>)
      }
    />
  );
};

export const ProductEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, form, id } =
    useForm<Product>();

  const { data: productAttributeTypeData } = useList<IProductAttributeType>({
    resource: "product-attribute-types",
    config: {
      pagination: { current: 1, pageSize: 500 },
    },
  });
  const { data: additionalDetailsDataResult } = useList<IAdditionalDetails>({
    resource: "additional-details",
    config: {
      pagination: { current: 1, pageSize: 500 },
    },
  });

  const { data: tagTypeDataResult } = useList<ITagType>({
    resource: "tag-types",
    config: {
      pagination: { current: 1, pageSize: 500 },
    },
  });

  const { data: tagDataResult } = useList<ITag>({
    resource: "tags",
    config: {
      pagination: { current: 1, pageSize: 500 },
    },
  });

  // data
  const variantsData = queryResult?.data?.data?.variants;
  const metaData = queryResult?.data?.data?.seo?.meta;
  const coverImageUrlData = queryResult?.data?.data?.coverImage?.url;
  const tastingNotesData = queryResult?.data?.data?.tastingNotesFile?.url;

  const coverImageUrl = () => {
    return (
      queryResult?.data?.data?.coverImage?.url ||
      "https://via.placeholder.com/300"
    );
  };

  const productAttrData = useMemo(() => {
    //  queryResult?.data?.data.productAttributes has the raw product attributes, without their parent
    // but we want to render them with knowledge of the parent (icon/colour/whatever)
    // so, match up the raw attributes to the parent
    if (
      !queryResult?.data?.data?.productAttributes ||
      !productAttributeTypeData?.data
    ) {
      // not enough info to try and match them up, return an empty array
      return [];
    }
    const result: IProductAttributeModel[] =
      queryResult.data.data.productAttributes.map((pa) => {
        const pat = productAttributeTypeData.data.find(
          (pat) => pat.id === pa.productAttributeType
        );
        if (!pat) {
          console.warn(
            `Product Edit: Unable to find product attribute type '${pa.productAttributeType}' for product attribute '${pa.id}'`
          );
        }
        return {
          ...pa,
          productAttributeTypeObject: pat,
        };
      });

    return result;
  }, [
    queryResult?.data?.data?.productAttributes,
    productAttributeTypeData?.data,
  ]);

  const productTagData = useMemo(() => {
    //  queryResult?.data?.data.tags has the raw product attributes, without their parent
    // but we want to render them with knowledge of the parent (icon/colour/whatever)
    // so, match up the raw attributes to the parent using the data from tagTypeDataResult
    if (!queryResult?.data?.data?.tags || !tagTypeDataResult?.data) {
      // not enough info to try and match them up, return an empty array

      return [];
    }
    const result: IProductTagModel[] = queryResult.data.data.tags.map((pt) => {
      const tagList = tagTypeDataResult.data.find((t) => t.id === pt.tagType);

      if (!tagList) {
        console.warn(
          `Product Edit: Unable to find tag type '${pt.tagType}' for tag '${pt.id}'`
        );
      }
      return {
        ...pt,
        tagTypeObject: tagList,
      };
    });

    return result;
  }, [queryResult?.data?.data?.tags, tagTypeDataResult?.data]);

  const customTagOptions = useMemo(() => {
    return tagDataResult?.data
      ?.map(
        // build an array with the id and label for the select option
        (t) => ({ id: t.id, label: `${t.tagType.name} : ${t.name}` })
      )
      .sort(
        // then sort everything
        (a, b) =>
          a.label.localeCompare(b.label, undefined, { sensitivity: "base" })
      )
      .map(
        // then build the final options
        (t) => (
          <Option key={t.id} value={t.id} title={t.label}>
            {t.label}
          </Option>
        )
      );
  }, [tagDataResult?.data]);

  const [customTags, setCustomTags] = useState([]);

  useEffect(() => {
    if (!queryResult?.data?.data?.customTags) {
      return;
    }

    setCustomTags(queryResult?.data?.data?.customTags.map((t) => t.id));
  }, [queryResult?.data?.data?.customTags]);

  const handleSelect = (value) => {
    setCustomTags((prevValue) => prevValue.concat([value]));
  };
  const handleDeselect = (value) => {
    setCustomTags((prevValue) => prevValue.filter((pv) => pv !== value));
  };

  useEffect(() => {
    form.setFieldsValue({ customTags });
  }, [customTags, form]);

  // --- additional details
  const additionlDetailsOptions = useMemo(() => {
    return additionalDetailsDataResult?.data
      ?.map(
        // build an array with the id and label for the select option
        (t) => ({ id: t.id, label: `${t.name}` })
      )
      .sort(
        // then sort everything
        (a, b) =>
          a.label.localeCompare(b.label, undefined, { sensitivity: "base" })
      )
      .map(
        // then build the final options
        (t) => (
          <Option key={t.id} value={t.id} title={t.label}>
            {t.label}
          </Option>
        )
      );
  }, [additionalDetailsDataResult?.data]);

  const [additionalDetailsIds, setAdditionalDetailsIds] = useState([]);

  useEffect(() => {
    if (!queryResult?.data?.data?.additionalDetails) {
      return;
    }

    setAdditionalDetailsIds(
      queryResult?.data?.data?.additionalDetails.map((t) => t.id)
    );
  }, [queryResult?.data?.data?.additionalDetails]);

  const handleAdditionalDetailsSelect = (value) => {
    setAdditionalDetailsIds((prevValue) => prevValue.concat([value]));
  };
  const handleAdditionalDetailsDeselect = (value) => {
    setAdditionalDetailsIds((prevValue) =>
      prevValue.filter((pv) => pv !== value)
    );
  };

  useEffect(() => {
    form.setFieldsValue({ additionalDetails: additionalDetailsIds });
  }, [additionalDetailsIds, form]);

  const manualProntoRefresh = () => {
    const skuToEdit = queryResult?.data?.data?.masterSku;
    toast.promise(refreshFromPronto(skuToEdit), {
      loading: "Refreshing product",
      success: "Product refreshed",
      error: (error) => `${error}`,
    });
  };
  const manualPushToAlgolgia = () => {
    const idToEdit = queryResult?.data?.data?.id;
    toast.promise(pushToAlgolia(idToEdit), {
      loading: "Pushing product to Algolia",
      success: "Product pushed to Algolia",
      error: (error) => `${error}`,
    });
  };
  const manualPublishCall = () => {
    if (queryResult?.data?.data?.published_at === null) {
      //if the prudct has a null value for published_at set to UTC value of now
      const now = new Date().toISOString();

      const allFormValue = form.getFieldsValue(true);
      //spread the form value except the value for published_at which we set to null.
      const newFormValue = {
        ...allFormValue,
        published_at: now,
      };
      //push the new formValues onto the form instance
      form.setFieldsValue(newFormValue);
      //this will only work if there is a published_at Form.Item hidden below
      form.submit();
    }
    //if the product has a string value in published_at then set to null
    if (queryResult?.data?.data?.published_at !== null) {
      const allFormValue = form.getFieldsValue(true);
      //spread the form value except the value for published_at which we set to null.
      const newFormValue = {
        ...allFormValue,
        published_at: null,
      };

      form.setFieldsValue(newFormValue);
      //as above this will only work if there is a published_at Form.Item hidden below
      form.submit();
    }
  };

  const handleCopyCoverImageToMetaImage = () => {
    const allFormValue = form.getFieldsValue(true);

    const newFormValue = {
      ...allFormValue,
      seo: { ...allFormValue.seo, metaImage: { ...allFormValue.coverImage } },
    };
    form.setFieldsValue(newFormValue);
  };

  const [file, setFile] = useState<File>(undefined);

  const handleInputChange = (event) => {
    return setFile(event.target.files[0]);
  };

  const uploadFile = (e) => {
    //create a new formData
    let formData = new FormData();
    //add the file to the formData
    formData.append("files", file);
    //append ref
    formData.append("ref", "product");
    //append refid
    formData.append("refId", id.toString());
    //append field
    formData.append("field", "tastingNotesFile");

    //then call the api at /upload

    toast.promise(uploadTastingNotesFile(formData), {
      loading: "Uploading file",
      success: "File uploaded",
      error: (error) => `${error}`,
    });
  };

  return (
    <Fragment>
      <Edit
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{
          extra: (
            <Fragment>
              <ListButton />
              <RefreshButton />
              <Button onClick={manualProntoRefresh}>Refresh from Pronto</Button>
              <Button onClick={manualPushToAlgolgia}>Push to Algolia</Button>
              {queryResult?.data?.data?.published_at ? (
                <Button onClick={manualPublishCall}>Unpublish</Button>
              ) : (
                <Button onClick={manualPublishCall}>Publish</Button>
              )}
            </Fragment>
          ),
        }}
      >
        <Tabs>
          <TabPane tab="Overview" key="1">
            <Form {...formProps} layout="vertical">
              <Row gutter={[32, 16]}>
                <Col span={12}>
                  <Row>
                    <Col>
                      <div>
                        {queryResult?.data?.data && (
                          <Fragment>
                            <Typography>
                              Draft/ Published Status:{" "}
                              <PublishedStatus
                                publishedDate={
                                  queryResult.data.data.published_at
                                }
                              />
                            </Typography>
                          </Fragment>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[8, 16]}>
                    <Col span={16}>
                      {queryResult?.data?.data && (
                        <Fragment>
                          {" "}
                          <div>
                            Name: <b>{queryResult.data.data.name}</b>{" "}
                          </div>
                          <div>
                            MasterSku: <b>{queryResult.data.data.masterSku}</b>
                          </div>
                          <div>
                            Product Type:{" "}
                            <b>{queryResult.data.data.productType}</b>
                          </div>
                        </Fragment>
                      )}
                    </Col>
                    <Col span={8}>
                      {queryResult?.data?.data && (
                        <Fragment>
                          <div>
                            <ChildSkuRender
                              childSkuList={queryResult.data.data.childSkus}
                            ></ChildSkuRender>
                          </div>
                          <div>
                            Master Status:{" "}
                            <b>{queryResult.data.data.masterStatus}</b>
                          </div>
                        </Fragment>
                      )}
                    </Col>
                  </Row>
                  <Divider plain></Divider>
                  <Row>
                    <Col span={24}>
                      <Form.Item label="Title" name="title">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Form.Item label="Slug" name="slug">
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        label="Master Product Type"
                        name="masterProductType"
                      >
                        <Select options={MASTER_PRODUCT_TYPES} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Is new until"
                        name="isNewProductUntil"
                        getValueProps={(value) => ({
                          value: value ? dayjs(value) : "",
                        })}
                      >
                        <DatePicker />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify="space-around">
                    <Form.Item
                      // label="Is searchable?"
                      name="isSearchableProduct"
                      valuePropName="checked"
                    >
                      <Checkbox>Product is searchable </Checkbox>
                    </Form.Item>

                    <Form.Item name="isPublicProduct" valuePropName="checked">
                      <Checkbox>Product is public </Checkbox>
                    </Form.Item>

                    <Form.Item
                      name="isFeaturedProduct"
                      valuePropName="checked"
                      // getValueProps={(value) => ({
                      //   value: value ? "checked" : "unchecked",
                      // })}
                    >
                      <Checkbox>Feature product </Checkbox>
                    </Form.Item>

                  </Row>
                  <Row gutter={[12, 0]}>
                    <Col span={12}>
                      <Form.Item label="Callout Primary" name="callOutPrimary">
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Callout Secondary"
                        name="callOutSecondary"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {/* HACK: This div is here because of the way that refine/ant design handles the setting of form vlaues, 
                    without this form item here the form.setFeildValue does not do anything -it also causes an error to be displayed in the 
                    console unless there is an element inside the Form.Item and this will allow us to manually publish/ upublish the product */}
                      <div style={{ display: "none" }}>
                        <Form.Item name="published_at">
                          <Input></Input>
                        </Form.Item>
                      </div>

                      {/* HACK: This div is here because of the way that refine/ant design handles the setting of form vlaues, 
                    without this form item here the form.setFeildValue does not do anything -it also causes an error to be displayed in the 
                    console unless there is an element inside the Form.Item */}
                      <div style={{ display: "none" }}>
                        <Form.Item name="customTags">
                          <Select></Select>
                        </Form.Item>
                      </div>

                      <Form.Item label="Custom Tags">
                        <Select
                          mode="multiple"
                          value={customTags}
                          onSelect={handleSelect}
                          onDeselect={handleDeselect}
                          filterOption={(input, option) => {
                            return (
                              option.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.title
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {customTagOptions}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row justify="center">
                    <Col>
                      <CoverImageRender
                        imageUrl={coverImageUrl()}
                      ></CoverImageRender>
                    </Col>
                  </Row>
                  <Row justify="center">
                    <Button
                      disabled={!coverImageUrlData}
                      onClick={handleCopyCoverImageToMetaImage}
                    >
                      {" "}
                      Update Meta Cover Image
                    </Button>
                  </Row>
                  <Row>
                    <Divider plain></Divider>
                  </Row>
                  <Row gutter={[8, 32]}>
                    <Col span={6}>Product Attributes:</Col>
                    <Col push={2} span={8}>
                      {queryResult?.data?.data && (
                        <Fragment>
                          <ProductAttributesTagRender
                            productAttrs={productAttrData}
                          ></ProductAttributesTagRender>
                        </Fragment>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={[8, 32]}>
                    <Col span={6}>Product Tags:</Col>
                    <Col push={2} span={8}>
                      {queryResult?.data?.data && (
                        <Fragment>
                          <ProductTagRender
                            productTags={productTagData}
                          ></ProductTagRender>
                        </Fragment>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Form.Item label="Variant Information" name={["variants"]}>
                  <Form.List name="variants" initialValue={variantsData}>
                    {(fields) =>
                      fields.map((field) => <VariantItem {...field} />)
                    }
                  </Form.List>
                </Form.Item>
              </Row>
            </Form>
          </TabPane>
          <TabPane tab="Description and Notes" key="2">
            <Form {...formProps} layout="vertical">
              <Row>
                <Col span={24}>
                  {/* HACK: This div is here because of the way that refine/ant design handles the setting of form vlaues, 
                    without this form item here the form.setFeildValue does not do anything it also causes an error to be displayed in the 
                    console unless there is an element inside the Form.Item i.e <Select> */}
                  <div style={{ display: "none" }}>
                    <Form.Item name="additionalDetails">
                      <Select></Select>
                    </Form.Item>
                  </div>

                  <Form.Item label="AdditionalDetails">
                    <Select
                      mode="multiple"
                      value={additionalDetailsIds}
                      onSelect={handleAdditionalDetailsSelect}
                      onDeselect={handleAdditionalDetailsDeselect}
                      filterOption={(input, option) => {
                        return (
                          option.key
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.title
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {additionlDetailsOptions}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  <Form.Item label="Description" name="description">
                    <MarkdownInput />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 0]}>
                <Col span={12}>
                  <Form.Item label="Staff Notes" name="staffNotes">
                    <MarkdownInput />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {/* TODO getting warning about this being null - need to fix in the model? */}
                  <Form.Item label="Tasting Notes" name="tastingNotes">
                    <MarkdownInput />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </TabPane>
          <TabPane tab="SEO" key="3">
            <Form {...formProps} layout="vertical">
              <Row gutter={[16, 16]}>
                <Col span={14}>
                  <Form.Item label="Meta Title" name={["seo", "metaTitle"]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Meta Description"
                    name={["seo", "metaDescription"]}
                  >
                    {/* TODO check the max length or recommened length of what a meta description is
                  i think its roughly 150 based on some googling for e.g recommended below
                  https://moz.com/learn/seo/meta-description#:~:text=Optimal%20meta%20description%20length,between%2050%20and%20160%20characters.
                */}
                    <TextArea rows={4} maxLength={150} />
                  </Form.Item>
                </Col>
                {/* <Divider type="vertical" style={{ height: "260px" }} /> */}
                <Col span={6}>
                  <Form.Item
                    label="Indexing"
                    name={["seo", "preventIndexing"]}
                    valuePropName="checked"
                  >
                    <Checkbox>Prevent Indexing?</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Form {...formProps} layout="horizontal">
              <Row>
                <Col span={14}>
                  <Typography style={{ marginBottom: "10px" }}>
                    <b>Meta Tags</b>{" "}
                  </Typography>

                  <Form.List name={["seo", "meta"]} initialValue={metaData}>
                    {(fields, { add, remove }) => (
                      <Fragment>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            size={"large"}
                            key={key}
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              marginBottom: 8,
                            }}
                            align="start"
                          >
                            <Form.Item
                              label="Name"
                              {...restField}
                              name={[name, "name"]}
                            >
                              <Input />
                            </Form.Item>
                            <Form.Item
                              label="Content"
                              {...restField}
                              name={[name, "content"]}
                            >
                              <TextArea
                                size="large"
                                // autoSize
                                allowClear
                                rows={2}
                                maxLength={150}
                              />
                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          </Space>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add Meta
                          </Button>
                        </Form.Item>
                      </Fragment>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </Form>
            <Form {...formProps} layout="vertical">
              <Row>
                <MetaImageRender />
              </Row>
            </Form>
          </TabPane>
          <TabPane tab="Tasting Notes" key="4">
            <Form {...formProps}>
              <Space direction="vertical">
                <FileField src={tastingNotesData} />
                <input
                  type="file"
                  name="tastingNotesFile"
                  accept="application/pdf"
                  onChange={handleInputChange}
                />
                <button disabled={!file} onClick={uploadFile}>
                  Upload File
                </button>
              </Space>
            </Form>
          </TabPane>
        </Tabs>
      </Edit>
    </Fragment>
  );
};
