import {getCodeByName, removeUndefinedOrNull} from "../Utilities/utils";
import * as _ from 'underscore'

const axios = require('axios');

export async function sendDataToUber(data) {
    const payload = buildUberPayload(data);
    return sendToUberAPI(payload);
}

export function buildUberPayload(row) {
    const address = (row.structuredData.shippingAddress?.addressLine1 ?? "") + ", " + ((row.structuredData.shippingAddress?.addressLine2 ?? ""))

    const metaAddress = row?.meta?.overridden_shipping_address
    const sendAddress = _.isEmpty(metaAddress) ? address : metaAddress

    const addressData =  row.structuredData.shippingAddress

    const parcels = row.structuredData.lineItems.map((item) => {
        return {
            "qty": item.quantity,
            "brand": "Brauz Anywhere",
            //"image_url": "https://cdn.shopify.com/s/files/1/0651/4989/4910/products/Case_71.jpg?v=1657162696",
            "third_party_order_line_item_id": item.id,
            "list_price": item.singlePrice,
            "name": item.title,
            // "page_url": "https://brauz-anywhere.myshopify.com/case",
            "store_thirdparty_id": "70008832254",
            "thirdparty_id": 43083707449598,

            "selected_attributes": []

        }
    });

    const payment = row.structuredData.payments[0]


    const out = {
        "confirm_on_session": true,
        "customer": {
            "email": row.structuredData.email,
            "first_name": row.structuredData.firstName || '',
            "last_name": row.structuredData.lastName || '',
            "phone_number": row.userPhoneNumber
        },
        "group_number": "BRAUZ",
        "order_id": row.prontoOrderId,
        "third_party_order_number": row.orderId,
        "items": parcels,
        "metadata": {

            // "event_id": "5707984896254",
            // "sale_transaction_id": 6688944685310,
            // "internal_id": "BRAUZ#5707984896254",

            address:sendAddress,
        },

        "payment_gateway": {
            "card_details_token": payment.bankTransactionId,
            "payment_gateway": "SHOPIFY"
        },

        "total_price": row.total,
        "promo_value": 0,
        "promo_code": "",
        "delivery": {
            "street": addressData.locality,
            "suburb": addressData.region,
            "state": getCodeByName(row.structuredData.shippingAddress?.region),
            "postcode": addressData.postcode,
            "country": addressData.country,
            "fee": 5,
            "type": "CUSTOM",
            "provider": "UBER"
        }
    }


    return removeUndefinedOrNull(out);
}

async function sendToUberAPI(payload) {
    const url = 'https://testapi.brauz.ai/thirdparty/order';
    const apiKey = process.env.SHIPPIT_API_KEY; // API key stored in environment variable

    console.log(process.env)
    console.log(apiKey)

    return axios.post(url, payload, {
        // headers: {'Authorization': 'xrJvbAfgLi5bLwAAPO8N_w'},
        contentType: 'application/json'
    });
}

// Sample usage
// Assuming you have the data array ready (similar to what you had in Google Sheets)
// const data = [...];
// sendDataToShippit(data);
