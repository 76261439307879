const axios = require("axios");

const { REACT_APP_API_URL, REACT_APP_STRAPI_TOKEN_KEY } = process.env;

const getAxiosConfig = () => {
  const token = localStorage.getItem(`${REACT_APP_STRAPI_TOKEN_KEY}`);
  if (!token) {
    return {};
  }
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export async function getProductBySku(sku) {
  return await axios.get(
    `${REACT_APP_API_URL}/productsbysku/${sku}`,
    getAxiosConfig()
  );
}

export async function setProductsRank(body) {
  return await axios.post(
    `${REACT_APP_API_URL}/product-ranks/setrank`,
    body,
    getAxiosConfig()
  );
}

export async function enableWebhook(body) {
  return await axios.post(
    `${REACT_APP_API_URL}/administration/webhooks/enable`,
    body,
    getAxiosConfig()
  );
}
export async function disableWebhook(body) {
  return await axios.post(
    `${REACT_APP_API_URL}/administration/webhooks/disable`,
    body,
    getAxiosConfig()
  );
}
export async function sendEmail(emailType, id, processName) {
  return await axios.post(
    `${REACT_APP_API_URL}/subscriptions/${id}/email`,
    { emailType, processName },
    getAxiosConfig()
  );
}

export async function getFinanceCsv({ dateFrom, dateTo }) {
  return await axios.get(
    `${REACT_APP_API_URL}/order-shippings/exportfinance?dateFrom=${dateFrom}&dateTo=${dateTo}`,
    getAxiosConfig()
  );
}
export async function getNextPackDate() {
  return await axios.get(
    `${REACT_APP_API_URL}/subscriptions/nextpackdate`,
    getAxiosConfig()
  );
}

export async function exportCarrierDataCSV({ data }) {
  return await axios.post(
    `${REACT_APP_API_URL}/order-shippings/export`,
    data,
    getAxiosConfig()
  );
}
export async function exportPackProjectionDataCSV({ data }) {
  return await axios.post(
    `${REACT_APP_API_URL}/subscriptions/project/export`,
    data,
    getAxiosConfig()
  );
}
export async function exportSubscriptionDataCSV({ data }) {
  return await axios.post(
    `${REACT_APP_API_URL}/subscriptions/export`,
    data,
    getAxiosConfig()
  );
}

export async function exportProductDataCSV({ data }) {
  return await axios.post(
      `${REACT_APP_API_URL}/products/export`,
      data,
      getAxiosConfig()
  );
}

export async function getForwardSubscriptionData() {
  return await axios.get(
    `${REACT_APP_API_URL}/subscriptions/project`,
    getAxiosConfig()
  );
}

export async function uploadTastingNotesFile(formData) {
  return await axios.post(
    `${REACT_APP_API_URL}/upload`,
    formData,
    getAxiosConfig()
  );
}

export async function uploadProductCoverImage(formData) {
  return await axios.post(
    `${REACT_APP_API_URL}/upload`,
    formData,
    getAxiosConfig()
  );
}
export async function refreshFromPronto(sku) {
  return await axios.post(
    `${REACT_APP_API_URL}/products/refreshfrompronto/${sku}`,
    undefined,
    getAxiosConfig()
  );
}

export async function pushToAlgolia(id) {
  return await axios.post(
    `${REACT_APP_API_URL}/products/pushtoalgolia/${id}`,
    undefined,
    getAxiosConfig()
  );
}

export async function updateOrderMeta(id,field,value){
  return await axios.post(
      `${REACT_APP_API_URL}/checkouts/updatemeta/${id}`,
      {
        key:field,
        value:value
      },
      getAxiosConfig()
  );
}

export async function pushTagForUpdate(id) {
  return await axios.post(
    `${REACT_APP_API_URL}/products/updatebytag/${id}`,
    { undefined },
    getAxiosConfig()
  );
}
export async function pushAttributeForUpdate(id) {
  return await axios.post(
    `${REACT_APP_API_URL}/products/updatebyattribute/${id}`,
    undefined,
    getAxiosConfig()
  );
}

export async function updateStockLevelCache() {
  return await axios.post(
    `${REACT_APP_API_URL}/products/updateStockLevelCache`,
    undefined,
    getAxiosConfig()
  );
}
export async function refreshStockAllocation(id: number) {
  return await axios.post(
    `${REACT_APP_API_URL}/subscription-runs/stockallocation/refresh`,
    { id },
    getAxiosConfig()
  );
}

export async function pauseSubscription(id: number, body: any) {
  return await axios.post(
    `${REACT_APP_API_URL}/subscriptions/${id}/pause`,
    body,
    getAxiosConfig()
  );
}
export async function cancelSubscription(id: number, body: any) {
  return await axios.post(
    `${REACT_APP_API_URL}/subscriptions/${id}/cancel`,
    body,
    getAxiosConfig()
  );
}
export async function restartSubscription(id: number, body: any) {
  return await axios.post(
    `${REACT_APP_API_URL}/subscriptions/${id}/restart`,
    body,
    getAxiosConfig()
  );
}

export async function processSubscription(id: number) {
  return await axios.post(
    `${REACT_APP_API_URL}/subscriptions/processsubscription/${id}`,
    undefined,
    getAxiosConfig()
  );
}

export async function processOrders(orders: number[], body: any) {
  return await axios.post(
    `${REACT_APP_API_URL}/subscription-orders/orderprocess`,
    { orders, body },
    getAxiosConfig()
  );
}
export async function cancelOrder(order: number, body: any) {
  return await axios.post(
    `${REACT_APP_API_URL}/subscription-orders/ordercancel`,
    { order, body },
    getAxiosConfig()
  );
}
export async function createOrders(id: string | number) {
  return await axios.post(
    `${REACT_APP_API_URL}/subscription-orders/createOrders`,
    { id },
    getAxiosConfig()
  );
}
export async function updateOrderHistory(
  id: string | number,
  processName: any
) {
  return await axios.post(
    `${REACT_APP_API_URL}/subscription-orders/updatehistory`,
    { id, processName },
    getAxiosConfig()
  );
}
export async function updateFrequency(
  id: string,
  frequency: any,
  processName: any
) {
  return await axios.post(
    `${REACT_APP_API_URL}/subscription/updatefrequency`,
    { id, frequency, processName },
    getAxiosConfig()
  );
}
export async function updateSubscriptionPack(
  id: string,
  masterSku: any,
  processName: any
) {
  return await axios.post(
    `${REACT_APP_API_URL}/subscription/updatepack`,
    { id, masterSku, processName },
    getAxiosConfig()
  );
}

export async function updateProductTags({
                                          id,
                                          tags
                                        }:{
  id:string,
  tags:number[]
}) {
  return await axios.put(
      `${REACT_APP_API_URL}/products/${id}`,
      { tags },
      getAxiosConfig()
  );
}
