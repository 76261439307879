import { DeleteButton, Popover } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";

export const SubscriptionRunDeleteButton = ({
  id,
  runName,
}: {
  id: number;
  runName: string;
}) => {
  const { list } = useNavigation();

  const content = "A run cannot be deleted when payment information exists";

  const trigger = ["hover"];

  return (
    <Popover placement="topLeft" content={content} trigger={trigger}>
      <DeleteButton
        resourceName="subscription-runs"
        recordItemId={id}
        confirmTitle={`Are you sure you want to delete ${runName}?
      Runs with payment info can not be deleted`}
        confirmOkText="Yes, delete this run"
        confirmCancelText="No! Cancel"
        //on success, naviate to the list page
        onSuccess={() => {
          list("subscription-runs");
        }}
      >
        Delete Run
      </DeleteButton>
    </Popover>
  );
};
