//this takes formprops and modal props

import {
  Modal,
  Form,
  Input,
  FormProps,
  ModalProps,
  FileField,
  Button,
} from "@pankod/refine-antd";
import { useCustom } from "@pankod/refine-core";
import { ISubscriptionRunSkuMap, Product } from "Interfaces";
import { Fragment } from "react";

export const EditSkuMapModalForm: React.FC<{
  formProps: FormProps;
  modalProps: ModalProps;
  queryData: ISubscriptionRunSkuMap | null | undefined;
}> = ({ formProps, modalProps, queryData }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const executedeSkuIsUndefined = queryData?.executedSku === undefined;
  // use custom
  const { data: productData } = useCustom<Product>({
    url: `${apiUrl}/productsbysku/${queryData?.executedSku}`,
    method: "get",
    queryOptions: {
      enabled: !executedeSkuIsUndefined,
    },
  });

  //TODO stop the custome query from being run if the sku is undefined, the above queryOptions isnt working

  const productDataData = productData?.data;
  let fileUrl = "";

  if (productDataData) {
    //get the first record in the array
    const productDataDataFirst = productDataData[0];

    //get the url from tastingNotesFile
    const productDataDataFirstTastingNotesFile =
      productDataDataFirst?.tastingNotesFile?.url;
    if (productDataDataFirstTastingNotesFile) {
      fileUrl = productDataDataFirstTastingNotesFile.toString();
    }
  }
  //get the first record from the product data
  const productId = productDataData?.[0]?.id;

  return (
    <Modal {...modalProps} title="Edit Sku Map">
      <Form {...formProps} layout="vertical" key="sku">
        {/* HACK: This div is here because of the way that refine/ant design handles the setting of form vlaues, 
                    without this form item here the payload ownt indclude the relevent id*/}
        <div style={{ display: "none" }}>
          <Form.Item name="subscription_run" style={{ width: "350px" }}>
            <Input></Input>
          </Form.Item>
        </div>
        <Form.Item
          label="Executed Title"
          name="executedTitle"
          style={{ width: "350px" }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="executedSku"
          name="executedSku"
          style={{ width: "350px" }}
        >
          <Input />
        </Form.Item>

        {/* HACK: This div is here because of the way that refine/ant design handles the setting of form vlaues, 
                    without this form item here the payload ownt indclude the relevent id*/}
        <div style={{ display: "none" }}>
          <Form.Item name="masterSku" style={{ width: "350px" }}>
            <Input />
          </Form.Item>
        </div>
        <Form.Item label="Master Sku" style={{ width: "350px" }}>
          {/*  need to input and join the mastertitle and the master Sku here in interpolated way */}
          {queryData?.masterSku}
        </Form.Item>

        {/* HACK: This div is here because of the way that refine/ant design handles the setting of form vlaues, 
                    without this form item here the payload ownt indclude the relevent id*/}
        <div style={{ display: "none" }}>
          <Form.Item name="masterTitle">
            <Input />
          </Form.Item>
        </div>

        <Form.Item label="Master Title" style={{ width: "350px" }}>
          {/* this is for display purposes - refine form wont let you display without editing and we still want to track the form value */}
          {queryData?.masterTitle}
        </Form.Item>

        {/* if fileUrl exists we show thhis other show nothing */}
        {fileUrl ? (
          <Fragment>
            <Form.Item label="Tasting Notes">
              <FileField src={fileUrl} />
            </Form.Item>

            <a href={`/products/edit/${productId}`}>
              <Button>Take me to this product</Button>
            </a>
          </Fragment>
        ) : (
          <Form.Item label="Tasting Notes"></Form.Item>
        )}
      </Form>
    </Modal>
  );
};
