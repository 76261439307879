import { ImageField } from "@pankod/refine-antd";
import { Form } from "antd";
import { Fragment } from "react";

export const MetaImageRender = () => {
  return (
    // In theory theese are the required metaImage properties as per the API docs, however in practice only the Id is required for it to work.
    // "id",
    // "name",
    // "hash",
    // "mime",
    // "size",
    // "url",
    // "provider"

    <Fragment>
      {/* HACK: This div is here because of the way that refine/ant design handles the setting of form vlaues, 
                   without this form item here the form.setFeildValue does not do anything  */}
      <div style={{ display: "none" }}>
        <Form.Item name={["seo", "metaImage", "id"]}>
          <ImageField width={300} value=""></ImageField>
        </Form.Item>
      </div>

      <Form.Item label="Meta Image" name={["seo", "metaImage", "url"]}>
        <ImageField
          width={300}
          value="" // Value will be overrriden by the Form.Item
        />
      </Form.Item>
    </Fragment>
  );
};
