import { Space, Tag } from "antd";
import { IProductTagModel } from "Interfaces";

export const ProductTagRender = ({
  productTags,
}: {
  productTags: IProductTagModel[];
}) => {
  return (
    <>
      {productTags.map((t) => (
        // <Space>
        <Tag key={t.id}>
          {t.tagTypeObject?.name} : {t.name}
        </Tag>
        // </Space>
      ))}
    </>
  );
};
