import {
  Button,
  Card,
  Checkbox,
  Col,
  CreateButton,
  EditButton,
  Form,
  Icons,
  Input,
  List,
  RefreshButton,
  Row,
  SaveButton,
  Select,
  Space,
  Table,
  TextField,
  DatePicker,
  useEditableTable,
  Tag,
} from "@pankod/refine-antd";
import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  useNavigation,
  useUpdate,
} from "@pankod/refine-core";
import {
  ORDER_SHIPPING_STATUS,
  SHIPPING_CARRIERS,
  SHIPPING_NOTIFICATION_OPTIONS,
} from "../../constants";
import dayjs from "dayjs";

import { Fragment, useState } from "react";
import { stringToTime } from "Utilities/stringToTime";
import { formatCurrency } from "../Dashboard/FormatCurrency";
import { exportCarrierDataCSV, getFinanceCsv } from "Services/productServices";
import { Dayjs } from "dayjs";
import { FirstLetterToUpperCase } from "Utilities/FirstLetterToUpperCase";
import { CSVExportTypes, IOrderShipping } from "Interfaces";
import { exportToCSV } from "Utilities/exportToCsv";
const { RangePicker } = DatePicker;

export const OrderShippingList: React.FC<IResourceComponentsProps> = () => {
  //NOTE if you want to set the initial filter value of the table, do it here.
  console.log("OrderShippingList top");
  const initialFilterValues: OrderShippingFilterVariables = {
    generalSearch: "",
    carrier: undefined,
    status: "ordered",
    isExported: false,
    orderCreateDate: [
      dayjs().startOf("day").subtract(7, "day"),
      dayjs().endOf("day"),
    ],
  };

  const dateToStartDate = (date: Dayjs | undefined) => {
    if (date) {
      return date.startOf("day").toISOString();
    }
    return undefined;
  };

  const dateToEndDate = (date: Dayjs | undefined) => {
    if (date) {
      return date.startOf("day").add(1, "day").toISOString();
    }
    return undefined;
  };

  //se tup state for filter variables
  const [filterValues, setFilterValues] =
    useState<OrderShippingFilterVariables>(initialFilterValues);

  const { edit } = useNavigation();

  const {
    tableProps,
    searchFormProps,
    tableQueryResult,
    formProps,
    isEditing,
    setId,
    saveButtonProps,
    cancelButtonProps,
    editButtonProps,
  } = useEditableTable<
    IOrderShipping,
    HttpError,
    {},
    OrderShippingFilterVariables
  >({
    resource: "order-shippings",
    initialPageSize: -1,
    hasPagination: false,
    defaultSetFilterBehavior: "replace",
    initialFilter: [
      {
        field: "status",
        operator: "eq",
        value: initialFilterValues.status,
      },
      {
        field: "isExported",
        operator: "eq",
        value: initialFilterValues.isExported,
      },
      {
        field: "orderCreateDate",
        operator: "gte",
        value: dateToStartDate(initialFilterValues.orderCreateDate[0]),
      },
      {
        field: "orderCreateDate",
        operator: "lt",
        value: dateToEndDate(initialFilterValues.orderCreateDate[1]),
      },
    ],
    onSearch: (params) => {
      console.log("params firing", params);
      setFilterValues(params);

      const filters: CrudFilters = [];
      const { generalSearch, status, carrier, isExported, orderCreateDate } =
        params;
      if (generalSearch) {
        filters.push({
          operator: "or",

          value: [
            {
              field: "prontoOrderId",
              operator: "contains",
              value: generalSearch,
            },
            {
              field: "prontoCustomerId",
              operator: "contains",
              value: generalSearch,
            },
            {
              field: "conNote",
              operator: "contains",
              value: generalSearch,
            },
            {
              field: "customerFirstName",
              operator: "contains",
              value: generalSearch,
            },
            {
              field: "customerLastName",
              operator: "contains",
              value: generalSearch,
            },
          ],
        });
      }
      if (!isExported) {
        console.log("isExported firing and is equal to ", isExported);
        filters.push({
          field: "isExported",
          operator: "eq",
          value: isExported,
        });
      }

      if (carrier) {
        filters.push({
          field: "carrier",
          operator: "eq",
          value: carrier,
        });
      }

      if (status) {
        filters.push({
          field: "status",
          operator: "eq",
          value: status,
        });
      }

      if (orderCreateDate) {
        filters.push(
          {
            field: "orderCreateDate",
            operator: "gte",
            value: dateToStartDate(orderCreateDate[0]),
          },
          {
            field: "orderCreateDate",
            operator: "lt",
            value: dateToEndDate(orderCreateDate[1]),
          }
        );
      }

      return filters;
    },
  });

  const { mutate } = useUpdate<IOrderShipping>();

  const handleCarrierChange = (id, value) => {
    mutate({
      resource: "order-shippings",
      id: id,
      values: { carrier: value },
      undoableTimeout: 0,
    });
  };

  // // setSeltedIds(tableQueryResult?.data?.data?.map((item) => item.id));
  //these are the ids that will get sent to the server for csv export
  const ids = tableQueryResult?.data?.data?.map((item) => item.id);

  const exportToFinance = () => {
    const response = getFinanceCsv({
      dateFrom: dateToStartDate(filterValues.orderCreateDate?.at(0)),
      dateTo: dateToEndDate(filterValues.orderCreateDate?.at(1)),
    });

    response.then((res) => {
      exportToCSV(res.data, "finance");
    });
  };

  const exportCarrierData = (carrier: CSVExportTypes) => {
    const format = carrier;

    const exportObject = {
      format,
      //NOTE we dont need to specify the date range as the ids are already filtered by the date range
      ids: ids,
    };

    const response = exportCarrierDataCSV({
      data: exportObject,
    });

    response.then((res) => {
      exportToCSV(res.data, format);
    });
  };

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        <Card title="Filters">
          <ProdFilter
            formProps={searchFormProps}
            initialFilterValue={initialFilterValues}
          />
        </Card>
        <List
          pageHeaderProps={{
            extra: (
              <Fragment>
                <RefreshButton />
                {!filterValues.carrier && !filterValues.generalSearch && (
                  <Button
                    style={{
                      backgroundColor: "#f5f5f5",
                      border: "solid black 1px",
                    }}
                    onClick={() => exportToFinance()}
                  >
                    Export to Finance
                  </Button>
                )}

                {/* NOTE the way i understand the instructions, the finance export will be used seperately to exporting the data to the carrier  
                so i have created a seperate button for it - this might have to change back if i have misunderstood*/}
                {filterValues?.carrier && (
                  <Button
                    //style bacground color to very light grey
                    style={{
                      backgroundColor: "#f5f5f5",
                      border: "solid black 1px",
                    }}
                    onClick={() => exportCarrierData(filterValues.carrier)}
                  >
                    Export to {FirstLetterToUpperCase(filterValues.carrier)}
                  </Button>
                )}

                <CreateButton />
              </Fragment>
            ),
          }}
        >
          <Form {...formProps}>
            <Table
              {...tableProps}
              rowKey="id"
              onRow={(record) => ({
                //this comes from the refine example in the docs, at the bottom we have to override the onclick to access the stand alone edit page
                onClick: (event: any) => {
                  if (event.target.nodeName === "TD") {
                    setId && setId(record.id);
                  }
                },
              })}
              // the ant-d table scroll is a bit of a pain to get working, i ended up going to SO and finding this.
              scroll={{ x: "max-content" }}
              //  This will get the horizontal scroll sticky - but the table collapses wierdly with no data in it
              // scroll={{ x: "max-content", y: 500 }}
            >
              <Table.Column<IOrderShipping> dataIndex="id " />
              <Table.Column
                sorter
                dataIndex="orderCreateDate"
                title="Order Date"
                render={(value) => dayjs(value).format("DD/MM/YYYY")}
              />
              <Table.Column<IOrderShipping>
                dataIndex="prontoOrderId"
                title="Pronto Order ID"
              />

              <Table.Column<IOrderShipping>
                dataIndex="carrier"
                title="Carrier"
                onCell={(record) => ({
                  style: {
                    backgroundColor: record.carrier ? "white" : "#fce77e",
                  },
                })}
                render={(_, record) => {
                  return (
                    <Select
                      allowClear
                      options={SHIPPING_CARRIERS}
                      style={{ minWidth: "100px" }}
                      value={record.carrier}
                      onChange={(value) =>
                        handleCarrierChange(record.id, value)
                      }
                    />
                  );
                }}
              />
              <Table.Column<IOrderShipping>
                title="Customer Name"
                render={(record) => {
                  const customerName =
                    record.customerFirstName + " " + record.customerLastName;
                  return <TextField value={customerName} />;
                }}
              />

              <Table.Column<IOrderShipping>
                title="Address "
                render={(record) => {
                  const shippingAddress =
                    record.shippingAddressLine1 +
                    ", " +
                    record.shippingLocality +
                    ", " +
                    record.shippingRegion +
                    ", " +
                    record.shippingPostCode;
                  return <TextField value={shippingAddress} />;
                }}
              />

              <Table.Column<IOrderShipping>
                dataIndex="deliveryNotes"
                title="Delivery Note"
                render={(value) => <TextField value={value} />}
              />
              <Table.Column<IOrderShipping>
                dataIndex="giftNote"
                title="Gift Note"
                render={(value) => <TextField value={value} />}
              />

              <Table.Column<IOrderShipping>
                sorter
                dataIndex="isExported"
                title="Exported"
                colSpan={20}
                render={(value) => <Checkbox checked={value} />}
              />

              <Table.Column<IOrderShipping>
                sorter
                dataIndex="status"
                title="Status"
                render={(_, record) => {
                  return record.status === "shipped" ? (
                    <Tag color="green">{record.status}</Tag>
                  ) : (
                    <Tag color="red">{record.status}</Tag>
                  );
                }}
              />
              <Table.Column<IOrderShipping>
                sorter
                dataIndex="newCustomer"
                title="New Customer"
                render={(value) => (value ? "YES" : "NO")}
              ></Table.Column>

              <Table.Column<IOrderShipping>
                dataIndex="conNote"
                title="Con Note"
                render={(value, data: any) => {
                  if (isEditing(data.id)) {
                    return (
                      <Form.Item name="conNote" style={{ margin: 0 }}>
                        <Input />
                      </Form.Item>
                    );
                  }
                  return <TextField value={value} />;
                }}
              />

              <Table.Column<IOrderShipping>
                dataIndex="authToLeave"
                title="Auth To Leave"
                render={(value) => <Checkbox checked={value} />}
              />

              <Table.Column<IOrderShipping>
                dataIndex="phoneNumber"
                title="Phone"
              />
              <Table.Column<IOrderShipping> dataIndex="email" title="Email" />
              <Table.Column<IOrderShipping>
                dataIndex="fromNote"
                title="From Note"
              />
              <Table.Column<IOrderShipping>
                dataIndex="insurance"
                title="Insurance"
                render={(value) => formatCurrency(value)}
              />
              <Table.Column<IOrderShipping> dataIndex="load" title="Load #" />

              <Table.Column<IOrderShipping>
                dataIndex="notification"
                title="Notification"
                render={(value) => (
                  <Select
                    allowClear
                    options={SHIPPING_NOTIFICATION_OPTIONS}
                    value={value}
                  />
                )}
              />
              <Table.Column<IOrderShipping>
                dataIndex="openTimeWindow"
                title="Time Window"
                render={(_, record) => {
                  const openTime = record.openTimeWindow;
                  const closeTime = record.closeTimeWindow;
                  return (
                    <Fragment>
                      {stringToTime(openTime)} - {stringToTime(closeTime)}
                    </Fragment>
                  );
                }}
              />

              <Table.Column<IOrderShipping>
                dataIndex="stackerId"
                title="Stacker"
              />

              <Table.Column<IOrderShipping>
                fixed="right"
                title="Actions"
                dataIndex="actions"
                key="actions"
                render={(_text, record) => {
                  if (isEditing(record.id)) {
                    return (
                      <Space>
                        <SaveButton {...saveButtonProps} size="small" />
                        <Button {...cancelButtonProps} size="small">
                          Cancel
                        </Button>
                      </Space>
                    );
                  }
                  return (
                    <Space>
                      <EditButton
                        {...editButtonProps(record.id)}
                        onClick={(e) => {
                          e.stopPropagation();
                          edit("order-shippings", record.id);
                        }}
                        size="small"
                      />
                    </Space>
                  );
                }}
              />
            </Table>
          </Form>
        </List>
      </Col>
    </Row>
  );
};

interface OrderShippingFilterVariables {
  generalSearch: string;
  carrier: CSVExportTypes;
  status: string;
  isExported: boolean;
  orderCreateDate: [Dayjs, Dayjs];
}

export const ProdFilter: React.FC<{
  formProps: any;
  initialFilterValue: OrderShippingFilterVariables;
}> = ({ formProps, initialFilterValue }) => {
  console.log("prod Filter is firing");
  return (
    <Form {...formProps}>
      <div style={{ display: "flex" }}>
        <Form.Item
          label="Search"
          name="generalSearch"
          style={{ paddingRight: "20px" }}
          initialValue={initialFilterValue.generalSearch}
        >
          <Input
            style={{ width: "260px" }}
            allowClear
            placeholder="pronto Id, connote, customer name"
            prefix={<Icons.SearchOutlined />}
          />
        </Form.Item>
        <Form.Item
          label="Carriers"
          name="carrier"
          style={{ paddingRight: "20px" }}
          initialValue={initialFilterValue.carrier}
        >
          <Select
            style={{ width: "150px" }}
            allowClear
            options={SHIPPING_CARRIERS}
            placeholder="Search Carrier"
            onClear={() => formProps.form.submit()}
            filterSort={(
              optionA: {
                label: string;
                value: string;
              },
              optionB: {
                label: string;
                value: string;
              }
            ) =>
              optionA.label === optionB.label
                ? 0
                : optionA.label < optionB.label
                ? -1
                : 1
            }
          />
        </Form.Item>

        <Form.Item
          label="Status"
          name="status"
          style={{ paddingRight: "20px" }}
          initialValue={initialFilterValue.status}
        >
          <Select
            style={{ width: "150px" }}
            allowClear
            options={ORDER_SHIPPING_STATUS}
            placeholder="Search Status"
            // onClear={() => formProps.form.submit()}
          />
        </Form.Item>

        <Form.Item
          label="Include Exported"
          name="isExported"
          style={{ paddingRight: "20px" }}
          valuePropName="checked"
          initialValue={initialFilterValue.isExported}
        >
          <Checkbox />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary">
            Filter
          </Button>
        </Form.Item>
      </div>
      <div>
        <Form.Item
          label="Search between Date range"
          name="orderCreateDate"
          initialValue={initialFilterValue.orderCreateDate}
        >
          <RangePicker />
        </Form.Item>
      </div>
    </Form>
  );
};
