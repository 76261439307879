// import * as _ from 'underscore'
//
// export function removeUndefinedOrNull(obj) {
//     return _.transform(obj, function(result, value, key) {
//         if (!_.isNull(value) && !_.isUndefined(value)) {
//             if (_.isObject(value) && !_.isArray(value)) {
//                 value = removeUndefinedOrNull(value);
//             }
//             result[key] = value;
//         }
//     });
// }

import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export function removeUndefinedOrNull(obj){
    return obj
}

const regions = [
    { Code: "ACT", Name: "Australian Capital Territory", CountryCode: "AUS" },
    { Code: "NSW", Name: "New South Wales", CountryCode: "AUS" },
    { Code: "NT", Name: "Northern Territory", CountryCode: "AUS" },
    { Code: "QLD", Name: "Queensland", CountryCode: "AUS" },
    { Code: "SA", Name: "South Australia", CountryCode: "AUS" },
    { Code: "TAS", Name: "Tasmania", CountryCode: "AUS" },
    { Code: "VIC", Name: "Victoria", CountryCode: "AUS" },
    { Code: "WA", Name: "Western Australia", CountryCode: "AUS" }
];

export function getCodeByName(name) {
    const lowercasedName = name?.toLowerCase();
    const region = regions.find(region => region?.Name?.toLowerCase() === lowercasedName);
    return region ? region.Code : null;
}

export function formatDate(currentDate, timeZone, formatStr) {
    // Convert the date to the specified time zone
    const zonedDate = utcToZonedTime(currentDate, timeZone);

    // Format the zoned date
    return format(zonedDate, formatStr);
}

