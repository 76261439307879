import { Button, List, Table, useTable } from "@pankod/refine-antd";
import { IStockAllocationData } from "Interfaces";
import { Fragment } from "react";
import toast from "react-hot-toast";
import { refreshStockAllocation } from "Services/productServices";

export const StockAllocationTable: React.FC<{
  id: number;
}> = ({ id }) => {
  const {
    tableProps: stockAllocationDataProps,
    tableQueryResult: stockAllocationDataQueryResult,
  } = useTable<IStockAllocationData>({
    resource: `subscription-runs/${id}/stockallocation`,
  });

  const handleManualStockRefresh = async () => {
    const updateTablePromise = refreshStockAllocation(id);
    toast
      .promise(updateTablePromise, {
        loading: "Loading...",
        success: "Refreshed Stock Allocation",
        error: (error) => `${error}`,
      })
      .then(() => stockAllocationDataQueryResult.refetch());
  };

  return (
    <List
      title="Stock Allocation"
      pageHeaderProps={{
        extra: (
          <Fragment>
            <Button
              // disabled={!isCompleteSkuMap}
              onClick={handleManualStockRefresh}
            >
              Manual Update
            </Button>
          </Fragment>
        ),
      }}
    >
      <Table
        {...stockAllocationDataProps}
        style={{ width: "100%" }}
        rowKey="id"
      >
        <Table.Column<IStockAllocationData>
          align="center"
          title="Sku"
          dataIndex="sku"
        />
        <Table.Column<IStockAllocationData>
          align="center"
          title="Required"
          dataIndex="required"
        />

        <Table.Column<IStockAllocationData>
          align="center"
          title="Reserved"
          dataIndex="reserved"
        />
        <Table.Column<IStockAllocationData>
          align="center"
          title="Spare"
          dataIndex="spare"
        />
        <Table.Column<IStockAllocationData>
          align="center"
          title="Status"
          dataIndex="status"
        />
      </Table>
    </List>
  );
};
