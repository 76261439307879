import { Spin, Tag } from "@pankod/refine-antd";
import { melbourneTimeNowHelper } from "Utilities/melbourneTimeNowHelper";
import { utcToMelbourneTimeHelper } from "Utilities/utcToMelbourneTimeHelper";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import { useEffect, useState } from "react";

export const WorkflowResetStatus = ({
  status,
  autoReactivateDateTimeUTC,
}: {
  status: number;
  autoReactivateDateTimeUTC: string;
}) => {
  const noResetStatusText = "No auto reset";

  dayjs.extend(relativeTime);
  dayjs.extend(duration);

  const utcToMelbourneTimeHelperResult = utcToMelbourneTimeHelper(
    autoReactivateDateTimeUTC
  );

  const calculateTimeLeft = () => {
    const targetTime = dayjs(utcToMelbourneTimeHelperResult);
    const timeInMelbourneNow = melbourneTimeNowHelper();
    const timeRemainingHours = dayjs
      .duration(targetTime.diff(timeInMelbourneNow))
      .hours();

    const timeRemainingMinutes = dayjs
      .duration(targetTime.diff(timeInMelbourneNow))
      .minutes();

    const timeRemainingSeconds = dayjs
      .duration(targetTime.diff(timeInMelbourneNow))
      .seconds();

    return {
      h: timeRemainingHours,
      m: timeRemainingMinutes,
      s: timeRemainingSeconds,
    };
  };

  // the countdown timer state
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  //   console.log("timeInMelbourneNow", {
  //     timeInMelbourneNow: timeInMelbourneNow.format("YYYY-MM-DD HH:mm:ss"),
  //     utcToMelbourneTimeHelperResult: utcToMelbourneTimeHelperResult
  //       ? utcToMelbourneTimeHelperResult.format("YYYY-MM-DD HH:mm:ss")
  //       : null,
  //   });

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [autoReactivateDateTimeUTC]);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }
    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}
      </span>
    );
  });

  // loop over the timerComponents and output the time remaining as a string, otherwise my grid want to spit them out as new rows on the grid
  const timerComponentsString = timerComponents?.map((component) => {
    return `${component.props.children[0]} ${component.props.children[2]} `;
  });

  if (status === 1 && !autoReactivateDateTimeUTC) {
    console.log("status === 0 && resetTime === null");
    return <Tag color="grey">{noResetStatusText}</Tag>;
  } else if (autoReactivateDateTimeUTC) {
    console.log("status === 0 && resetTime !== null");
    return <Tag color="grey">{timerComponentsString} left</Tag>;
  } else if (status === 0) {
    console.log("status === 1");
    return <Tag color="white"></Tag>;
  }
};
