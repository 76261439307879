import {getCodeByName, removeUndefinedOrNull} from "../Utilities/utils";
import * as _ from 'underscore'

const axios = require('axios');

export async function sendDataToShippit(data) {
    const payload = buildShippitPayload(data);
    return sendToShippitAPI(payload);
}

export function buildShippitPayload(row) {
    const address = (row.structuredData.shippingAddress?.addressLine1 ?? "") + ", " + ((row.structuredData.shippingAddress?.addressLine2 ?? ""))

    const metaAddress = row?.meta?.overridden_shipping_address
    const sendAddress = _.isEmpty(metaAddress) ? address : metaAddress

    const parcels = row.structuredData.lineItems.map((item) => {
        return {
            "qty": item.quantity,
            "weight": 1
        }
    });

    const out = {
        "order": {
            "retailer_invoice": row.prontoOrderId,
            "receiver_contact_number": row.userPhoneNumber,
            "delivery_instructions": row.structuredData.deliveryInstructions,
            "courier_type": "standard",
            "delivery_address": sendAddress,
            "delivery_postcode": row.structuredData.shippingAddress?.postcode,
            "delivery_state": getCodeByName(row.structuredData.shippingAddress?.region),
            "delivery_suburb": row.structuredData.shippingAddress?.locality,
            "authority_to_leave": "",
            "price": row.totalValue,
            "parcel_attributes": parcels,
            "user_attributes": {
                "email": row.structuredData.email,
                "first_name": row.structuredData.firstName || '',
                "last_name": row.structuredData.lastName || ''
            }
        }
    }

    return removeUndefinedOrNull(out);
}

async function sendToShippitAPI(payload) {
    const url = 'https://app.shippit.com/api/3/orders';
    const apiKey = process.env.SHIPPIT_API_KEY; // API key stored in environment variable

    console.log(process.env)
    console.log(apiKey)

    return axios.post(url, payload, {
        headers: {'Authorization': 'xrJvbAfgLi5bLwAAPO8N_w'},
        contentType: 'application/json'
    });
}

// Sample usage
// Assuming you have the data array ready (similar to what you had in Google Sheets)
// const data = [...];
// sendDataToShippit(data);
