import {
  Col,
  Create,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  useForm,
} from "@pankod/refine-antd";
import React, { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  useList,
  useNavigation,
} from "@pankod/refine-core";
import { ISubscriptionRun } from "Interfaces";
import dayjs from "dayjs";
import { Dayjs } from "dayjs";
import { DEFAULT_OPEN_DATE, SUBSCRIPTION_RUN_STATUSES } from "../../constants";

export const SubscriptionRunCreate: React.FC<IResourceComponentsProps> = () => {
  const dateFormat = "DD/MM/YYYY";

  const { edit } = useNavigation();

  const { formProps, saveButtonProps, form } = useForm<ISubscriptionRun>({
    // custom url here because we want to create the defualt skumaps when we create the run
    resource: "subscription-runs/createrunwithmaps",
    redirect: false,
    onMutationSuccess: (data) => {
      edit("subscription-runs", data?.data?.id);
    },
  });

  //create a useState to hold the runDate value
  const [runDate, setRunDate] = useState<Date>(null);

  //create a method to handle the onChange of the runDate
  const handleRunDateChange = (value: Dayjs) => {
    const startOfDay = value.startOf("day").toDate();
    setRunDate(startOfDay);
  };

  //now create a useEffect that wacthes the runDate value and updates the form value for openDate and close date
  useEffect(() => {
    if (runDate) {
      //create const to hold the openDate and set it to the 27th of the previous month and time to midnight
      const openDate = dayjs(runDate)
        .subtract(1, "month")
        .date(DEFAULT_OPEN_DATE)
        .endOf("day")
        .toDate();
      //create const to hold the closeDate and set it to the 26th of the current month and time to end of day
      const closeDate = dayjs(runDate)
        .date(DEFAULT_OPEN_DATE)
        .startOf("day")
        .toDate();
      form.setFieldsValue({ openDateTime: openDate });
      form.setFieldsValue({ closeDateTime: closeDate });

      // NOTE because the runDate is stored in the DB as UTC we need to make sure that being the
      // 1st of the month it can't be allowed to set the local time (say 9am) otherwise in UTC it will be the last day of the previous month
      // so we intervene and set the time to 3pm
      // OR we only allow them the month picker and auto set the date to the 1st of the month
      // then they can manually set the open and close dates

      const runDateWithTimeToAvoidRollback = dayjs(runDate).hour(15).toDate();
      form.setFieldsValue({ runDate: runDateWithTimeToAvoidRollback });
    }
  }, [runDate, form]);

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Row>
          <Col span={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
              style={{ width: "350px" }}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Status" name="status" style={{ width: "350px" }}>
              <Select
                placeholder="Select a status"
                allowClear
                style={{ width: "350px" }}
                options={SUBSCRIPTION_RUN_STATUSES}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <Form.Item
                label="Run Date"
                name="runDate"
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
              >
                <DatePicker
                  format={dateFormat}
                  onChange={handleRunDateChange}
                />
              </Form.Item>
              <Form.Item
                label="Open Date"
                name="openDateTime"
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
                style={{ marginLeft: "20px" }}
              >
                <DatePicker format={dateFormat} />
              </Form.Item>
              <Form.Item
                label="Close Date"
                name="closeDateTime"
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
                style={{ marginLeft: "20px" }}
              >
                <DatePicker format={dateFormat} />
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
