import { ImageField } from "@pankod/refine-antd";

import { Fragment } from "react";

// create a icon render prop type
export type ProductAttributeIconRenderProps = {
  iconUrl: string;
  width?: number;
  height?: number;
};

export const ProductAttributeIconRender = ({
  prop,
}: {
  prop: ProductAttributeIconRenderProps;
}) => {
  const width = prop.width ? prop.width : 20;
  const height = prop.height ? prop.height : 20;

  if (!prop.iconUrl) {
    return (
      <ImageField
        value="https://via.placeholder.com/30"
        width={width}
        height={height}
      />
    );
  } else {
    return <ImageField value={prop.iconUrl} width={width} height={height} />;
  }
};
