import React, {Fragment} from 'react';
import {Typography} from "@pankod/refine-antd";
import LabelPdfRenderer from "./LabelPdfRenderer";

import {Document, Page, View, StyleSheet, Text, Image} from '@react-pdf/renderer';


const {Title} = Typography;

// Define your styles
const styles = StyleSheet.create({

    // ... other styles you need for content inside the A7 "pages"
    page: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: '#FFF',
    },
    a7Container: {
        width: '50%', // A7 is half the width of A4 in portrait
        height: '25%', // A7 is a quarter of the height of A4 in portrait
        padding: 10,
        borderWidth: 1,
        borderColor: '#000',
        borderStyle: 'solid',
    },
});

// Create Document Component
export const LabelWrapper = ({records}) => {

    if (!records) {
        return <div>Loading...</div>; // Or any placeholder you prefer
    }
    return (
        <Document>
            {records.map(one => {
                return (<>
                    <Page size={{width: 288, height: 432}}>
                            <LabelPdfRenderer record={one}></LabelPdfRenderer>
                    </Page>
                </>)
            })}
        </Document>
    )
}