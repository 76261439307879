import { Tag } from "@pankod/refine-antd";
import { StatusColours, SubscriptionOrderOrderStatuses } from "../../constants";

export const SubscriptionOrderOrderStatusTagRender = ({
  status,
}: {
  status: string;
}) => {
  switch (status) {
    case SubscriptionOrderOrderStatuses.PENDING:
      return <Tag color={StatusColours.PENDING}>{status}</Tag>;
    case SubscriptionOrderOrderStatuses.ORDERED:
      return <Tag color={StatusColours.ORDERED}>{status}</Tag>;
    case SubscriptionOrderOrderStatuses.SHIPPED:
      return <Tag color={StatusColours.SHIPPED}>{status}</Tag>;
    case SubscriptionOrderOrderStatuses.FAILED:
      return <Tag color={StatusColours.FAILED}>{status}</Tag>;
    case SubscriptionOrderOrderStatuses.ORDER_INTERVENTION_REQUIRED:
      return (
        <Tag color={StatusColours.ORDER_INTERVENTION_REQUIRED}>{status}</Tag>
      );

    default:
      console.warn(
        `SubscriptionOrder OrderStatus - Unknown status value selected '${status}' `
      );
      return <Tag color="#7a7d7b"> null </Tag>;
  }
};
