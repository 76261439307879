import {
  Button,
  Col,
  DateField,
  Divider,
  Edit,
  Form,
  Input,
  ListButton,
  RefreshButton,
  Row,
  Select,
  Space,
  Table,
  useForm,
  useSelect,
  useTable,
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { IAttribute, IProductAttributeType, Product } from "Interfaces";
import { ProductImageThumbnailRender } from "Pages/Products/ProductImageThumbnailRender";
import { ProductStateIconList } from "Pages/Products/ProductStateIconList";
import { PublishedProductStatusIcon } from "Pages/Products/PublishedProductStatusIcon";
import { VariantStockOnHand } from "Pages/Products/VariantStockOnHand";
import { Fragment } from "react";
import toast from "react-hot-toast";

import "react-mde/lib/styles/css/react-mde-all.css";
import { pushAttributeForUpdate } from "Services/productServices";

import { ProductAttributeIconFormRender } from "../ProductAttributeIconFormRender";
import {
  ProductAttributeIconRender,
  ProductAttributeIconRenderProps,
} from "../ProductAttributeIconRender";

const { TextArea } = Input;

export const ProdAttibuteEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, id } = useForm<IAttribute>();
  const { selectProps } = useSelect<IProductAttributeType>({
    resource: "product-attribute-types",
    optionLabel: "name",
    optionValue: "id",
    sort: [
      {
        field: "name",
        order: "asc",
      },
    ],
    defaultValue: queryResult.data?.data?.productAttributeType?.id,
  });

  const manualPushAttributesForUpdate = () => {
    toast.promise(pushAttributeForUpdate(id), {
      loading: "Updating Product Attributes...",
      success: (data) => `${data.data.message}`,
      error: (error) => `${error}`,
    });
  };

  //get the entire list of products
  const { tableProps } = useTable<Product>({
    resource: "products",
    initialPageSize: 50,
    permanentFilter: [
      {
        field: "_publicationState",
        operator: "eq",
        value: "preview",
      },
      {
        field: "productAttributes.id",
        operator: "eq",
        value: id,
      },
    ],
  });

  const returnIconRenderProp = () => {
    const iconRenderProps: ProductAttributeIconRenderProps = {
      iconUrl: queryResult.data?.data?.icon?.url,
      height: 75,
      width: 75,
    };
    return iconRenderProps;
  };

  return (
    <Fragment>
      <Edit
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{
          extra: (
            <Fragment>
              <ListButton />
              <RefreshButton />

              <Button onClick={manualPushAttributesForUpdate}>
                Manual Attribute Update
              </Button>
            </Fragment>
          ),
        }}
      >
        <Form {...formProps} layout="vertical">
          <Row gutter={[8, 16]}>
            <Col span={6}>
              <Form.Item label="Name" name="name">
                <Input />
              </Form.Item>

              <Form.Item label="Code" name="code">
                <Input />
              </Form.Item>

              {/* NOTE   This works and produces a post that strapi will accept however it doesnt match the documented API 
          The documented API expects tag-type to be a string for id, this prodcues tagtype as an object with a property called id with a value
          */}
              <Form.Item
                label="Attribute Type"
                name={["productAttributeType", "id"]}
              >
                <Select {...selectProps} maxTagTextLength={100} />
              </Form.Item>
              <Form.Item label="Icon link" name="iconLink">
                <Input />
              </Form.Item>
              <ProductAttributeIconRender prop={returnIconRenderProp()} />
            </Col>
            <Col>
              <Divider type="vertical" style={{ height: "260px" }} />
            </Col>

            <Col span={8}>
              <Form.Item label="Description" name="description">
                <TextArea rows={10} maxLength={350}></TextArea>
              </Form.Item>
            </Col>
            <Col>
              <Divider type="vertical" style={{ height: "260px" }} />
            </Col>
            <Col span={8}>
              <Form.Item label="Created At" name="created_at">
                <DateField format="LLL" value="created_at" />
              </Form.Item>
              <Form.Item label="Updated At" name="created_at">
                <DateField format="LLL" value="updated_at" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Edit>
      <Divider />
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="id" title="ID" sorter />
        <Table.Column<Product>
          dataIndex="title"
          title="Title"
          render={(_, record) => (
            <Fragment>
              <PublishedProductStatusIcon
                productProp={record}
              ></PublishedProductStatusIcon>
            </Fragment>
          )}
        ></Table.Column>
        <Table.Column<Product>
          title="Quick State"
          render={(_, record) => (
            <ProductStateIconList productProp={record}></ProductStateIconList>
          )}
        />

        <Table.Column dataIndex="masterSku" title="MasterSku" sorter />
        <Table.Column dataIndex="productType" title="Product Type" sorter />
        <Table.Column dataIndex="masterStatus" title="Status" sorter />
        {/* thanks to Keegan for this - https://ant.design/components/table/#Column (render) */}
        <Table.Column<Product>
          title="Variant SOH"
          align="center"
          render={(_, record) => (
            <>
              <Space size={"small"}>
                <VariantStockOnHand variants={record?.variants} />
                {record?.variants?.map((v) => v.quantityAvailable).join("/")}
              </Space>
            </>
          )}
        />

        <Table.Column<Product>
          title="Cover Image"
          align="center"
          render={(_, record) => (
            //add the render
            <ProductImageThumbnailRender
              imageUrl={record?.coverImage?.formats?.thumbnail?.url}
            ></ProductImageThumbnailRender>
          )}
        />
      </Table>
    </Fragment>
  );
};
