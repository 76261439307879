import {
  Card,
  Checkbox,
  Col,
  DateField,
  Form,
  Input,
  Row,
  Space,
  TextField,
  Typography,
} from "@pankod/refine-antd";

import { FormListFieldData } from "antd/lib/form/FormList";
import { Fragment } from "react";

export function VariantItem(field: FormListFieldData) {
  return (
    <Fragment>
      <Row>
        <Col span={24}>
          <Card bodyStyle={{ padding: "15px" }}>
            <Space size="middle">
              <Form.Item
                // style={{ width: "110px" }}
                label="Status"
                {...field}
                name={[field.name, "status"]}
              >
                <TextField value="status" />
              </Form.Item>
              <Form.Item
                style={{ width: "80px" }}
                label="Name"
                {...field}
                name={[field.name, "name"]}
              >
                <TextField value="name" />
              </Form.Item>
              <Form.Item
                style={{ width: "70px" }}
                label="Sku"
                {...field}
                name={[field.name, "sku"]}
              >
                <TextField value="sku" />
              </Form.Item>
              <Form.Item
                style={{ width: "110px", textAlign: "center" }}
                label="Qty in Variant"
                {...field}
                name={[field.name, "quantityInVariant"]}
              >
                <TextField value="quantityInVariant" />
              </Form.Item>
              <Form.Item
                label="Pronto Update?"
                {...field}
                name={[field.name, "lastUpdatedInPronto"]}
              >
                {!field.name ? (
                  <DateField format="LLL" value={"lastUpdatedInPronto"} />
                ) : (
                  <Typography>N/A</Typography>
                )}
              </Form.Item>
              <Form.Item
                style={{ width: "80px" }}
                label="SOH"
                {...field}
                name={[field.name, "quantityAvailable"]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Package Name"
                {...field}
                name={[field.name, "packageName"]}
              >
                <Input />
              </Form.Item>

              {/* below we access the state of no further discounts by using the valuePropName which is auto passed into checbox by the form.item */}
              <Form.Item
                label="No Discount"
                {...field}
                name={[field.name, "noFurtherDiscounts"]}
                valuePropName="checked"
                style={{ textAlign: "center" }}
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                style={{ width: "110px" }}
                label="Price"
                {...field}
                name={[field.name, "price"]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                  // label="Is searchable?"
                  label="Max allowed purchase"
                  {...field}
                  name={[field.name, "maxAllowedPurchase"]}
              >
                <Input/>
              </Form.Item>
            </Space>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}
