import { Typography } from "@pankod/refine-antd";
import { Fragment } from "react";

const { Text } = Typography;

export const IncompleteSkuMapWarning = ({
  isComplete,
}: {
  isComplete: boolean;
}) => {
  //if isIncomplete is true, then show the warning otherise hide it
  return (
    <Fragment>
      {!isComplete ? (
        <Text type="warning">
          {" "}
          <b>Warning:</b> This run has an incomplete SKU map. No subscriptions
          can be created until the maps are completed.
        </Text>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};
