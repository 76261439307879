import { Tag } from "@pankod/refine-antd";
import { StatusColours, SubscriptionOrderStatuses } from "../../constants";

export const SubscriptionOrderStatusTagRender = ({
  status,
}: {
  status: string;
}) => {
  switch (status) {
    case SubscriptionOrderStatuses.PENDING:
      return <Tag color={StatusColours.PENDING}>{status}</Tag>;
    case SubscriptionOrderStatuses.QUEUED:
      return <Tag color={StatusColours.QUEUED}>{status}</Tag>;
    case SubscriptionOrderStatuses.PAID:
      return <Tag color={StatusColours.PAID}>{status}</Tag>;
    case SubscriptionOrderStatuses.PAYMENT_ERROR:
      return <Tag color={StatusColours.PAYMENT_ERROR}>{status}</Tag>;
    case SubscriptionOrderStatuses.ORDERED:
      return <Tag color={StatusColours.ORDERED}>{status}</Tag>;
    case SubscriptionOrderStatuses.ORDER_INTERVENTION_REQUIRED:
      //the linter is making me do this
      return (
        <Tag color={StatusColours.ORDER_INTERVENTION_REQUIRED}>{status}</Tag>
      );
    case SubscriptionOrderStatuses.CANCELLED:
      return <Tag color={StatusColours.CANCELLED}>{status}</Tag>;

    default:
      console.warn(
        `Subscription Order Status - Unknown status value selected '${status}' `
      );
      return <Tag color="#7a7d7b"> null </Tag>;
  }
};
