import { Card, CardProps, Col, Row, Space } from "@pankod/refine-antd";
import { ProductsDashPanel } from "./ProductsDashPanel";
import { OrdersDashPanel } from "./OrdersDashPanel";
import { Fragment } from "react";
import { StockLevelPanel } from "./StockLevelPanel";
import { MissingProductImagesPanel } from "./MissingProductImagesPanel";
import { RunPanel } from "./RunPanel";
import { WorkflowStatusPanel } from "./WorkflowStatusPanel";

export const DashBoard = () => {
  const cardStyle: CardProps["style"] = {
    borderRadius: "15px",
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    minWidth: "400px",
  };

  const productsTableCardStyle: CardProps["style"] = {
    borderRadius: "15px",
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    minWidth: "650px",
  };
  const runCardStyle: CardProps["style"] = {
    borderRadius: "15px",
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    minWidth: "650px",
  };
  const workFlowCardStyle: CardProps["style"] = {
    borderRadius: "15px",
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    minWidth: "400px",
  };

  const cardHeadStyle: CardProps["headStyle"] = {
    textAlign: "center",
    backgroundColor: "#410563",
    color: "#FFFF",
  };

  return (
    <Fragment>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col>
          <Card title="Products" style={cardStyle} headStyle={cardHeadStyle}>
            <Space direction="horizontal">
              <ProductsDashPanel />
            </Space>
          </Card>
        </Col>
        <Col>
          <Card title="Orders" style={cardStyle} headStyle={cardHeadStyle}>
            <Space direction="horizontal">
              <OrdersDashPanel />
            </Space>
          </Card>
        </Col>
        <Col>
          <Card
            title="Workflow"
            style={workFlowCardStyle}
            headStyle={cardHeadStyle}
            bodyStyle={{ alignSelf: "center" }}
          >
            <Space direction="horizontal">
              <WorkflowStatusPanel />
            </Space>
          </Card>
        </Col>
        <Col>
          <Card title="Runs" style={runCardStyle} headStyle={cardHeadStyle}>
            <RunPanel />
          </Card>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={8}>
          <Card
            title="Stock Level Reconciliation"
            style={cardStyle}
            headStyle={cardHeadStyle}
          >
            <Space direction="vertical">
              <StockLevelPanel />
            </Space>
          </Card>
        </Col>
        <Col>
          <Card
            title="Products Missing Images"
            style={productsTableCardStyle}
            headStyle={cardHeadStyle}
          >
            <MissingProductImagesPanel />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
