import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  Form,
  Input,
  Button,
  Card,
  Icons,
  Select,
  FormProps,
  Row,
  Col,
  Space,
  EditButton,
  TextField, Switch,
} from "@pankod/refine-antd";

import { Product } from "../../Interfaces";
import { VariantStockOnHand } from "./VariantStockOnHand";
import { ProductImageThumbnailRender } from "./ProductImageThumbnailRender";
import { ProductStateIconList } from "./ProductStateIconList";
import {
  PRODUCT_STATUSES,
  PRODUCT_TYPES,
  PUBLICATION_STATUSES,
  PUBLICATION_STATUS_LIVE,
  PUBLICATION_STATUS_PREVIEW,
} from "../../constants";
import { PublishedStatus } from "./Edit/PublishedStatus";
import { StatusTagRender } from "./StatusTagRender";
import {Checkbox} from "antd";
import React from "react";
import {exportProductDataCSV, exportSubscriptionDataCSV} from "../../Services/productServices";
import {exportToCSV} from "../../Utilities/exportToCsv";
import {ProdFilter} from "./ProdFilter";

export const ProdList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, searchFormProps } = useTable<
    Product,
    HttpError,
    ProductFilterVariables
  >({
    initialPageSize: 50,
    initialSorter: [
      {
        field: "id",
        order: "asc",
      },
    ],
    initialFilter: [
      {
        field: "_publicationState",
        operator: "eq",
        value: "preview",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];
      // setFilters({}, "replace");
      const {
        masterStatus,
        generalSearch,
        productType,
        title,
        publicationStatus,
        filterOutBlankCover,
          filterOutBlankDesc,
      } = params;

      if (generalSearch) {
        filters.push({
          operator: "or",
          value: [
            {
              field: "id",
              operator: "contains",
              value: generalSearch,
            },
            {
              field: "childSkus",
              operator: "contains",
              value: generalSearch,
            },
            {
              field: "title",
              operator: "contains",
              value: generalSearch,
            },
          ],
        });
      }

      filters.push(
        {
          field: "masterStatus",
          operator: "eq",
          value: masterStatus,
        },
        {
          field: "productType",
          operator: "contains",
          value: productType,
        },
        {
          field: "title",
          operator: "contains",
          value: title,
        }
      );


        // filters.push(
        //     {
        //       field:"coverImage.formats.thumbnail.url",
        //       operator: "null",
        //       value: filterOutBlankCover,
        //     }
        // )

      if(filterOutBlankDesc){
        filters.push(
            {
              field:"description",
              operator:"null",
              value:true
            }
        )
      }else{
        filters.push(
            {
              field:"description",
              operator:"null",
              value:undefined
            }
        )
      }






      if (!publicationStatus) {
        // haven't picked anything from the dropdown, they don't care. Include the normal filter for preview, which will include draft and published
        filters.push(
          {
            field: "_publicationState",
            operator: "eq",
            value: "preview",
          },
          {
            field: "published_at",
            operator: "null",
            value: undefined,
          }
        );
      } else if (publicationStatus === PUBLICATION_STATUS_PREVIEW) {
        // picked Draft from the dropdown. We only want to show draft products. Which means we need to include the preview ones
        // and exclude anything that has a published date

        filters.push(
          {
            field: "_publicationState",
            operator: "eq",
            value: "preview",
          },
          {
            field: "published_at",
            operator: "null",
            value: true,
          }
        );
      } else if (publicationStatus === PUBLICATION_STATUS_LIVE) {
        // picked Published from the dropdown. We only want to show published products. Which means that we apply no filters at all here
        filters.push(
          {
            field: "_publicationState",
            operator: "eq",
            value: undefined,
          },
          {
            field: "published_at",
            operator: "null",
            value: undefined,
          }
        );
      } else {
        console.warn(
          `Product List onSearch. Unknown publicationStatus selected: '${publicationStatus}'`
        );
      }

      return filters;
    },
  });

  const handleCSVExport = async () => {
    const csvRows = [];

    console.log("CSV EXPORT")
    tableProps.dataSource.forEach((sub) => {
      console.log(sub);
      csvRows.push(sub.id);
    });

    const response = exportProductDataCSV({ data: csvRows });
    response.then((res) => {
      exportToCSV(res.data, "productData","productData-");
    });
  };

  return (
    <Row gutter={[16, 16]}>
      <Col lg={24} xs={24}>
        {/* TODO not really happy with the filter card layout  - needs some feedback and adjutment */}
        <Card title="Filters">
          <ProdFilter formProps={searchFormProps} />
        </Card>
        <List>
          <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
          >
            {/* export to csv button */}
            <Button
                type="primary"
                style={{ marginBottom: 10 }}
                onClick={handleCSVExport}
            >
              Export to CSV
            </Button>
          </div>
          <Table {...tableProps} rowKey="id">
            <Table.Column dataIndex="id" title="ID" sorter />
            <Table.Column<Product>
              dataIndex="title"
              title="Title"
              render={(value) => <TextField value={value} />}
            ></Table.Column>
            <Table.Column<Product>
              title="Quick State"
              render={(_, record) => (
                <ProductStateIconList
                  productProp={record}
                ></ProductStateIconList>
              )}
            />

            <Table.Column dataIndex="masterSku" title="Sku" sorter />
            <Table.Column
              dataIndex="productType"
              title="Product Type"
              align="center"
              sorter
            />
            <Table.Column
              dataIndex="masterStatus"
              title="Status"
              render={(value) => <StatusTagRender status={value} />}
              align="center"
              sorter
            />
            {/* thanks to Keegan for this - https://ant.design/components/table/#Column (render) */}
            <Table.Column<Product>
              title="Variant SOH"
              align="center"
              render={(_, record) => (
                <>
                  <Space size={"small"}>
                    <VariantStockOnHand variants={record?.variants} />
                    {record?.variants
                      ?.map((v) => v.quantityAvailable)
                      .join("/")}
                  </Space>
                </>
              )}
            />

            {/* when accessing complex data we use this structure to gain some type safety */}
            <Table.Column<Product>
              title="Cover Image"
              align="center"
              render={(_, record) => (
                //add the render
                <ProductImageThumbnailRender
                  imageUrl={record?.coverImage?.formats?.thumbnail?.url}
                ></ProductImageThumbnailRender>
              )}
            />
            <Table.Column
              dataIndex="published_at"
              title="Status"
              render={(value) => <PublishedStatus publishedDate={value} />}
              sorter
            />

            <Table.Column<Product>
              title="Edit"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton size="small" recordItemId={record.id} />
                </Space>
              )}
            />
          </Table>
        </List>
      </Col>
    </Row>
  );
};

interface ProductFilterVariables {
  masterStatus: string;
  generalSearch: string;
  childSkus: string;
  productType: string;
  title: string;
  publicationStatus: string;
  filterOutBlankCover:boolean;
  filterOutBlankDesc:boolean;
}

