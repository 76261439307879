import React from "react";
import {Button, Col, Form, FormProps, Icons, Input, Row, Select, Space, Switch} from "@pankod/refine-antd";
import {PRODUCT_STATUSES, PRODUCT_TYPES, PUBLICATION_STATUSES} from "../../constants";

export const ProdFilter: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    return (
        <Form layout="vertical" {...formProps}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Space align="end" size={"large"}>
                        <Form.Item label="Search" name="generalSearch">
                            <Input
                                style={{ width: "260px" }}
                                allowClear
                                placeholder="Search Id, Sku's or Title..."
                                prefix={<Icons.SearchOutlined />}
                            />
                        </Form.Item>
                        <Form.Item label="Type" name="productType">
                            <Select
                                style={{ width: "150px" }}
                                allowClear
                                options={PRODUCT_TYPES}
                                placeholder="Product Types"
                                filterSort={(
                                    optionA: {
                                        label: string;
                                        value: string;
                                    },
                                    optionB: {
                                        label: string;
                                        value: string;
                                    }
                                ) =>
                                    optionA.label === optionB.label
                                        ? 0
                                        : optionA.label < optionB.label
                                            ? -1
                                            : 1
                                }
                            />
                        </Form.Item>
                        <Form.Item label="Status" name="masterStatus">
                            <Select
                                style={{ width: "150px" }}
                                allowClear
                                options={PRODUCT_STATUSES}
                                placeholder="Status"
                            />
                        </Form.Item>

                        <Form.Item label="State" name="publicationStatus">
                            <Select
                                style={{ width: "150px" }}
                                allowClear
                                options={PUBLICATION_STATUSES}
                                placeholder="State"
                            />
                        </Form.Item>

                        {/*<Form.Item*/}
                        {/*    name="filterOutBlankCover"*/}
                        {/*    label="Filter Out Blank Image"*/}
                        {/*    valuePropName="checked"*/}
                        {/*>*/}
                        {/*  <Switch />*/}
                        {/*</Form.Item>*/}


                        <Form.Item
                            name="filterOutBlankDesc"
                            label="Filter Out Blank Descriptions"
                            valuePropName="checked"
                        >
                            <Switch />
                        </Form.Item>

                        {/*<Form.Item*/}
                        {/*    label="Only show new"*/}
                        {/*    name="isNew"*/}
                        {/*    valuePropName="checked"*/}
                        {/*    style={{*/}
                        {/*      marginLeft: "20px",*/}
                        {/*      display: "flex",*/}
                        {/*      width: "200px",*/}
                        {/*      justifyContent: "center",*/}
                        {/*      alignItems: "center",*/}
                        {/*    }}*/}
                        {/*>*/}

                        {/*</Form.Item>*/}




                        <Form.Item>
                            <Button htmlType="submit" type="primary">
                                Filter
                            </Button>
                        </Form.Item>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};
