import {
  Button,
  EditButton,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
import { useApiUrl, useCustom } from "@pankod/refine-core";

import { Caches, IStockLevel, Product } from "Interfaces";
import { Fragment, useState } from "react";
import { updateStockLevelCache } from "Services/productServices";
import dayjs from "dayjs";
export const StockLevelPanel = () => {
  const { tableProps, tableQueryResult } = useTable<IStockLevel>({
    resource: "products/checkStockLevels",
  });

  const apiUrl = useApiUrl();

  const { data: lastUpdatedData, refetch: lastUpdatedRefetch } =
    useCustom<Caches>({
      url: `${apiUrl}/caches`,
      method: "get",
      config: {
        query: {
          key: "mismatchedStockLevel",
        },
      },
    });

  const lastUpdated = lastUpdatedData?.data;
  const lastUpdatedDate = lastUpdated?.[0]?.updated_at;

  const [isLoading, setIsLoading] = useState(false);

  const handleGetFreshData = async () => {
    setIsLoading(true);
    await updateStockLevelCache();
    setIsLoading(false);
    tableQueryResult.refetch();
    lastUpdatedRefetch();
  };

  //get the last upadted value from the cahce table
  // const lastUpdated = tableQueryResult?.data?.data?.lastUpdated;

  return (
    <Fragment>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems:"center" }}>
          {/* <div>Last refresh:</div>
          <div style={{ marginLeft: "20px" }}> */}
          <div>
          Last refresh:&nbsp;<b>{dayjs(lastUpdatedDate).format("D MMM YY HH:mm ")}</b>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <Button loading={isLoading} onClick={handleGetFreshData}>
              Refresh Stock Levels
            </Button>
          </div>
        </div>
        <div style={{ marginTop: "10px" }}>
          <Table {...tableProps} rowKey="sku">
            <Table.Column<IStockLevel> dataIndex="sku" title="Sku" sorter />
            <Table.Column<IStockLevel>
              dataIndex="prontoCount"
              title="Pronto SOH"
              sorter
            />
            <Table.Column<IStockLevel>
              dataIndex="strapiCount"
              title="Strapi SOH"
              sorter
            />
            <Table.Column<Product>
              title="Link"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton
                    resourceNameOrRouteName="products"
                    size="small"
                    recordItemId={record.id}
                  />
                </Space>
              )}
            />
          </Table>
        </div>
      </div>
    </Fragment>
  );
};
