import {formatDate, getCodeByName} from "../Utilities/utils";
import * as _ from 'underscore'

const axios = require('axios');

export async function sendDataToOptimoRoute(data) {

    console.log('Send to CSV checked for row ');
    const order = buildOrder(data);

    return sendOrderToOptimoRoute(order);


}

function buildOrder(row) {
    const currentDate = new Date();

    const address = (row.structuredData.shippingAddress?.addressLine1 ?? "") + ", "
        + (row.structuredData.shippingAddress?.addressLine2 ?? "") + ", "
        + row.structuredData.shippingAddress?.locality + ", " + Number(row.structuredData.shippingAddress?.postcode)
        + ", " + getCodeByName(row.structuredData.shippingAddress?.region)

    const metaAddress = row?.meta?.overridden_shipping_address
    const sendAddress = _.isEmpty(metaAddress) ? address : metaAddress

    return {
        "operation": "CREATE",
        "orderNo": row.prontoOrderId, // ORDER ID
        "type": "D", // Assuming all orders are of type "D"
        "date": formatDate(currentDate, 'Australia/Brisbane', 'yyyy-MM-dd'),
        "location": {
            "address": sendAddress,
            "locationNo": "LOC" + row.id,
            "locationName": row.structuredData.firstName + " " + row.structuredData.lastName, // CUSTOMER NAME
            "acceptPartialMatch": true
        },
        "duration": 5, // Duration not specified in the sheet, using a fixed value
        "load1": 1, // LOAD #1
        "email": row.structuredData.email, // Email
        "phone": row.userPhoneNumber,
        // "customField1": "", // From
        "customField2": row.structuredData.firstName + " " + row.structuredData.lastName, // Customer Name
        // "customField3": "", // ATL
        "vehicleFeatures": [], // Vehicle features not specified in the sheet, using a fixed value
        "skills": [], // Skills not specified in the sheet, using fixed values
        "notes": row.structuredData.deliveryInstructions ?? "No instructions" // DELIVERY NOTES
    };
}

async function sendOrderToOptimoRoute(order) {
    const url = `https://api.optimoroute.com/v1/create_order?key=e73dbfdb9cb0dd92b2d6688e475cdec8qqXra4H7Zqs`;
    return axios.post(url, order, {headers: {'Content-Type': 'application/json'}})
        .then(response => {
            if (!response.data?.success) {
                throw new Error("OPTIMO: " + response.data.message);
            }
            return response;
        });
}

// Sample usage
// Assuming you have the data array ready (similar to what you had in the spreadsheet)
// const data = [...];
// sendDataToOptimoRoute(data);
