import { Table, useTable } from "@pankod/refine-antd";
import { Fragment } from "react";
import { MissingImageProductStatus } from "./MissingImageProductStatus";

export interface IMissingProductImageTable {
  productId: number;
  masterSku: string;
  published_at: string;
  name: string;
  variantName: string;
  sku: string;
  quantityInVariant: number;
  quantityAvailable: number;
}

export const MissingProductImagesPanel = () => {
  const { tableProps } = useTable<IMissingProductImageTable>({
    resource: "products/missingimages",
  });

  return (
    <Fragment>
      <div style={{ display: "flex" }}>
        <div>
          <Table
            {...tableProps}
            rowKey="sku"
            style={{ minWidth: "550px", maxWidth: "800px" }}
          >
            <Table.Column<IMissingProductImageTable>
              dataIndex="published_at"
              title="Status"
              sorter
              render={(_, record) => (
                <Fragment>
                  <MissingImageProductStatus productProp={record} />
                </Fragment>
              )}
            ></Table.Column>
            <Table.Column<IMissingProductImageTable>
              dataIndex="name"
              title="Product Name"
              sorter
              width="200px"
            />
            <Table.Column<IMissingProductImageTable>
              dataIndex="masterSku"
              title="Master Sku"
              sorter
            ></Table.Column>
            <Table.Column<IMissingProductImageTable>
              dataIndex="sku"
              title="Sku"
              sorter
            ></Table.Column>
            <Table.Column<IMissingProductImageTable>
              dataIndex="variantName"
              title="Variant Name"
            ></Table.Column>
            <Table.Column<IMissingProductImageTable>
              dataIndex="quantityInVariant"
              title="QiV"
              sorter
            ></Table.Column>
            <Table.Column<IMissingProductImageTable>
              dataIndex="quantityAvailable"
              title="QAv"
              sorter
            ></Table.Column>
          </Table>
        </div>
      </div>
    </Fragment>
  );
};
