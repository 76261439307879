import { Tag } from "@pankod/refine-antd";
import { StatusColours, SubscriptionRunStatuses } from "../../constants";

export const SubscriptionRunStatusTagRender = ({
  status,
}: {
  status: string;
}) => {
  switch (status) {
    case SubscriptionRunStatuses.PENDING:
      return <Tag color={StatusColours.PENDING}>{status}</Tag>;
    case SubscriptionRunStatuses.OPEN:
      return <Tag color={StatusColours.OPEN}>{status}</Tag>;
    case SubscriptionRunStatuses.CLOSED:
      return <Tag color={StatusColours.CLOSED}>{status}</Tag>;

    default:
      console.warn(`Subscription Run Status - Value unknown '${status}' `);
      return <Tag color="#7a7d7b"> null </Tag>;
  }
};
