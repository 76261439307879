import { Tag } from "@pankod/refine-antd";

export const SubscriptionStatusDisplay = ({ status }) => {
  if (status === "paused") {
    return (
      <div style={{ display: "flex" }}>
        <h1>Status:</h1>
        <h1 style={{ color: "yellow", marginLeft: "10px" }}>
          <Tag color="yellow">{status}</Tag>
        </h1>
      </div>
    );
  } else if (status === "cancelled") {
    return (
      <div style={{ display: "flex" }}>
        <h1>Status:</h1>
        <h1 style={{ color: "red", marginLeft: "10px" }}>
          <Tag color="red">{status}</Tag>
        </h1>
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex" }}>
        <h1>Status:</h1>
        <h1 style={{ color: "green", marginLeft: "10px" }}>
          <Tag color="green">{status}</Tag>
        </h1>
      </div>
    );
  }
};
