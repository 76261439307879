import { Button, Input, useModal, Modal } from "@pankod/refine-antd";
import { useApiUrl, useCustom } from "@pankod/refine-core";
import { IWebhookStatus } from "Interfaces";
import { Fragment, useState } from "react";
import { WorkflowStatusTag } from "./WorkflowStatusTag";
import { Spin } from "antd";
import { WorkflowResetStatus } from "./WorkflowResetStatus";
import { disableWebhook, enableWebhook } from "Services/productServices";
import { CloseOutlined } from "@ant-design/icons";
import toast from "react-hot-toast";

export const WorkflowStatusPanel = () => {
  const apiUrl = useApiUrl();

  const { data, isLoading, refetch, error } = useCustom<IWebhookStatus[]>({
    url: `${apiUrl}/administration/webhooks/status`,
    method: "get",
    // handle the error
  });
  const { modalProps, show, close } = useModal();

  const onWebHookDisable = (webhookName: string, time: string) => {
    console.log("onWebHookDisable", { webhookName, time });

    const body = {
      webhookName,
      restartInMinutes: time,
    };

    toast
      .promise(disableWebhook(body), {
        loading: "Disabling webhook",
        success: "Disabled",
        error: (error) => `${error}`,
      })
      .then(() => {
        refetch();
      });
  };

  const onWebHookEnable = (webhookName: string) => {
    console.log("onWebHookEnable", { webhookName });
    const body = {
      webhookName,
    };
    toast
      .promise(enableWebhook(body), {
        loading: "Enabling webhook",
        success: "Enabled",
        error: (error) => `${error}`,
      })
      .then(() => {
        refetch();
      });
  };

  const dataResult = data?.data;

  const onRefresh = () => {
    // refetch using the built in refetch
    refetch();
  };

  // create state to hold the webhook
  const [webhook, setWebhook] = useState<IWebhookStatus>();
  //sethe minute value
  const [minutes, setMinutes] = useState<string>("");

  const onEditWebhook = (selectedRow) => {
    // set the webhook into state
    setWebhook(selectedRow);

    show();
  };

  return (
    <Fragment>
      {/* we are using the built in isLoading */}
      {isLoading && <Spin size="large" />}

      {error && (
        // create a grid one col, two rows
        // top row is the service unavailable contact admin
        // bottom row is the error message
        <div
          style={{
            display: "grid",
            gap: 10,
            paddingBottom: 10,
          }}
        >
          <h2 style={{ fontWeight: "bold" }}>Service is Unavailable</h2>
        </div>
      )}

      {!isLoading &&
        !error &&
        dataResult?.map((element) => (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "2fr auto 1fr",
              gap: 10,
              paddingBottom: 10,
            }}
            key={element.webhookName}
            //  add the show and pass the element to the modal
            onClick={() => onEditWebhook(element)}
          >
            <div>{element.webhookName}</div>
            <WorkflowStatusTag status={element.status} />
            <div
              style={{
                display: "grid",
                gap: 10,
              }}
            >
              <WorkflowResetStatus
                status={element.status}
                autoReactivateDateTimeUTC={element.autoReactivateDateTimeUTC}
              />
            </div>
          </div>
        ))}

      {!isLoading && <Button onClick={onRefresh}>Refesh</Button>}

      <Modal onOk={close} {...modalProps}>
        {/* // now we get the webhook from state */}
        <div
          style={{
            display: "grid",
            justifyItems: "center",
            gap: 10,
            paddingBottom: 10,
          }}
        >
          <div>
            <b>{webhook?.webhookName}</b>
          </div>
          {/* if the webhook is enabled we want to be able to disable it */}
          {webhook?.status === 0 ? (
            <>
              <div
                style={{
                  width: "62%",
                }}
              >
                {/* add a input to set the minutes to disable */}
                <Input
                  type="number"
                  min={0}
                  allowClear
                  placeholder="Minutes to disable"
                  onChange={(e) => {
                    setMinutes(e.target.value);
                  }}
                />
              </div>
              <Button
                // set style to center button
                style={{ backgroundColor: "red", color: "white" }}
                onClick={() => {
                  onWebHookDisable(webhook?.webhookName, minutes);
                  close();
                }}
              >
                Disable {webhook?.webhookName}
              </Button>
            </>
          ) : (
            <div>
              <Button
                style={{ backgroundColor: "green", color: "white" }}
                onClick={() => {
                  onWebHookEnable(webhook?.webhookName);
                  close();
                }}
              >
                Enable {webhook?.webhookName}
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </Fragment>
  );
};
