import React, {Fragment, useState} from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import {formatCurrency} from "../Dashboard/FormatCurrency";
import {GiftCardStatusTag} from "./GiftCardStatusTag";
import {CheckmarkIcon} from "react-hot-toast";
import {Divider, Typography} from "@pankod/refine-antd";
import {OrderStatusTagRender} from "./OrderStatusTagRender";
import {OrderPdfRender} from "./OrderPdfRender";


const {Title} = Typography;

// Create Document Component
export const PdfRenderWrapper = ({records}) => {

    if (!records) {
        return <div>Loading...</div>; // Or any placeholder you prefer
    }


    return (
        <Document>
            {records.map(one=>{
                return (<>
                    <OrderPdfRender record={one}></OrderPdfRender>
                </>)
            })}
        </Document>
    )}