import { Product } from "Interfaces";
import { Fragment } from "react";
import { Badge } from "@pankod/refine-antd";

export const PublishedProductStatusIcon = ({
  productProp,
}: {
  productProp: Product;
}) => {
  let hasPublishedDate: boolean = false;
  if (productProp.published_at != null) {
    hasPublishedDate = true;
  } else if (productProp.published_at === null) {
    hasPublishedDate = false;
  }

  return (
    <>
      {hasPublishedDate ? (
        <Fragment>
          <Badge color="green" title="published" />{" "}
          <Fragment>{productProp.title}</Fragment>
        </Fragment>
      ) : (
        <Fragment>
          <Badge color="yellow" title="draft" />{" "}
          <Fragment>{productProp.title}</Fragment>
        </Fragment>
      )}
    </>
  );
};
