import {
  Button,
  Card,
  CardProps,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Edit,
  Form,
  Input,
  Row,
  Select,
  Typography,
  useForm,
} from "@pankod/refine-antd";
import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  SHIPPING_CARRIERS,
  ORDER_SHIPPING_STATUS,
  SHIPPING_NOTIFICATION_OPTIONS,
  SHIPPING_HOURS,
} from "../../../constants";
import { IOrderShipping, IOrderShippingHistory } from "Interfaces";
import { OrderShippingHistoryTable } from "../OrderShippingHistoryTable";
import { NewCustomerTagRender } from "./NewCustomerTag";
import { IsExportedTagRender } from "./IsExportedTagRender";
const { TextArea } = Input;
const dayjs = require("dayjs");
export const OrderShippingEdit: React.FC<IResourceComponentsProps> = () => {
  const { form, formProps, id, queryResult, saveButtonProps } =
    useForm<IOrderShipping>({
      resource: "order-shippings",
    });

  const orderShippingHistory: IOrderShippingHistory[] =
    queryResult.data?.data?.order_shipping_histories || [];

  const handleClearTimeRange = () => {
    form.setFieldsValue({
      closeTimeWindow: null,
      openTimeWindow: null,
    });
  };
  const handleTimeValidation = (value: any) => {
    if (value) {
      const openTime = form.getFieldValue("openTimeWindow");
      console.log("openTime", openTime);
      const closeTime = form.getFieldValue("closeTimeWindow");
      console.log("closeTime", closeTime);
      if (openTime && closeTime) {
        if (openTime > closeTime) {
          alert("Open time must be before close time");
          handleClearTimeRange();
          return Promise.reject("Open time must be before close time");
        }
      }
    }
    return Promise.resolve();
  };
  const cardStyle: CardProps["style"] = {
    borderRadius: "15px",
    marginTop: "9px",
    display: "flex",
    flexDirection: "column",
    minWidth: "400px",
  };
  const cardHeadStyle: CardProps["headStyle"] = {
    textAlign: "center",
    backgroundColor: "lightgrey",
    color: "#0f0f0f",
  };

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        style={{ flexDirection: "column" }}
        labelCol={{ span: 8, offset: 0 }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Card title="Customer" style={cardStyle} headStyle={cardHeadStyle}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingBottom: "10px",
                }}
              >
                <NewCustomerTagRender
                  isNewCustomer={queryResult?.data?.data?.newCustomer}
                />

                <IsExportedTagRender
                  isExported={queryResult?.data?.data?.isExported}
                />
              </div>

              <Form.Item
                name="customerFirstName"
                label="Customer First Name"
                labelAlign="right"
              >
                <Input />
              </Form.Item>
              <Form.Item name="customerLastName" label="Customer Last Name">
                <Input />
              </Form.Item>
              <div
                style={{
                  display: "flex",
                }}
              >
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  style={{ width: "250px" }}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="email"
                  label="Email"
                  style={{ width: "250px", marginLeft: "20px" }}
                >
                  <Input />
                </Form.Item>
              </div>
              <Divider></Divider>
              <Typography>Shipping Info</Typography>
              <Form.Item name="shippingAddressLine1" label="Address line 1">
                <Input />
              </Form.Item>
              <Form.Item name="shippingAddressLine2" label=" Address line 2">
                <Input />
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item name="shippingLocality" label="Locality">
                  <Input />
                </Form.Item>

                <Form.Item name="shippingRegion" label="State">
                  <Input />
                </Form.Item>
                <Form.Item name="shippingPostCode" label="Postcode">
                  <Input />
                </Form.Item>
              </div>
              <Divider></Divider>
              <Typography>Billing Details</Typography>
              <Form.Item name="billingAddressLine1" label="Address line 1">
                <Input />
              </Form.Item>
              <Form.Item name="billingAddressLine2" label=" Address line 2">
                <Input />
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item name="billingLocality" label="Locality">
                  <Input />
                </Form.Item>
                <Form.Item name="billingRegion" label="State">
                  <Input />
                </Form.Item>
                <Form.Item name="billingPostCode" label="Postcode">
                  <Input />
                </Form.Item>
              </div>
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Order" style={cardStyle} headStyle={cardHeadStyle}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Form.Item
                  name="prontoOrderId"
                  label="Pronto Order Id"
                  style={{ width: "354px" }}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="prontoCustomerId"
                  label="Pronto Cust Id"
                  style={{ width: "354px", marginLeft: "10px" }}
                >
                  <Input />
                </Form.Item>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Form.Item
                  name="status"
                  label="Order Status"
                  style={{ width: "354px" }}
                >
                  <Select
                    placeholder="Select a status"
                    allowClear
                    options={ORDER_SHIPPING_STATUS}
                  ></Select>
                </Form.Item>

                <Form.Item
                  name="conNote"
                  label="Con Note"
                  style={{ width: "354px", marginLeft: "10px" }}
                >
                  <Input />
                </Form.Item>
              </div>
              <div
                style={{
                  display: "flex",

                  width: "100%",
                }}
              >
                <Form.Item
                  name="giftNote"
                  label="Gift Message"
                  style={{ width: "50%" }}
                >
                  <TextArea />
                </Form.Item>
                <Form.Item
                  name="fromNote"
                  label="From Note"
                  style={{ width: "50%", marginLeft: "10px" }}
                >
                  <TextArea />
                </Form.Item>
              </div>
              <Form.Item
                name="orderCreateDate"
                label="Order Date"
                getValueProps={(value) => ({
                  value: value ? dayjs(value) : "",
                })}
              >
                <DatePicker />
              </Form.Item>
            </Card>

            <Card title="Shipping" style={cardStyle} headStyle={cardHeadStyle}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Form.Item
                  name="carrier"
                  label="Carrier"
                  style={{ width: "50%" }}
                >
                  <Select
                    placeholder="Select a carrier"
                    allowClear
                    options={SHIPPING_CARRIERS}
                  ></Select>
                </Form.Item>

                <Form.Item
                  name="notification"
                  label="Notification"
                  style={{ width: "50%", marginLeft: "10px" }}
                >
                  <Select
                    placeholder="Select a notification"
                    allowClear
                    options={SHIPPING_NOTIFICATION_OPTIONS}
                  ></Select>
                </Form.Item>
              </div>
              <Form.Item name="deliveryNotes" label="Delivery Note">
                <TextArea />
              </Form.Item>

              <Form.Item label="Time Range">
                <div
                  style={{
                    display: "flex",
                    //center the time range
                    alignItems: "center",
                    // width: "100%",
                  }}
                >
                  <Form.Item
                    label="From"
                    name="openTimeWindow"
                    style={{ width: "30%" }}
                  >
                    <Select
                      placeholder="Select a time"
                      options={SHIPPING_HOURS}
                      onChange={handleTimeValidation}
                      allowClear
                    ></Select>
                  </Form.Item>
                  <Form.Item
                    name="closeTimeWindow"
                    label="To"
                    style={{ width: "30%", marginLeft: "10px" }}
                  >
                    <Select
                      placeholder="Select a time"
                      options={SHIPPING_HOURS}
                      allowClear
                      onChange={handleTimeValidation}
                    ></Select>
                  </Form.Item>
                  <Button
                    onClick={handleClearTimeRange}
                    style={{ marginLeft: "10px", backgroundColor: "#f0f2f5" }}
                  >
                    Clear Times
                  </Button>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Form.Item
                    name="insurance"
                    label="Insurance"
                    style={{ width: "30%" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="stackerId"
                    label="Stacker Id"
                    style={{ width: "30%", marginLeft: "10px" }}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="load"
                    label="Load"
                    style={{ width: "30%", marginLeft: "10px" }}
                  >
                    <Input />
                  </Form.Item>
                </div>
                <Form.Item
                  name="authToLeave"
                  label="Authority To Leave"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col>
          <Typography>Shipping History</Typography>
        </Col>
      </Row>
      <OrderShippingHistoryTable data={orderShippingHistory} />
    </Edit>
  );
};
