import { ImageField } from "@pankod/refine-antd";
import { Fragment } from "react";

export const ProductImageThumbnailRender = ({
  imageUrl,
}: {
  imageUrl: string;
}) => {
  // const placeholderImageUrl = "https://via.placeholder.com/300";
  // the null in the prdocut model should be handled here i thinnk - its currently handled in a seperate function in the edit

  if (imageUrl == null || "") {
    return (
      <Fragment>
        <ImageField
          value="https://via.placeholder.com/30"
          width={20}
          height={20}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <ImageField value={imageUrl} width={30} />
    </Fragment>
  );
};
