//TODO this can be tightened up a bit on the error side, but they have pretty much freereign what to call their runs so I'm not sure how to do it for the green

import { ISubscriptionRun } from "Interfaces";
import { dateTimeOffsetMelbourneHelper } from "Services/dateTimeOffsetMelbourneHelper";
const dayjs = require("dayjs");
var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);
const { todayZonedtoMelbourne } = dateTimeOffsetMelbourneHelper();

const isCurrent = (subscriptionRun: ISubscriptionRun) => {
  const todayMelbourne = dayjs(todayZonedtoMelbourne);

  const isCurrentRun = todayMelbourne.isBetween(
    dayjs(subscriptionRun.openDateTime),
    dayjs(subscriptionRun.closeDateTime)
  );

  if (isCurrentRun) {
    //return current styled in green
    return (
      <h1 style={{ color: "green" }}>
        <b>* Current run</b>
      </h1>
    );
  } else {
    return (
      <h1 style={{ color: "red" }}>
        <b>* Not current run</b>
      </h1>
    );
  }
};

export const ShowRunIsCurrent = ({
  runStringProp,
}: {
  runStringProp: ISubscriptionRun;
}) => {
  return <div>{isCurrent(runStringProp)}</div>;
};
