import React from "react";
import {Button, Col, Form, FormProps, Icons, Input, Row, Select, Space, Switch} from "@pankod/refine-antd";

export const ProdFilterMini: React.FC<{ formProps: FormProps }> = ({ formProps }) => {
    return (
        <Form layout="vertical" {...formProps}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Space align="end" size={"large"}>
                        <Form.Item label="Search" name="generalSearch">
                            <Input
                                style={{ width: "260px" }}
                                allowClear
                                placeholder="Search Id, Sku's or Title..."
                                prefix={<Icons.SearchOutlined />}
                            />
                        </Form.Item>


                        <Form.Item>
                            <Button htmlType="submit" type="primary">
                                Filter
                            </Button>
                        </Form.Item>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};
