import {
  Form,
  Input,
  Row,
  Col,
  TextField,
  Select,
  Table,
  useTable,
  useForm,
  Edit,
  Divider,
} from "@pankod/refine-antd";
import {
  IResourceComponentsProps,
  useList,
  useNavigation,
} from "@pankod/refine-core";

import {
  ISubscriptionOrder,
  ISubscriptionOrderHistory,
  ISubscriptionRunSkuMap,
} from "Interfaces";
import { Fragment, useMemo } from "react";
import { SubscriptionOrderStatusTagRender } from "../../Subscription_Run/SubscriptionOrderStatusTagRender";
import { SubscriptionOrderOrderStatusTagRender } from "../../Subscription_Run/SubscriptionOrderOrderStatusTagRender";
import { updateOrderHistory } from "Services/productServices";

const { Option } = Select;
const dayjs = require("dayjs");

export const SubscriptionOrderEdit: React.FC<IResourceComponentsProps> = () => {
  const { edit } = useNavigation();
  const { formProps, saveButtonProps, queryResult, id, form } =
    useForm<ISubscriptionOrder>({
      resource: "subscription-orders",
      onMutationSuccess: () => {
        edit("subscription-runs", runId);
      },
      redirect: false,
    });

  const runId = queryResult?.data?.data?.subscription_run?.id;
  const runIdIsUndefined = runId === undefined;

  const orderMasterSku = queryResult?.data?.data?.masterSku;

  const { data: subscriptionSkuMapDataResult } =
    useList<ISubscriptionRunSkuMap>({
      resource: "subscription-run-sku-maps",
      queryOptions: {
        enabled: !runIdIsUndefined,
      },
      config: {
        filters: [
          {
            field: "subscription_run",
            operator: "eq",
            value: runId,
          },
        ],
        ///the hasPagination flag is broken in the current version of refine-core
        // so instead i have to set the page size to this - otherwise it stops at ten.
        pagination: {
          current: 1,
          pageSize: 100,
        },
      },
    });

  const { tableProps, tableQueryResult } = useTable<ISubscriptionOrderHistory>({
    resource: "subscription-order-histories",

    //  set the inital filter to be where the subscription is open
    permanentFilter: [
      {
        field: "subscription_order.id",
        operator: "eq",
        value: id,
      },
    ],
    initialPageSize: 20,
  });

  const executedSkuTagOptions = useMemo(() => {
    return (
      subscriptionSkuMapDataResult?.data
        // // filter orderMasterSku
        ?.filter((x) => x.masterSku === orderMasterSku)

        ?.map(
          // build an array with the id and label for the select option
          (t) => ({
            id: t.id,
            label: t.executedSku,
            title: `${t.executedSku} - ${t.executedTitle}`,
          })
        )
        .sort(
          // then sort everything
          (a, b) =>
            a.label.localeCompare(b.label, undefined, { sensitivity: "base" })
        )
        .map(
          // then build the final options
          (t) => (
            <Option key={t.id} value={t.label} title={t.title}>
              {t.title}
            </Option>
          )
        )
    );
  }, [subscriptionSkuMapDataResult?.data, orderMasterSku]);

  const handleEditOrderExecutedSkuSelect = async (input, option) => {
    const executedTitle = subscriptionSkuMapDataResult?.data?.find(
      // eslint-disable-next-line eqeqeq
      (x) => x.id == option.key
    ); // diasble the === as value is type any and we dont want to create an interface

    //set the process name for the api
    const processName = "Admin Edit Order";

    form.setFieldsValue({
      title: `${queryResult?.data?.data?.subscription?.customerName} ${executedTitle.executedTitle}`,
    });

    updateOrderHistory(id, processName)
      .then(() => {
        tableQueryResult.refetch();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleEditOrderExecutedSkuDeselect = () => {
    form.setFieldsValue({
      executedTitle: "",
    });
  };

  return (
    <Fragment>
      <Edit saveButtonProps={saveButtonProps}>
        <Form {...formProps} layout="vertical" key={"subscription"}>
          <Row gutter={[16, 16]}>
            <Col style={{ display: "flex", justifyContent: "space-evenly" }}>
              {/* this is here to ensure that refine tracks the right entity */}
              <div style={{ display: "none" }}>
                <Form.Item label="Subscription" name="subscription">
                  <Input />
                </Form.Item>
              </div>

              <Form.Item
                label="Subscription"
                name={["subscription", "customerName"]}
              >
                <TextField value="customerName" />
              </Form.Item>
            </Col>
            <Col style={{ display: "flex" }}>
              <Form.Item label="Status" name="status">
                <SubscriptionOrderStatusTagRender
                  status={form.getFieldValue("status")}
                />
              </Form.Item>
            </Col>
            <Col style={{ display: "flex" }}>
              <Form.Item label="Order Status" name="orderStatus">
                <SubscriptionOrderOrderStatusTagRender
                  status={form.getFieldValue("orderStatus")}
                />
              </Form.Item>
            </Col>
            <Col style={{ display: "flex" }}>
              <Form.Item label="Pronto Order #" name="prontoOrderId">
                <TextField value="prontoOrderId" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col style={{ display: "flex" }}>
              <Form.Item label="Master Sku" name="masterSku">
                <TextField value="masterSku" />
              </Form.Item>
            </Col>
            <Col style={{ display: "flex" }}>
              <Form.Item
                label="Title"
                name="title"
                style={{ width: "400px" }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col style={{ display: "flex" }}>
              <Form.Item
                label="Executed Sku"
                name="executedSku"
                // style={{ width: "400px " }}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  allowClear
                  onSelect={handleEditOrderExecutedSkuSelect}
                  onDeselect={handleEditOrderExecutedSkuDeselect}
                  filterOption={(input, option) => {
                    return (
                      option.key.toLowerCase().indexOf(input.toLowerCase()) >=
                        0 ||
                      option.title.toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    );
                  }}
                >
                  {executedSkuTagOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Divider />
        <h1>Order History</h1>
        <Table {...tableProps} rowKey="id">
          <Table.Column<ISubscriptionOrderHistory>
            title="Action Type"
            dataIndex="actionType"
          />
          <Table.Column<ISubscriptionOrderHistory>
            title="Action Date / Time"
            dataIndex="actionDateTime"
            render={(_, record) => {
              return dayjs(record.actionDateTime).format("DD/MM/YYYY HH:mm");
            }}
          />
          <Table.Column<ISubscriptionOrderHistory>
            title="Description"
            dataIndex="actionDescription"
          />
        </Table>
      </Edit>
    </Fragment>
  );
};
