import { useApiUrl, useCustom } from "@pankod/refine-core";
import { IProductStatistics } from "Interfaces";
import { Fragment } from "react";
import { DashboardPanelItem } from "./DashboardPanelItem";
import toast from "react-hot-toast";
import {Button} from "@pankod/refine-antd";

export const ProductsDashPanel = () => {
  const apiUrl = useApiUrl();

  //consider using the refine query string to the get the unpublished count
  // also count just returns  anumber so no interface needed.
  const { data } = useCustom<IProductStatistics>({
    url: `${apiUrl}/dashboard/productstatistics`,
    method: "get",
  });


  const { refetch } = useCustom<null>({
    url: `https://algolia-cache.blackheartsandsparrows.com.au/clear-cache`,
    method: "post",
    queryOptions:{
      enabled:false
    }
  });

  return (
    <Fragment>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <DashboardPanelItem
            heading="Draft Products"
            data={data?.data?.draftProductCount}
          />
        </div>
        <div>
          <DashboardPanelItem
            heading="Published Products"
            data={data?.data?.publishedProductCount}
          />
        </div>
        <div>
          <Button
              onClick={() => {
                toast
                    .promise(refetch(), {
                      loading: "Clearing Algolia Cache",
                      success: "Algolia Cache cleared",
                      error: (error) => `${error}`,
                    })
              }}
          >
            Clear Algolia Cache
          </Button>
        </div>
      </div>
    </Fragment>
  );
};
