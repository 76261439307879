import { Tag } from "@pankod/refine-antd";

export const StatusTagRender = ({
  status,
}: {
  status: "Active" | "Inactive" | "PurchaseStop";
}) => {
  if (status === "Active") {
    return <Tag color="green">{status}</Tag>;
  } else if (status === "Inactive") {
    return <Tag color="blue">{status}</Tag>;
  } else if (status === "PurchaseStop") {
    return <Tag color="red">{status}</Tag>;
  } else {
    return null;
  }
};
