import { Button, List, Table, Tabs, useTable } from "@pankod/refine-antd";
import { IStockAllocationData } from "Interfaces";
import { Fragment } from "react";
import toast from "react-hot-toast";
import { refreshStockAllocation } from "Services/productServices";
const { TabPane } = Tabs;

//create stock alloaction as a a react funcational component
export const PackAllocationTable: React.FC<{
  id: number;
}> = ({ id }) => {
  const {
    tableProps: packAllocationDataProps,
    tableQueryResult: packAllocationDataQueryResult,
  } = useTable<IStockAllocationData>({
    resource: `subscription-runs/${id}/stockallocation/packs`,
  });

  const handleManualPackStockRefresh = async () => {
    const updateTablePromise = refreshStockAllocation(id);

    toast
      .promise(updateTablePromise, {
        loading: "Loading...",
        success: "Refreshed Pack Allocation",
        error: (error) => `${error}`,
      })
      .then(() => packAllocationDataQueryResult.refetch());
  };

  return (
    <List
      title="Pack Allocation"
      pageHeaderProps={{
        extra: (
          <Fragment>
            <Button
              // disabled={!isCompleteSkuMap}
              onClick={handleManualPackStockRefresh}
            >
              Manual Update
            </Button>
          </Fragment>
        ),
      }}
    >
      <Table
        {...packAllocationDataProps}
        style={{ width: "100%" }}
        rowKey="packSku"
      >
        <Table.Column<IStockAllocationData>
          align="center"
          title="Asap Available"
          dataIndex="isAsapPurchaseAvailable"
          render={(_, record) => {
            //if the asap purchase is available then return a checkmark
            if (record.isAsapPurchaseAvailable) {
              return <div>✅</div>;
            }
            //if the asap purchase is not available then return a cross
            return <div>❌</div>;
          }}
        />
        <Table.Column<IStockAllocationData>
          align="center"
          title="Pack Sku"
          dataIndex="packSku"
        />
        <Table.Column<IStockAllocationData>
          align="center"
          title="Required"
          dataIndex="required"
        />

        <Table.Column<IStockAllocationData>
          align="center"
          title="Reserved"
          dataIndex="reserved"
        />
        <Table.Column<IStockAllocationData>
          align="center"
          title="Spare"
          dataIndex="spare"
        />
        <Table.Column<IStockAllocationData>
          align="center"
          title="Status"
          dataIndex="status"
        />
      </Table>
    </List>
  );
};
