import { useApiUrl, useCustom } from "@pankod/refine-core";
import { IOrderStatistics, IRunStatistics } from "Interfaces";
import { Fragment } from "react";
import { DashboardPanelItem } from "./DashboardPanelItem";
import { formatCurrency } from "./FormatCurrency";

export const RunPanel = () => {
  const apiUrl = useApiUrl();

  const { data } = useCustom<IRunStatistics>({
    url: `${apiUrl}/dashboard/runstatistics`,
    method: "get",
  });

  const flexContainer: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
  };
  const flexColumn: React.CSSProperties = {
    display: "flex",
    justifyContent: "space-between",
  };
  const panelItem: React.CSSProperties = {
    marginRight: "40px",
    width: "180px",
  };
  const panelItemEnd: React.CSSProperties = {
    marginRight: "0px",
    paddingRight: "0px",
    width: "180px",
  };

  return (
    <Fragment>
      <div style={flexContainer}>
        <div style={flexColumn}>
          <div style={panelItem}>
            <DashboardPanelItem
              heading="Current Run"
              data={data?.data?.runName}
            />
          </div>
          <div style={panelItem}>
            <DashboardPanelItem
              heading="Asap Available"
              data={data?.data?.isAsapAvailable ? "Yes" : "No"}
            />
          </div>
          <div style={panelItem}>
            <DashboardPanelItem
              heading="Pending"
              data={data?.data?.pendingCount}
            />
          </div>
          <div style={panelItemEnd}>
            <DashboardPanelItem
              heading="Payment Error"
              data={data?.data?.paymentErrorCount}
            />
          </div>
        </div>
        <div style={flexColumn}>
          <div style={panelItem}>
            <DashboardPanelItem
              heading="Order Intervention"
              data={data?.data?.orderInterventionCount}
            />
          </div>
          <div style={panelItem}>
            <DashboardPanelItem
              heading="Cancelled"
              data={data?.data?.cancelledCount}
            />
          </div>
          <div style={panelItem}>
            <DashboardPanelItem
              heading="Total Run Value"
              data={formatCurrency(data?.data?.totalRunValue)}
            />
          </div>
          {/* </div> */}
          {/* <div style={flexColumn}> */}
          <div style={panelItemEnd}>
            <DashboardPanelItem
              heading="New Subscribers"
              data={data?.data?.newSubscribersCount}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
